<template>
    <div
        class="items"
        ref="mentionContainer"
    >
        <div
            v-for="(item, groupIndex) in itemsWithoutEmptyGroups"
            :key="item.groepId + '-' + uuidv4()"
        >
            <h5 v-if="items.length > 1 && item.gebruikers.length">{{ item.groepnaam }}</h5>
            <button
                class="item"
                :class="{ 'is-selected': groupIndex === selectedIndex[0] && gebruikerIndex === selectedIndex[1] }"
                v-for="(gebruiker, gebruikerIndex) in item.gebruikers"
                :key="gebruiker.id + '-' + uuidv4()"
                @click="selectItem(gebruiker)"
            >
                {{ ` ${gebruiker.voornaam} ${gebruiker.tussenvoegsel ?? ''} ${gebruiker.achternaam}` }}
            </button>
        </div>
        <h6 v-if="itemsWithoutEmptyGroups.length === 0">Geen resultaten gevonden</h6>
    </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref, watch } from 'vue'

import { uuidv4 } from '@/helpers/uuidv4'
import { IGroupedMentionUsers } from '@/interfaces/INote'
import { IUser } from '@/interfaces/IUser'
import { useScroll } from '@vueuse/core'

interface Props {
    items: IGroupedMentionUsers[]
    command: any
}

const mentionContainer = ref()

const { y } = useScroll(mentionContainer)

const props = defineProps<Props>()
const selectedIndex = ref([0, 0])

const onKeyDown = ({ event }: any) => {
    if (event.key === 'ArrowUp') {
        upHandler()
        return true
    }

    if (event.key === 'ArrowDown') {
        downHandler()
        return true
    }

    if (event.key === 'Enter') {
        enterHandler()
        return true
    }

    return false
}

const upHandler = () => {
    if (itemsWithoutEmptyGroups.value.length === 0) return

    const groupIndex = selectedIndex.value[0]
    const userIndex = selectedIndex.value[1]
    const currentGroupUserLength = itemsWithoutEmptyGroups.value[groupIndex].gebruikers.length
    const nextGroupIndex = groupIndex !== 0 ? groupIndex - 1 : itemsWithoutEmptyGroups.value.length - 1
    const nextGroupLength = itemsWithoutEmptyGroups.value[nextGroupIndex].gebruikers.length

    if (userIndex - 1 < 0) {
        selectedIndex.value = [(groupIndex + itemsWithoutEmptyGroups.value.length - 1) % itemsWithoutEmptyGroups.value.length, nextGroupLength - 1]
    } else {
        selectedIndex.value = [groupIndex, (userIndex + currentGroupUserLength - 1) % currentGroupUserLength]
    }

    setScrollheight()
}

const downHandler = () => {
    if (itemsWithoutEmptyGroups.value.length === 0) return

    const groupIndex = selectedIndex.value[0]
    const userIndex = selectedIndex.value[1]
    const currentGroupUserLength = itemsWithoutEmptyGroups.value[groupIndex].gebruikers.length

    if (userIndex + 1 >= currentGroupUserLength) {
        selectedIndex.value = [(groupIndex + 1) % itemsWithoutEmptyGroups.value.length, 0]
    } else {
        selectedIndex.value = [groupIndex, (userIndex + 1) % currentGroupUserLength]
    }

    setScrollheight()
}

const setScrollheight = async () => {
    await nextTick()
    const selectedUserEl = mentionContainer.value.querySelector('.is-selected')
    y.value = selectedUserEl.offsetTop - mentionContainer.value?.offsetHeight / 2
}

const enterHandler = () => {
    if (itemsWithoutEmptyGroups.value.length === 0) return
    selectItem(itemsWithoutEmptyGroups.value[selectedIndex.value[0]].gebruikers[selectedIndex.value[1]])
}

const selectItem = (user: IUser) => {
    const name = `${user.voornaam} ${user.tussenvoegsel ?? ''} ${user.achternaam}`
    if (user) {
        props.command({ id: user.id, name: name, displayname: name })
    }
}

const itemsWithoutEmptyGroups = computed(() => props.items.filter((group) => group.gebruikers.length > 0))

watch(
    () => itemsWithoutEmptyGroups.value,
    () => {
        selectedIndex.value = [0, 0]
    }
)

// fix method not being exposed to parent component
defineExpose({
    onKeyDown,
})
</script>
<style lang="scss" scoped>
.items {
    position: relative;
    border-radius: 0.25rem;
    background: white;
    color: #1c1634;
    overflow: hidden;
    font-size: 0.9rem;
    max-height: 350px;
    overflow: auto;
    box-shadow:
        0 0 0 1px rgba(0, 0, 0, 0.1),
        0px 10px 20px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 1rem;
}

.item {
    display: block;
    width: 100%;
    text-align: left;
    background: transparent;
    border: none;
    padding: 0.2rem 0.5rem;

    &.is-selected,
    &:hover {
        color: #1c1634;
        background: #ebebff;
    }
}
</style>
