<template>
    <div class="schedule-note mb-4 mb-xl-10">
        <div
            v-if="!isPlanOnly"
            class="schedule-note__back"
            @click="openCreateNote"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
            >
                <path
                    d="M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z"
                    fill="rgba(71,49,135,1)"
                ></path>
            </svg>
            <span>terug</span>
        </div>
        <div class="schedule-note__tabs w-100 mb-5">
            <button
                @click="updateSelectedTab(SCHEDULE_TYPE.LES)"
                :class="selectedTab === SCHEDULE_TYPE.LES ? 'btn-primary' : 'btn-outline-primary'"
                class="btn rounded-left"
            >
                Les
            </button>
            <button
                @click="updateSelectedTab(SCHEDULE_TYPE.DAG)"
                :class="selectedTab === SCHEDULE_TYPE.DAG ? 'btn-primary' : 'btn-outline-primary'"
                class="btn rounded-right"
            >
                Dag
            </button>
        </div>
        <div v-show="selectedTab === SCHEDULE_TYPE.LES">
            <KeepAlive>
                <ScheduleNoteLesson
                    :plan-only="isPlanOnly"
                    @schedule:note-vak="scheduleNoteVak"
                    @schedule:note-lessons="scheduleNoteLessons"
                    @schedule:note-les="scheduleNoteLes"
                    @update:note="updateNoteLesson"
                    @close:plan-note="openCreateNote"
                />
            </KeepAlive>
        </div>
        <div
            class="schedule-note__date"
            v-show="selectedTab === SCHEDULE_TYPE.DAG"
        >
            <KeepAlive>
                <ScheduleNoteDay
                    :plan-only="isPlanOnly"
                    @schedule:note="scheduleNoteDay"
                    @update:note="updateNoteDay"
                    @close:plan-note="openCreateNote"
                />
            </KeepAlive>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'

import ScheduleNoteDay from '@/components/Notes/ScheduleNoteDay.vue'
import ScheduleNoteLesson from '@/components/Notes/ScheduleNoteLesson.vue'
import { useStore } from '@/composables/useStore'
import { ObjectValues } from '@/interfaces/IDay'
import { INotePlanLes } from '@/interfaces/INote'
import { ILabel } from '@/interfaces/IPlanning'

const SCHEDULE_TYPE = {
    LES: 'Les',
    DAG: 'Dag',
} as const

export type ScheduleType = ObjectValues<typeof SCHEDULE_TYPE>

const props = defineProps<{
    defaultTab: ScheduleType
}>()

const emit = defineEmits<{
    (eventName: 'open-create-note'): void
    (eventName: 'schedule:note', payload: Date): void
}>()

const { notesStore } = useStore()

const selectedTab = ref<ScheduleType>(SCHEDULE_TYPE.LES)

const openCreateNote = () => {
    emit('open-create-note')
}

const updateSelectedTab = (tab: ScheduleType) => {
    selectedTab.value = tab
}

const scheduleNoteDay = (moment: Date[]) => {
    notesStore.actions.setCurrentNoteMomentDate(moment)
    openCreateNote()
}

const scheduleNoteLes = (lessons: INotePlanLes[]) => {
    notesStore.actions.setCurrentNoteLessons(lessons)
    notesStore.commits.setCurrentNoteLesInfo(lessons[0])
    openCreateNote()
}

const scheduleNoteLessons = (lessons: INotePlanLes[]) => {
    notesStore.actions.setCurrentNoteLessons(lessons)
    openCreateNote()
}

const scheduleNoteVak = (vak: ILabel) => {
    notesStore.actions.setCurrentNoteVak(vak)
    openCreateNote()
}

const updateNoteDay = async (moment: Date[]) => {
    notesStore.actions.setCurrentNoteMomentDate(moment)
    if (notesStore.getters.value.getCurrentNote) {
        await notesStore.actions.updateNote(notesStore.getters.value.getCurrentNote)
    }
}
const updateNoteLesson = async (lessons: INotePlanLes[]) => {
    notesStore.actions.setCurrentNoteLessons(lessons)
    if (notesStore.getters.value.getCurrentNote) {
        await notesStore.actions.updateNote(notesStore.getters.value.getCurrentNote)
    }
}

const setSelectedTab = () => {
    const currentNote = notesStore.getters.value.getCurrentNote

    if (currentNote.planningsdatum?.length) {
        selectedTab.value = SCHEDULE_TYPE.DAG
    } else {
        selectedTab.value = SCHEDULE_TYPE.LES
    }
}

const isPlanOnly = computed(() => {
    return notesStore.getters.value.getSidebarPlanNoteOnly
})

onMounted(() => {
    setSelectedTab()
})
</script>
