import { format } from 'date-fns'
import { nl } from 'date-fns/locale'

export const formatNL = (
    date: Date,
    f: string,
    options?: {
        locale?: Locale
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
        firstWeekContainsDate?: number
        useAdditionalWeekYearTokens?: boolean
        useAdditionalDayOfYearTokens?: boolean
    }
) => {
    return format(date, f, { locale: nl, ...options })
}
