<script setup lang="ts">
import { useStore } from '@/composables/useStore'
import { convertLocalToUTC, convertUTCtoLocal } from '@/helpers/convertTimezone'
import { uuidv4 } from '@/helpers/uuidv4'
import { format } from 'date-fns'
import { nl as nlLocale } from 'date-fns/locale'
import { computed, onMounted, ref } from 'vue'

interface Props {
    planOnly: boolean
}

const props = defineProps<Props>()

const selectedDates = ref<Date[]>([])

const emit = defineEmits<{
    (event: 'schedule:note', payload: Date[]): void
    (event: 'update:note', payload: Date[]): void
    (event: 'close:plan-note'): void
}>()

const { notesStore } = useStore()

const selectedDateText = computed(() => {
    return 'Selecteer een of meerdere datum(s)'
})

const selectDate = () => {
    if (selectedDates.value) {
        emit('schedule:note', selectedDates.value)
        emit('close:plan-note')
    }
}

const selectDatePlanOnly = () => {
    if (selectedDates.value) {
        emit('update:note', selectedDates.value)
        emit('close:plan-note')
    }
}

const removeSelectedDate = (index: number) => {
    selectedDates.value.splice(index, 1)
}

const cancel = () => {
    emit('close:plan-note')
}

const cancelText = computed(() => {
    if (props.planOnly) {
        return 'Annuleren'
    } else {
        return 'Terug zonder datum te selecteren'
    }
})

const updateDates = (value: Date) => {
    selectedDates.value.push(convertLocalToUTC(value))
}

const sortedSelectedDays = computed(() => {
    return selectedDates.value.sort((a, b) => {
        return a.getTime() - b.getTime()
    })
})

onMounted(() => {
    if (notesStore.getters.value.getCurrentNote && notesStore.getters.value.getCurrentNote.planningsdatum && notesStore.getters.value.getCurrentNote.planningsdatum.length) {
        selectedDates.value = notesStore.getters.value.getCurrentNote.planningsdatum
    }
})
</script>

<template>
    <div class="container">
        <h3 class="schedule-note__date__title mb-6">{{ selectedDateText }}</h3>
        <v-date-picker
            class="schedule-note__date__picker w-100 mb-10"
            is-required
            mode="date"
            :value="selectedDates"
            @update:modelValue="updateDates"
            locale="nl"
            :disabled-dates="selectedDates"
            :min-date="new Date()"
        >
        </v-date-picker>
        <div class="schedule-note__date__selected-dates mb-4">
            <div
                v-for="(date, index) in sortedSelectedDays"
                :key="uuidv4()"
                class="schedule-note__date__selected-dates__date"
            >
                <div class="schedule-note__date__selected-dates__date__data">
                    <div class="schedule-note__date__selected-dates__date__data__date">
                        {{ format(convertUTCtoLocal(new Date(date)), 'EEEE d MMMM', { locale: nlLocale }) }}
                    </div>
                </div>
                <button
                    @click="removeSelectedDate(index)"
                    class="btn btn-active-tag d-flex"
                >
                    <div class="active-tag-icon d-flex">
                        <svg
                            class="feather feather-x-circle"
                            fill="#A69BD1"
                            height="20"
                            stroke="#FFFFFF"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 21"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="12"
                                cy="12"
                                r="10"
                            ></circle>
                            <line
                                x1="15"
                                x2="9"
                                y1="9"
                                y2="15"
                            ></line>
                            <line
                                x1="9"
                                x2="15"
                                y1="9"
                                y2="15"
                            ></line>
                        </svg>
                    </div>
                </button>
            </div>
        </div>
        <div class="schedule-note__date__buttons d-flex flex-column align-items-center">
            <button
                v-if="planOnly"
                class="schedule-note__date__buttons__save btn btn-purple-gradient w-100 mb-2"
                @click="selectDatePlanOnly"
                :disable="!selectedDates.length"
            >
                {{ `Inplannen op deze dag${selectedDates.length > 1 ? 'en' : ''}` }}
            </button>
            <button
                v-else
                class="schedule-note__date__buttons__save btn btn-purple-gradient w-100 mb-2"
                @click="selectDate"
                :disable="!selectedDates.length"
            >
                {{ `Selecteer datum${selectedDates.length > 1 ? 's' : ''}` }}
            </button>
            <button
                class="schedule-note__date__buttons__back btn text-primary"
                @click="cancel"
            >
                {{ cancelText }}
            </button>
        </div>
    </div>
</template>
