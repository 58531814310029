import { initRequestInterceptor, initResponseInterceptor } from '@/services/interceptor'
import Notifications from '@kyvg/vue3-notification'
import axios from 'axios'
import VCalendar from 'v-calendar'
import { createApp } from 'vue'
import { createVfm } from 'vue-final-modal'
import vSelect from 'vue-select'
import { svgSpriteDirectivePlugin } from 'vue-svg-sprite'
import VueTippy from 'vue-tippy'
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar'
import VueTimepicker from 'vue3-timepicker'

import App from './App.vue'
import router from './router'
import store from './store'

import 'mutationobserver-shim'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'unfonts.css'
import 'v-calendar/style.css'
import 'vue-final-modal/style.css'
import 'vue3-perfect-scrollbar/style.css'
import 'vue3-timepicker/dist/VueTimepicker.css'
import './scss/style.scss'

const app = createApp(App)
const vfm = createVfm()

app.component('v-select', vSelect)
app.component('vue-timepicker', VueTimepicker)
app.use(Notifications)
app.use(vfm)
app.use(VueTippy, {
    component: 'tippy', // => <tippy/>
    defaultProps: {
        theme: 'light',
    },
})

app.use(PerfectScrollbarPlugin, {
    //@ts-expect-error options object is not defined in the type definition
    suppressScrollX: true,
})
app.use(VCalendar, {})
app.use(svgSpriteDirectivePlugin, {
    url: '/assets/rte/remixicon.symbol.svg',
    class: 'remix-icon',
})
app.use(store)
app.use(router)

let apibase
let fullcalendarLicenseKey

if (!import.meta.env.PROD) {
    apibase = import.meta.env.VITE_VUE_APP_ROOT_API
    fullcalendarLicenseKey = import.meta.env.VITE_VUE_APP_FC_LICENSE_KEY
} else {
    apibase = window.__env.apibase
    fullcalendarLicenseKey = window.__env.fullcalendarLicenseKey
}

app.provide('$appName', 'Momento Plus')
app.provide('$apiBase', apibase)
app.provide('$fullcalendarLicense', fullcalendarLicenseKey)

initRequestInterceptor(axios, store)
initResponseInterceptor(axios, store)
axios.defaults.baseURL = apibase
axios.defaults.withCredentials = true

app.mount('#app')
