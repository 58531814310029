export enum Yeargroup {
    ALLE = 0,
    GROEP1 = 1,
    GROEP2 = 2,
    GROEP3 = 3,
    GROEP4 = 4,
    GROEP5 = 5,
    GROEP6 = 6,
    GROEP7 = 7,
    GROEP8 = 8
}