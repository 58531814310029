export const dateSorter = ({ a, b, asc = true }: { a?: Date; b?: Date; asc: boolean }) => {
    if (a && b) {
        if (new Date(a).getTime() > new Date(b).getTime()) {
            return asc ? 1 : -1
        }
        if (new Date(a).getTime() < new Date(b).getTime()) {
            return asc ? -1 : 1
        }
    }
    return 0
}

export const stringSorter = ({ a, b, asc = true }: { a?: string; b?: string; asc: boolean }) => {
    if (a && b) {
        asc ? a.localeCompare(b) : b.localeCompare(a)
    }
    return 0
}
