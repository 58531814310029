<script setup lang="ts">
import FeatherBell from '@/components-svg/FeatherBell.vue'
import { useStore } from '@/composables/useStore'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const { notesStore } = useStore()
const router = useRouter()

const unreadNotesCount = computed(() => {
    return notesStore.getters.value.getUnreadNotesCount
})

const onOpenNotifications = () => {
    notesStore.commits.setShowUnread(true)
    router.push({ path: '/notities' })
}
</script>

<template>
    <div
        id="nav-notifications"
        class="navbar-custom__notifications mr-4"
    >
        <tippy
            placement="bottom"
            class="time-block-icon"
        >
            <div
                class="navbar-custom__notifications__icon"
                @click="onOpenNotifications"
            >
                <div class="navbar-custom__notifications__icon__number">{{ unreadNotesCount > 9 ? '9+' : unreadNotesCount }}</div>
                <FeatherBell class="text-primary" />
            </div>
            <template #content>
                <div
                    v-if="unreadNotesCount === 0"
                    class="p-2"
                >
                    {{ `Je hebt geen ongelezen notities` }}
                </div>
                <div
                    v-else
                    class="p-2"
                >
                    {{ `Je hebt ${unreadNotesCount} ongelezen notitie${unreadNotesCount > 1 ? 's' : ''}` }}
                </div>
            </template>
        </tippy>
    </div>
</template>
