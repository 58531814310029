<template>
    <div class="items">
        <button
            class="item"
            :class="{ 'is-selected': index === selectedIndex }"
            v-for="(item, index) in items"
            :key="index"
            @click="selectItem(index)"
        >
            {{ item.name }}
        </button>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import { ITagVM } from '@/interfaces/INote'

interface Props {
    items: ITagVM[]
    command: any
}

const props = defineProps<Props>()
const selectedIndex = ref(0)

const onKeyDown = ({ event }: any) => {
    if (event.key === 'ArrowUp') {
        upHandler()
        return true
    }

    if (event.key === 'ArrowDown') {
        downHandler()
        return true
    }

    if (event.key === 'Enter') {
        enterHandler()
        return true
    }

    return false
}

const upHandler = () => {
    selectedIndex.value = (selectedIndex.value + props.items.length - 1) % props.items.length
}

const downHandler = () => {
    selectedIndex.value = (selectedIndex.value + 1) % props.items.length
}

const enterHandler = () => {
    selectItem(selectedIndex.value)
}

const selectItem = (index: number) => {
    const item = props.items[index]

    if (item) {
        props.command({ id: item.id, name: item.name, displayname: item.displayname })
    }
}

// fix method not being exposed to parent component
defineExpose({
    onKeyDown,
})
</script>
<style lang="scss" scoped>
.items {
    position: relative;
    border-radius: 0.25rem;
    background: white;
    color: #1c1634;
    overflow: hidden;
    font-size: 0.9rem;
    box-shadow:
        0 0 0 1px rgba(0, 0, 0, 0.1),
        0px 10px 20px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 1rem;
}

.item {
    display: block;
    width: 100%;
    text-align: left;
    background: transparent;
    border: none;
    padding: 0.2rem 0.5rem;

    &.is-selected,
    &:hover {
        color: #1c1634;
        background: #ebebff;
    }
}
</style>
