import { MentionOptions } from '@tiptap/extension-mention'
import Suggestion from '@tiptap/suggestion'
import { Node, mergeAttributes } from '@tiptap/vue-3'

export const TagCustom = Node.create<Partial<MentionOptions>>({
    name: 'tag',
    addOptions() {
        return {
            renderText({ options, node }) {
                return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
            },
            HTMLAttributes: {},
            suggestion: {
                char: '#',
                command: ({ editor, range, props }) => {
                    editor
                        .chain()
                        .focus()
                        .insertContentAt(range, [
                            {
                                type: 'tag',
                                attrs: props,
                            },
                            {
                                type: 'text',
                                text: ' ',
                            },
                        ])
                        .run()
                },
                allow: ({ editor, range }) => {
                    return editor.can().insertContentAt(range, { type: 'tag' })
                },
            },
        }
    },
    group: 'inline',
    inline: true,
    selectable: false,
    atom: true,
    addAttributes() {
        return {
            id: {
                default: null,
                parseHTML: (element) => element.getAttribute('data-tag'),
                renderHTML: (attributes) => {
                    if (!attributes.id && typeof attributes.id !== 'number') {
                        return {}
                    }

                    return {
                        'data-tag': attributes.id,
                    }
                },
            },

            name: {
                default: null,
                parseHTML: (element) => element.getAttribute('data-name'),
                renderHTML: (attributes) => {
                    if (!attributes.name) {
                        return {}
                    }

                    return {
                        'data-name': attributes.name,
                    }
                },
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: '.tag',
            },
        ]
    },

    renderHTML({ node, HTMLAttributes }) {
        return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), `${this.options.suggestion.char}${node.attrs.name}`]
    },

    renderText({ node }) {
        return `${this.options.suggestion.char}${node.attrs.name}`
    },

    addKeyboardShortcuts() {
        return {
            Backspace: () =>
                this.editor.commands.command(({ tr, state }) => {
                    let isMention = false
                    const { selection } = state
                    const { empty, anchor } = selection

                    if (!empty) {
                        return false
                    }

                    state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
                        if (node.type.name === this.name) {
                            isMention = true
                            tr.insertText(this.options.suggestion.char || '', pos, pos + node.nodeSize)

                            return false
                        }
                    })

                    return isMention
                }),
        }
    },

    addProseMirrorPlugins() {
        return [
            Suggestion({
                editor: this.editor,
                ...this.options.suggestion,
            }),
        ]
    },
})
