import { AppState } from '@/enums/AppState'
import router from '@/router'
import { AxiosError, AxiosResponse, AxiosStatic } from 'axios'

export const initResponseInterceptor = (axios: AxiosStatic, store: any) => {
    axios.interceptors.response.use(
        (response: AxiosResponse) => {
            return response
        },
        (error: AxiosError) => {
            if (error.response?.status === 401 && error.response.config.url !== 'api/Frontend/Instellingen/VerwerkersOvereenkomst') {
                store.commit('setAppstate', AppState.LOADING)
                // this timeout exists to make a better transition to the login page moo
                setTimeout(() => {
                    router.push({ name: 'Login' }).catch(() => console.info('navigated'))
                    store.commit('setAppstate', AppState.LOGGEDOUT)
                    Promise.reject('unauthorized')
                }, 1500)

                return Promise.reject(error)
            } else {
                return Promise.reject(error)
            }
        }
    )
}

export const initRequestInterceptor = (axios: AxiosStatic, store: any) => {
    axios.interceptors.request.use(
        (config) => {
            const groep = store.getters.getSelectedGroup()

            if (groep && groep.id > -1 && config.headers) {
                config.headers['stamgroep'] = groep.id
            }
            return config
        },
        (error) => {
            // Do something with request error
            return Promise.reject(error)
        }
    )
}
