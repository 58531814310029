import { ResultType } from '@/enums/ResultType'
import { JaarPeriode, OefenenPeriode, ToetsenPeriode } from '@/enums/Timespan'
import { Yeargroup } from '@/enums/Yeargroup'
import { IVolgCategory, IVolgCategoryCollection } from '@/interfaces/ICategory'
import { IResultUser } from '@/interfaces/IResult'
import { resultService } from '@/services/resultService'
import { looqinCompetentiesResultService } from '@/services/resultaten/looqinCompetentiesResultService'
import { looqinSociaalEmotioneelResultService } from '@/services/resultaten/looqinSociaalEmotioneelResultService'
import { methodeToetsResultService } from '@/services/resultaten/methodeToetsResultService'
import { moelijksteOefeningenResultService } from '@/services/resultaten/moelijksteOefeningenResultService'
import { oefeningenResultService } from '@/services/resultaten/oefeningenResultService'
import { volgToetsResultService } from '@/services/resultaten/volgToetsResultService'
import Vue from 'vue'
import { ActionTree, GetterTree, MutationTree, createStore } from 'vuex'
import { ICategoryResult, IVolgCategoryResult } from './../../interfaces/ICategoryResult'
import { ICategoryResultFilters } from './../../interfaces/IFilters'
import { notify } from '@kyvg/vue3-notification'

const showVueNotifyError = (message: string) => {
    notify({
        group: 'requests',
        title: 'Foutmelding',
        type: 'error',
        text: `Er is iets misgegaan. Error: ${message}`,
    })
}

class ResultsDetailsState {
    filters: ICategoryResultFilters = {
        YeargroupFilter: Yeargroup.ALLE,
        TimespanFilter: OefenenPeriode.THIS_WEEK,
        ToetsTimespanFilter: ToetsenPeriode.THIS_WEEK,
        VolgenTimespanFilter: JaarPeriode.THIS_SCHOOLYEAR,
        SociaalTimespanFilter: JaarPeriode.THIS_SCHOOLYEAR,
        CompetentiesTimespanFilter: JaarPeriode.THIS_SCHOOLYEAR,
        CategoryFilter: -1,
    }
    catDetailResults: ICategoryResult = {
        gemiddeldeOefenResultaatPerLeerling: [],
        gemiddeldeToetsResultaatPerLeerling: [],
        moeilijksteOefeningen: [],
    }
    volgDetailResults: IVolgCategoryResult = {
        volgToetsenResults: [],
        sociaalEmotioneelResults: [],
        competentiesResults: [],
    }
    volgResulttypes: IVolgCategoryCollection = {
        volgToets: [],
        sociaalEmtioneel: [],
        competenties: [],
    }
    loading = {
        oefenenLoading: false,
        moelijksteOefeningenLoading: false,
        toetsenLoading: false,
        volgLoading: false,
        sociaalLoading: false,
        competentiesLoading: false,
    }
}

const getters: GetterTree<ResultsDetailsState, any> = {
    getCatDetailResults(store): ICategoryResult {
        const results = store.catDetailResults
        results.gemiddeldeOefenResultaatPerLeerling.sort((a, b) => {
            if (`${a.voornaam.toLowerCase()}${a.achternaam.toLowerCase()}` < `${b.voornaam.toLowerCase()}${b.achternaam.toLowerCase()}`) {
                return -1
            }
            if (`${a.voornaam.toLowerCase()}${a.achternaam.toLowerCase()}` > `${b.voornaam.toLowerCase()}${b.achternaam.toLowerCase()}`) {
                return 1
            }
            return 0
        })

        results.gemiddeldeToetsResultaatPerLeerling.sort((a, b) => {
            if (`${a.voornaam.toLowerCase()}${a.achternaam.toLowerCase()}` < `${b.voornaam.toLowerCase()}${b.achternaam.toLowerCase()}`) {
                return -1
            }
            if (`${a.voornaam.toLowerCase()}${a.achternaam.toLowerCase()}` > `${b.voornaam.toLowerCase()}${b.achternaam.toLowerCase()}`) {
                return 1
            }
            return 0
        })
        return () => results
    },
    getCategoryDetailFilters(store): ICategoryResultFilters {
        return () => store.filters
    },
    getSelectedCategoryFilter(store): number {
        return () => store.filters.CategoryFilter
    },
    getSelectedCategoryPeriodFilter(store): OefenenPeriode {
        return () => store.filters.TimespanFilter
    },
    getSelectedVolgCategoryPeriodFilter(store): JaarPeriode {
        return () => store.filters.VolgenTimespanFilter
    },
    getVolgResultTypes(store): IVolgCategoryCollection {
        return () => store.volgResulttypes
    },
    getVolgToetsenCategories(store): IVolgCategory[] {
        const categories = store.volgResulttypes.volgToets
        categories.unshift({
            id: 0,
            naam: 'Alle',
        })
        return () => categories
    },
    getSociaalEmotioneelCategories(store): IVolgCategory[] {
        const categories = store.volgResulttypes.sociaalEmtioneel
        categories.unshift({
            id: 0,
            naam: 'Alle',
        })
        return () => categories
    },
    getCompetentiesCategories(store): IVolgCategory[] {
        const categories = store.volgResulttypes.competenties
        categories.unshift({
            id: 0,
            naam: 'Alle',
        })
        return () => categories
    },
    getVolgResults(store) {
        return () => store.volgDetailResults
    },
    getResultsDetailsLoading(store) {
        return () => store.loading.volgLoading || store.loading.oefenenLoading || store.loading.moelijksteOefeningenLoading || store.loading.toetsenLoading || store.loading.sociaalLoading || store.loading.competentiesLoading
    },
}

const mutations: MutationTree<ResultsDetailsState> = {
    setOefenResultaten(state, payload: IResultUser[]) {
        state.catDetailResults.gemiddeldeOefenResultaatPerLeerling = payload
    },
    setToetsResultaten(state, payload: IResultUser[]) {
        state.catDetailResults.gemiddeldeToetsResultaatPerLeerling = payload
    },
    setMoelijksteOefeningResultaten(state, payload: any[]) {
        state.catDetailResults.moeilijksteOefeningen = payload
    },
    setVolgToetsResultaten(state, payload) {
        state.volgDetailResults.volgToetsenResults = payload
    },
    setSociaalEmotioneelResultaten(state, payload) {
        state.volgDetailResults.sociaalEmotioneelResults = payload
    },
    setCompetentiesResultaten(state, payload) {
        state.volgDetailResults.competentiesResults = payload
    },
    setFiltersCategoryDetail(state, payload: ICategoryResultFilters) {
        state.filters = state.filters = { ...state.filters, ...payload }
    },
    setVolgResulttypes(state, payload) {
        state.volgResulttypes = payload
    },
    setResultDetailsLoading(state, payload) {
        state.loading = payload
    },
}

const actions: ActionTree<ResultsDetailsState, any> = {
    async getOefeningenResultsVak({ commit, state }, vakLabelId: number) {
        state.loading.oefenenLoading = true
        try {
            const data = await oefeningenResultService.getVak(vakLabelId, state.filters.TimespanFilter)
            commit('setOefenResultaten', data.data as IResultUser[])
            return { resultType: ResultType.OEFENINGEN, data: data.data }
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        } finally {
            state.loading.oefenenLoading = false
        }
    },
    async getToetsResultsVak({ commit, state }, vakLabelId: number) {
        state.loading.toetsenLoading = true
        try {
            const data = await methodeToetsResultService.getVak(vakLabelId, state.filters.ToetsTimespanFilter)
            commit('setToetsResultaten', data.data)
            return { resultType: ResultType.TOETSEN, data: data.data }
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        } finally {
            state.loading.toetsenLoading = false
        }
    },
    async getMoelijksteOefeningenResultsVak({ commit, state }, vakLabelId: number) {
        state.loading.moelijksteOefeningenLoading = true
        try {
            const data = await moelijksteOefeningenResultService.getVak(vakLabelId, state.filters.TimespanFilter)
            commit('setMoelijksteOefeningResultaten', data.data)
            return { resultType: ResultType.OEFENINGEN, data: data.data }
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        } finally {
            state.loading.moelijksteOefeningenLoading = false
        }
    },
    async getVolgtoetsResultsVak({ commit, state }, vakLabelId: number) {
        state.loading.volgLoading = true
        try {
            const data = await volgToetsResultService.getVak(vakLabelId, state.filters.VolgenTimespanFilter)
            commit('setVolgToetsResultaten', data.data)
            return { resultType: ResultType.VOLGTOETSEN, data: data.data }
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        } finally {
            state.loading.volgLoading = false
        }
    },
    async getSociaalEmotioneelResultsVak({ commit, state }, vakLabelId: number) {
        state.loading.sociaalLoading = true
        try {
            const data = await looqinSociaalEmotioneelResultService.getVak(vakLabelId, state.filters.SociaalTimespanFilter)
            commit('setSociaalEmotioneelResultaten', data.data)
            return { resultType: ResultType.SOCIAAL_EMOTIONEEL, data: data.data }
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        } finally {
            state.loading.sociaalLoading = false
        }
    },
    async getCompetentiesResultsVak({ commit, state }, vakLabelId: number) {
        state.loading.competentiesLoading = true
        try {
            const data = await looqinCompetentiesResultService.getVak(vakLabelId, state.filters.CompetentiesTimespanFilter)
            commit('setCompetentiesResultaten', data.data)
            return { resultType: ResultType.COMPETENTIES, data: data.data }
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        } finally {
            state.loading.competentiesLoading = false
        }
    },

    async getVolgResultTypes({ commit }) {
        try {
            const data = await resultService.getResultaatSoorten()
            commit('setVolgResulttypes', data.data)
            return data.data
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        }
    },
}

export default {
    state: new ResultsDetailsState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}
