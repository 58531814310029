import * as jose from 'jose'

export const upvotyKey = '44e3e79cb3a6e7890983ce73eaf650b8'

export interface UpvotyUser {
    // Required, Unique identifier for this user
    id: string
    // Required, The full name for this user
    name: string
    // The user's email address
    email?: string
    // Optional: The URL to the user's avatar
    avatar?: string
}

export const createUpvotyToken = async (user: UpvotyUser) => {
    const token = new jose.SignJWT(user as unknown as jose.JWTPayload).setProtectedHeader({ alg: 'HS256' }).sign(new TextEncoder().encode(upvotyKey))
    return await token
}
