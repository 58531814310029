<template>
    <div class="navbar-custom-dropdown ml-auto">
        <div class="d-flex justify-content-center align-items-center">
            <Notifications v-if="windowWidth >= 1024" />
        </div>

        <div class="navbar-custom-dropdown-inner">
            <div class="navbar-custom-dropdown-person">{{ userStore.getters.value.getUser.voornaam }} {{ userStore.getters.value.getUser.tussenvoegsel }} {{ userStore.getters.value.getUser.achternaam }}</div>
            <img
                class="navbar-custom-dropdown-settings"
                src="/assets/avatar.svg"
                alt="avatar"
                @click="dropdownClick()"
            />
            <ul
                class="navbar-custom-dropdown-settings-menu list-unstyled"
                :class="{ show: dropdownOpen }"
                ref="target"
            >
                <li>
                    <router-link
                        v-if="!isHidden"
                        to="/instellingen"
                        @click.native="closeDropdown()"
                    >
                        <span>Instellingen</span>
                    </router-link>
                    <a
                        href="javascript:void(0)"
                        @click.prevent="logout()"
                    >
                        <span>Uitloggen</span>
                    </a>
                </li>
            </ul>
            <div class="navbar-custom-dropdown-group">
                <v-select
                    label="naam"
                    :options="groups"
                    v-model="selected"
                    :searchable="false"
                    :clearable="false"
                    @close="$el.querySelector('input[type=search]')?.blur()"
                >
                    <template #open-indicator="{ attributes }">
                        <span v-bind="attributes">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-down"
                            >
                                <polyline
                                    points="6 9 12 15 18 9"
                                    stroke-width="1"
                                ></polyline>
                            </svg>
                        </span>
                    </template>
                    <template v-slot:no-options="{ searching }">
                        <template v-if="searching"> Geen resultaten </template>
                        <em
                            style="opacity: 0.5"
                            v-else
                        ></em>
                    </template>
                </v-select>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useStore } from '@/composables/useStore'
import { AppState } from '@/enums/AppState'
import { UserType } from '@/enums/UserType'
import { Yeargroup } from '@/enums/Yeargroup'
import { IGroup } from '@/interfaces/IGroup'
import { userService } from '@/services/userService'
import { onClickOutside, useWindowSize } from '@vueuse/core'
import Notifications from './Notifications.vue'

const router = useRouter()
const route = useRoute()
const { userStore, appStore, groupStore, resultsStore, notesStore } = useStore()
const { width: windowWidth } = useWindowSize()

const dropdownOpen = ref(false)
const target = ref(null)

const unreadNotesCount = computed(() => {
    return notesStore.getters.value.getUnreadNotesCount
})

const onOpenNotifications = () => {
    notesStore.commits.setShowUnread(true)
    router.push({ path: '/notities' })
}

const isCoordinator = computed(() => {
    return userStore.getters.value.getUser.type === UserType.IctCoordinator
})

const isHidden = computed(() => {
    return (
        route.matched?.find((route) => {
            return route.path === '/onboarding' || route.path === '/planning/aanpassen'
        }) !== undefined
    )
})

onMounted(() => {
    onClickOutside(target, (event) => {
        closeDropdown()
    })
})

const groups = computed<IGroup[]>(() => {
    const gr = groupStore.getters.value.groups
    return gr.sort((a: IGroup, b: IGroup) => a.naam.localeCompare(b.naam))
})

const selected = computed({
    get() {
        return groupStore.getters.value.getSelectedGroup
    },
    set(group: IGroup) {
        groupStore.commits.setSelectedGroup(group)
        userStore.commits.setUserSettings({ groep: group.id })
        groupStore.actions.getCandidates(group.id)
        resultsStore.commits.setFilters({ YeargroupFilter: Yeargroup.ALLE })
        userStore.actions.setUserSettings()
        groupStore.actions.getGroupSettings(group.id)
        notesStore.actions.getUnread()
        notesStore.actions.getUnreadNotes()
    },
})

const logout = () => {
    userService
        .logout()
        .then(() => {
            appStore.commits.setAppstate(AppState.LOGGEDOUT)
            router.push({ name: 'Login' })
        })
        .finally(() => {
            if (appStore.getters.value.appState === AppState.LOGGEDOUT) {
                router.push({ name: 'Login' })
            }
        })
}

const dropdownClick = () => {
    setTimeout(() => {
        dropdownOpen.value = !dropdownOpen.value
    }, 50)
}

const closeDropdown = () => {
    dropdownOpen.value = false
}
</script>
