<template>
    <div>
        <template v-for="(item, index) in editorActions">
            <div
                :key="`divider${index}`"
                class="divider"
                v-if="item.type === 'divider'"
            ></div>
            <menu-item
                :key="index"
                v-bind="item"
                v-else
            />
        </template>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'

import MenuItem from '@/components/RteMenuItem.vue'
import { Editor } from '@tiptap/vue-3'

const props = defineProps<{
    editor?: Editor
}>()

const editorActions = ref<any[]>([])

onMounted(() => {
    if (!props.editor) return

    editorActions.value = [
        {
            icon: 'bold',
            title: 'Bold',
            action: () => props.editor?.chain().focus().toggleBold().run(),
            isActive: () => props.editor?.isActive('bold'),
        },
        {
            icon: 'italic',
            title: 'Italic',
            action: () => props.editor?.chain().focus().toggleItalic().run(),
            isActive: () => props.editor?.isActive('italic'),
        },
        {
            icon: 'strikethrough',
            title: 'Strike',
            action: () => props.editor?.chain().focus().toggleStrike().run(),
            isActive: () => props.editor?.isActive('strike'),
        },
        {
            icon: 'underline',
            title: 'Underline',
            action: () => props.editor?.chain().focus().toggleUnderline().run(),
            isActive: () => props.editor?.isActive('underline'),
        },
        {
            icon: 'mark-pen-line',
            title: 'Highlight',
            action: () => props.editor?.chain().focus().toggleHighlight().run(),
            isActive: () => props.editor?.isActive('highlight'),
        },
        {
            type: 'divider',
        },
        {
            icon: 'list-unordered',
            title: 'Bullet List',
            action: () => props.editor?.chain().focus().toggleBulletList().run(),
            isActive: () => props.editor?.isActive('bulletList'),
        },
        {
            icon: 'list-ordered',
            title: 'Ordered List',
            action: () => props.editor?.chain().focus().toggleOrderedList().run(),
            isActive: () => props.editor?.isActive('orderedList'),
        },
        {
            type: 'divider',
        },
        {
            icon: 'link',
            title: 'Link',
            action: setLink,
            isActive: () => props.editor?.isActive('link'),
        },
        {
            icon: 'link-unlink',
            title: 'Unlink',
            action: () => props.editor?.chain().focus().extendMarkRange('link').unsetLink().run(),
            isActive: () => props.editor?.isActive('unlink'),
        },
        {
            type: 'divider',
        },
        {
            icon: 'arrow-go-back-line',
            title: 'Undo',
            action: () => props.editor?.chain().focus().undo().run(),
        },
        {
            icon: 'arrow-go-forward-line',
            title: 'Redo',
            action: () => props.editor?.chain().focus().redo().run(),
        },
    ]
})

const setLink = () => {
    if (!props.editor) return
    const previousUrl = props.editor.getAttributes('link').href
    let url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
        return
    }

    // if url does not start with http or https, add https
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = `https://${url}`
    }

    // empty
    if (url === '') {
        props.editor.chain().focus().extendMarkRange('link').unsetLink().run()

        return
    }

    // update link
    props.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
}
</script>
