<template>
    <nav
        v-if="!navIsHidden"
        id="nav"
        class="navbar-custom bg-white"
    >
        <div class="navbar-custom-inner container-xl p-0 p-lg-5">
            <div
                class="navbar-custom-hamburger"
                @click="mobileActive = !mobileActive"
            >
                <div
                    class="hamburger"
                    v-show="!mobileActive"
                >
                    <hamburger></hamburger>
                    <span>Menu</span>
                </div>
                <div
                    class="cross"
                    v-show="mobileActive"
                >
                    <cross></cross>
                </div>
            </div>
            <div class="navbar-custom-brand">
                <momento-logo></momento-logo>
            </div>
            <div
                v-if="!isHidden"
                class="navbar-custom-menu"
            >
                <router-link
                    to="/"
                    class="navbar-custom-link"
                    exact
                >
                    <div class="navbar-custom-link-inner">Home</div>
                </router-link>
                <router-link
                    to="/jouw-lesdag"
                    class="navbar-custom-link"
                >
                    <div class="navbar-custom-link-inner">Jouw lesdag</div>
                </router-link>
                <router-link
                    to="/planning?tab=Week"
                    class="navbar-custom-link"
                >
                    <div class="navbar-custom-link-inner">Planning</div>
                </router-link>
                <router-link
                    to="/resultaten"
                    class="navbar-custom-link"
                >
                    <div class="navbar-custom-link-inner">Resultaten</div>
                </router-link>
                <router-link
                    to="/notities"
                    class="navbar-custom-link"
                    exact
                >
                    <div class="navbar-custom-link-inner">Notities</div>
                </router-link>
                <router-link
                    to="/verdiepen"
                    class="navbar-custom-link"
                >
                    <div class="navbar-custom-link-inner">Verdiepen</div>
                </router-link>
                <router-link
                    to="/documenten"
                    class="navbar-custom-link"
                >
                    <div class="navbar-custom-link-inner">Documenten</div>
                </router-link>
            </div>
        </div>
        <Notifications v-if="windowWidth < 1024" />
        <nav-avatar></nav-avatar>
        <div
            v-show="mobileActive"
            @click="mobileActive = false"
            class="navbar-custom-mobile-backdrop"
        ></div>
        <div
            class="navbar-custom-mobile"
            :class="{ active: mobileActive }"
        >
            <div class="navbar-custom-mobile-inner">
                <div class="brand-wrapper">
                    <div class="brand">
                        <MomentoLogo></MomentoLogo>
                    </div>
                    <div
                        class="cross"
                        @click="mobileActive = false"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-x"
                        >
                            <line
                                x1="18"
                                y1="6"
                                x2="6"
                                y2="18"
                            ></line>
                            <line
                                x1="6"
                                y1="6"
                                x2="18"
                                y2="18"
                            ></line>
                        </svg>
                    </div>
                </div>
                <div class="navbar-custom-mobile-menu">
                    <div
                        class="navbar-custom-mobile-link"
                        @click="navigateTo('/')"
                    >
                        <div class="navbar-custom-mobile-link-inner">
                            Home
                            <feather-chevron-right></feather-chevron-right>
                        </div>
                    </div>
                    <div
                        class="navbar-custom-mobile-link"
                        @click="navigateTo('/jouw-lesdag')"
                    >
                        <div class="navbar-custom-mobile-link-inner">
                            Jouw lesdag
                            <feather-chevron-right></feather-chevron-right>
                        </div>
                    </div>
                    <div
                        class="navbar-custom-mobile-link"
                        @click="navigateTo('/planning?tab=Week')"
                    >
                        <div class="navbar-custom-mobile-link-inner">
                            Planning
                            <feather-chevron-right></feather-chevron-right>
                        </div>
                    </div>
                    <div
                        class="navbar-custom-mobile-link"
                        @click="navigateTo('/resultaten')"
                    >
                        <div class="navbar-custom-mobile-link-inner">
                            Resultaten
                            <feather-chevron-right></feather-chevron-right>
                        </div>
                    </div>
                    <div
                        class="navbar-custom-mobile-link"
                        @click="navigateTo('/notities')"
                    >
                        <div class="navbar-custom-mobile-link-inner">
                            Notities
                            <feather-chevron-right></feather-chevron-right>
                        </div>
                    </div>
                    <div
                        class="navbar-custom-mobile-link"
                        @click="navigateTo('/verdiepen')"
                    >
                        <div class="navbar-custom-mobile-link-inner">
                            Verdiepen
                            <feather-chevron-right></feather-chevron-right>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import Cross from '@/components-svg/Cross.vue'
import FeatherChevronRight from '@/components-svg/FeatherChevronRight.vue'
import Hamburger from '@/components-svg/Hamburger.vue'
import MomentoLogo from '@/components-svg/MomentoLogo.vue'
import NavAvatar from '@/components/Nav-Avatar.vue'
import { useStore } from '@/composables/useStore'
import { useWindowSize } from '@vueuse/core'
import Notifications from './Notifications.vue'

const route = useRoute()
const router = useRouter()

const { notesStore } = useStore()
const { width: windowWidth } = useWindowSize()

const mobileActive = ref(false)

const isHidden = computed(() => {
    return (
        route.matched.findIndex((route) => {
            return route.path === '/onboarding' || route.path === '/planning/aanpassen'
        }) > -1
    )
})

const navIsHidden = computed(() => {
    return (
        route.matched.findIndex((route) => {
            return route.path === '/login' || route.path === '/digibord' || route.path.startsWith('/widget/moo')
        }) > -1
    )
})

const unreadNotesCount = computed(() => {
    return notesStore.getters.value.getUnreadNotesCount
})

const onOpenNotifications = () => {
    notesStore.commits.setShowUnread(true)
    navigateTo('/notities')
}

const navigateTo = (path: string) => {
    router.push({ path: path })
    mobileActive.value = false
}
</script>
