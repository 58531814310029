export enum Vakperiode {
    ALL,
    THIS_SCHOOLYEAR,
    THIS_AND_LAST_WEEK,
    THIS_WEEK,
    LAST_WEEK,
    TODAY,
    YESTERDAY,
    CURRENT,
}

export enum VolgenPeriode {
    ALL,
    CURRENT_START,
    CURRENT_MIDDLE,
    CURRENT_END,
    PREVIOUS_START,
    PREVIOUS_MIDDLE,
    PREVIOUS_END,
}

export enum OefenenPeriode {
    TODAY,
    YESTERDAY,
    THIS_WEEK,
    LAST_WEEK,
    PAST_TWO_WEEKS,
}

export enum ToetsenPeriode {
    THIS_WEEK,
    PAST_TWO_WEEKS,
    THIS_MONTH,
    THIS_YEAR,
}

export enum JaarPeriode {
    THIS_SCHOOLYEAR,
    LAST_SCHOOLYEAR,
    LAST_AND_THIS_SCHOOLYEAR,
}
