<template>
   
        <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
        >
            <title>icon/close</title>
            <g
                id="icon/close"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
            >
                <g
                    id="close"
                    fill="#1C1634"
                    fill-rule="nonzero"
                >
                    <path
                        d="M0.822764158,16 C0.611999924,16 0.401235691,15.9198352 0.241103046,15.7585012 C-0.080367682,15.4370385 -0.080367682,14.9158672 0.241103046,14.5944045 L14.5947711,0.241096991 C14.9162418,-0.0803656638 15.4374262,-0.0803656638 15.758897,0.241096991 C16.0803677,0.562559646 16.0803677,1.08373095 15.758897,1.40539456 L1.40542985,15.7585012 C1.24409172,15.9188307 1.03332748,16 0.822764158,16 L0.822764158,16 Z"
                        id="Path"
                    ></path>
                    <path
                        d="M15.1774368,16 C14.9666725,16 14.7561092,15.9198352 14.5957757,15.7585012 L0.241103046,1.40539456 C-0.080367682,1.08373095 -0.080367682,0.562559646 0.241103046,0.241096991 C0.562573774,-0.0803656638 1.08375822,-0.0803656638 1.40542985,0.241096991 L15.758897,14.5944045 C16.0803677,14.9158672 16.0803677,15.4370385 15.758897,15.7585012 C15.5975588,15.9188307 15.3869955,16 15.1774368,16 Z"
                        id="Path"
                    ></path>
                </g>
            </g>
        </svg>
</template>
