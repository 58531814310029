import { JaarPeriode } from '@/enums/Timespan'
import { IResultCollection, IResultUser } from '@/interfaces/IResult'
import axios from 'axios'

export default class LooqinCompetentiesResultService {
    getOverzicht(period: JaarPeriode) {
        return axios.get<IResultCollection>(`/api/Frontend/Resultaten/Looqin/Overzicht/Competenties/periode/${period}`)
    }
    getVak(vakLabelId: number, period: JaarPeriode) {
        return axios.get<IResultUser[]>(`/api/Frontend/Resultaten/Looqin/Vak/${vakLabelId}/Competenties/periode/${period}`)
    }

    getLeerling(leerlingId: number, period: JaarPeriode) {
        return axios.get<IResultCollection>(`/api/Frontend/Resultaten/Looqin/Leerling/${leerlingId}/Competenties/periode/${period}`)
    }
}

export const looqinCompetentiesResultService: LooqinCompetentiesResultService = new LooqinCompetentiesResultService()
