export interface IDocument {
    groupId: number
    blobId: string
    fileContent: string
    extension: string
    fileName?: string
    friendlyName?: string
    subject: string
    type: UploadDocumentType
    creationDate: Date
    fileSizeBytes: number
}

export interface IUploadDocument {
    groupId: number
    fileContent: any
    fileName?: string
    friendlyName?: string
    subject: string
    type: UploadDocumentType
    creationDate: Date
}

export interface IDocumentTable extends IDocument {
    actions: string
}

export interface IUploadDocumentForm {
    titel: string
    omschrijving: string
    type: null | UploadDocumentType
    file: null | File
    link: string
}

export interface IDocumentConfig {
    maxStorageSize: number
    currentStorageSize: number
}

export const DOCUMENT_TYPES = {
    FILE: 'File',
    LINK: 'Link',
} as const

// 'DocumentType' already exists
export type UploadDocumentType = (typeof DOCUMENT_TYPES)[keyof typeof DOCUMENT_TYPES]

export const ARCHIVE_DOCUMENT_TYPES = {
    JAARPLANNING: 0,
    NOTITIES: 1,
} as const

export type ArchiveDocumentTypes = (typeof ARCHIVE_DOCUMENT_TYPES)[keyof typeof ARCHIVE_DOCUMENT_TYPES]

export interface IArchiveFileSchoolYearWrapper {
    schoolYearName: string
    schoolYearId: number
    stamgroepId: number
    stamgroepNaam: string
    files: IArchiveFile[]
}

export interface IArchiveFile {
    fileName: string
    fileType: ArchiveDocumentTypes
    fileContent: string
    contentSize: number
    creationDate: Date
    blobId: string
}

export interface IArchiveDocumentTable extends IArchiveFile {
    actions: string
}

export interface IDownloadArchiveFileRequest {
    groupId: number
    blobIds: string[]
}

export interface IGroupWrapper {
    name: string
    id: number
}

export interface ISchoolGroupWrapper {
    groups: IGroupWrapper[]
    name: string
}

export interface IExportRequest {
    groupIds: number[]
    exportPlanning: boolean
    exportNotities: boolean
    exportResultaten: boolean
}

export interface IArchiveRequest {
    stamgroepIds: number[]
}
