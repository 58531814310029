import { OefenenPeriode } from '@/enums/Timespan'
import { IOefeningenResultCollection, IResultUser } from '@/interfaces/IResult'
import axios, { AxiosResponse } from 'axios'

export default class OefeningenResultService {
    getOverzicht(period: OefenenPeriode): Promise<AxiosResponse<IOefeningenResultCollection>> {
        return axios.get<IOefeningenResultCollection>(`/api/Frontend/Resultaten/Oefening/Overzicht/periode/${period}`)
    }
    getVak(vakLabelId: number, period: OefenenPeriode): Promise<AxiosResponse<IResultUser[]>> {
        return axios.get<IResultUser[]>(`/api/Frontend/Resultaten/Oefening/Vak/${vakLabelId}/periode/${period}`)
    }

    getLeerling(leerlingId: number, period: OefenenPeriode): Promise<AxiosResponse<IOefeningenResultCollection>> {
        return axios.get<IOefeningenResultCollection>(`/api/Frontend/Resultaten/Oefening/Leerling/${leerlingId}/periode/${period}`)
    }
}

export const oefeningenResultService: OefeningenResultService = new OefeningenResultService()
