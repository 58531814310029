import { IArchiveDocumentTable, IDocument, IDocumentConfig, IUploadDocument } from '@/interfaces/IDocument'
import { IMimeTypes } from '@/interfaces/IMimeTypes'
import axios, { AxiosResponse } from 'axios'

export default class DocumentsService {
    getDocuments(groupId: number) {
        return axios.get(`/api/Frontend/Groep/files`, {
            params: {
                groupId: groupId,
            },
        })
    }
    downloadDocument(groupId: number, document: IDocument) {
        return axios.get(`/api/Frontend/Groep/file/`, {
            responseType: 'blob',
            params: {
                groupId: groupId,
                blobId: document.blobId,
            },
        })
    }
    downloadDocumentArchive(groupId: number, document: IArchiveDocumentTable) {
        return axios.get(`/api/Frontend/Export/archiveFile/`, {
            responseType: 'blob',
            params: {
                groupId: groupId,
                blobId: document.blobId,
            },
        })
    }
    uploadDocument(document: IUploadDocument): Promise<AxiosResponse<IDocument>> {
        return axios.post(`/api/Frontend/Groep/file`, document)
    }
    deleteDocument(groupId: number, document: IDocument) {
        return axios.delete(`/api/Frontend/Groep/file`, {
            params: {
                groupId: groupId,
                blobId: document.blobId,
            },
        })
    }
    getConfig(groupId: number): Promise<AxiosResponse<IDocumentConfig>> {
        return axios.get(`/api/Frontend/Groep/file/config`, {
            params: {
                groupId: groupId,
            },
        })
    }
    getExtensions(): Promise<AxiosResponse<string[]>> {
        return axios.get(`/api/Frontend/Groep/file/extensions`)
    }
    getMimetypes(): Promise<AxiosResponse<IMimeTypes>> {
        return axios.get(`/api/Frontend/Groep/file/mimetypes`)
    }
}

export const documentsService = new DocumentsService()
