import { createStore } from 'vuex'

import appModule from '@/store/modules/appModule'
import appcuesState from '@/store/modules/appcuesModule'
import groupState from '@/store/modules/groupsModule'
import homeState from '@/store/modules/homeModule'
import labelsState from '@/store/modules/labelsModule'
import lesdagState from '@/store/modules/lesdagModule'
import noteState from '@/store/modules/notesModule'
import onboardingState from '@/store/modules/onboardingModule'
import planningState from '@/store/modules/planningModule'
import resultCandidateDetailsState from '@/store/modules/resultsCandidateModule'
import resultDetailsState from '@/store/modules/resultsDetailsModule'
import resultState from '@/store/modules/resultsModule'
import settingsState from '@/store/modules/settingsModule'
import userInfoState from '@/store/modules/userInfoModule'
import verdiepenState from '@/store/modules/verdiepenModule'
import documentsModule from './modules/documentsModule'
import exportModule from './modules/exportModule'

export default createStore({
    modules: {
        home: homeState,
        user: userInfoState,
        results: resultState,
        resultsDetails: resultDetailsState,
        resultsCandidateDetails: resultCandidateDetailsState,
        groups: groupState,
        settings: settingsState,
        onboarding: onboardingState,
        notes: noteState,
        planning: planningState,
        lesdag: lesdagState,
        verdiepen: verdiepenState,
        appcues: appcuesState,
        labels: labelsState,
        app: appModule,
        documenten: documentsModule,
        export: exportModule,
    },
})
