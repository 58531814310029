import { JaarPeriode, OefenenPeriode, ToetsenPeriode } from '@/enums/Timespan'
import { Yeargroup } from '@/enums/Yeargroup'
import { ILabel } from '@/interfaces/IPlanning'
import { IResult } from '@/interfaces/IResult'
import { looqinCompetentiesResultService } from '@/services/resultaten/looqinCompetentiesResultService'
import { looqinSociaalEmotioneelResultService } from '@/services/resultaten/looqinSociaalEmotioneelResultService'
import { methodeToetsResultService } from '@/services/resultaten/methodeToetsResultService'
import { oefeningenResultService } from '@/services/resultaten/oefeningenResultService'
import { volgToetsResultService } from '@/services/resultaten/volgToetsResultService'
import { notify } from '@kyvg/vue3-notification'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { MomentoScore } from './../../enums/MomentoResult'
import { ResultType } from './../../enums/ResultType'
import { IResultFilters } from './../../interfaces/IFilters'
import { ILooqingCompetentiesResultCollectionResponse, ILooqingResultCollection, ILooqingSociaalEmotioneelResultCollectionResponse, IOefeningenResultCollection, IOefeningenResultCollectionResponse, IResultCollection, IResultCollectionResponseLike, IToetsenResultCollection, IToetsenResultCollectionResponse } from './../../interfaces/IResult'

const preferredOrder: MomentoScore[] = [MomentoScore.Red, MomentoScore.Orange, MomentoScore.Green, MomentoScore.Unknown]

const showVueNotifyError = (resultType: ResultType, message: string) => {
    notify({
        group: 'requests',
        title: 'Foutmelding',
        type: 'error',
        text: `Er ging iets mis met het ophalen van de resultaten voor ${resultType}, Error: ${message}`,
    })
}

class ResultsState {
    results: IResult[] = []
    resultsOefeningen: IOefeningenResultCollection = {
        moeilijskteOefeningen: [],
        oefeningenResultaat: {
            categorieen: [],
            leerlingen: [],
        },
    }
    resultsToetsen: IToetsenResultCollection = {
        categorieen: [],
        leerlingen: [],
    }
    resultsVolgToetsen: IResultCollection = {
        categorieen: [],
        leerlingen: [],
    }
    resultsLooqing: ILooqingResultCollection = {
        competenties: {
            categorieen: [],
            leerlingen: [],
        },
        sociaalEmotioneel: {
            categorieen: [],
            leerlingen: [],
        },
    }
    categories: ILabel[] = []
    filters: IResultFilters = {
        YeargroupFilter: Yeargroup.ALLE,
        TimespanFilter: OefenenPeriode.THIS_WEEK,
        ToetsTimespanFilter: ToetsenPeriode.THIS_WEEK,
        VolgenTimespanFilter: JaarPeriode.THIS_SCHOOLYEAR,
        SociaalTimespanFilter: JaarPeriode.THIS_SCHOOLYEAR,
        CompetentiesTimespanFilter: JaarPeriode.THIS_SCHOOLYEAR,
    }
    loading = false
    oefenenLoading = false
    toetsenLoading = false
    volgToetsenLoading = false
    sociaalEmotioneelLoading = false
    competentiesLoading = false
}

const getters: GetterTree<ResultsState, any> = {
    getTimespanFilter(store) {
        return () => store.filters.TimespanFilter
    },
    getToetsTimespanFilter(store) {
        return () => store.filters.ToetsTimespanFilter
    },
    getVolgenTimespanFilter(store) {
        return () => store.filters.VolgenTimespanFilter
    },
    getYeargroupFilter(store) {
        return () => store.filters.YeargroupFilter
    },
    getResults(store) {
        return store.results
    },
    getResultsOefeningen(store) {
        const oefeningen = store.resultsOefeningen
        oefeningen.oefeningenResultaat.categorieen.sort((a, b) => {
            return preferredOrder.indexOf(a.momentoResultaat) - preferredOrder.indexOf(b.momentoResultaat)
        })
        oefeningen.oefeningenResultaat.leerlingen.sort((a, b) => {
            return preferredOrder.indexOf(a.momentoResultaat) - preferredOrder.indexOf(b.momentoResultaat)
        })
        oefeningen.moeilijskteOefeningen.sort((a, b) => {
            return preferredOrder.indexOf(a.momentoResultaat) - preferredOrder.indexOf(b.momentoResultaat)
        })
        return oefeningen
    },
    getResultsToetsen(store) {
        const toetsen = store.resultsToetsen
        toetsen.categorieen.sort((a: any, b: any) => {
            return preferredOrder.indexOf(a.momentoResultaat) - preferredOrder.indexOf(b.momentoResultaat)
        })
        toetsen.leerlingen.sort((a: any, b: any) => {
            return preferredOrder.indexOf(a.momentoResultaat) - preferredOrder.indexOf(b.momentoResultaat)
        })
        return toetsen
    },
    getResultsVolgToetsen(store) {
        const volgtoetsen = store.resultsVolgToetsen
        volgtoetsen.categorieen.sort((a: any, b: any) => {
            return preferredOrder.indexOf(a.momentoResultaat) - preferredOrder.indexOf(b.momentoResultaat)
        })
        volgtoetsen.leerlingen.sort((a: any, b: any) => {
            return preferredOrder.indexOf(a.momentoResultaat) - preferredOrder.indexOf(b.momentoResultaat)
        })
        return volgtoetsen
    },
    getResultsSociaalEmotioneel(store) {
        const sociaalEmotioneel = store.resultsLooqing.sociaalEmotioneel
        sociaalEmotioneel.categorieen.sort((a: any, b: any) => {
            return preferredOrder.indexOf(a.momentoResultaat) - preferredOrder.indexOf(b.momentoResultaat)
        })
        sociaalEmotioneel.leerlingen.sort((a: any, b: any) => {
            return preferredOrder.indexOf(a.momentoResultaat) - preferredOrder.indexOf(b.momentoResultaat)
        })
        return sociaalEmotioneel
    },
    getResultsCompetenties(store) {
        const competenties = store.resultsLooqing.competenties
        competenties.categorieen.sort((a: any, b: any) => {
            return preferredOrder.indexOf(a.momentoResultaat) - preferredOrder.indexOf(b.momentoResultaat)
        })
        competenties.leerlingen.sort((a: any, b: any) => {
            return preferredOrder.indexOf(a.momentoResultaat) - preferredOrder.indexOf(b.momentoResultaat)
        })
        return competenties
    },
    getResultsLoading(store) {
        return () => ({
            loading: store.loading,
            oefenenLoading: store.oefenenLoading,
            toetsenLoading: store.toetsenLoading,
            volgToetsenLoading: store.volgToetsenLoading,
            sociaalEmotioneelLoading: store.sociaalEmotioneelLoading,
            competentiesLoading: store.competentiesLoading,
        })
    },
    getCategories(store) {
        return () => store.categories
    },
    getCategoryOptions(store): () => {
        id: number
        naam: string
        disabled: boolean
    }[] {
        const categories = store.categories
        const categoriesWithResults = [...store.resultsToetsen.categorieen, ...store.resultsOefeningen.oefeningenResultaat.categorieen]
        const categoryOptions = categories
            .map((cat) => {
                return {
                    id: cat.labelId,
                    naam: cat.naam,
                    disabled: categoriesWithResults.filter((cat) => cat.momentoResultaat !== MomentoScore.Unknown).findIndex((catres) => catres.categorieId === cat.labelId) === -1,
                }
            })
            .sort((a, b) => {
                if (a.naam < b.naam) {
                    return -1
                }
                if (a.naam > b.naam) {
                    return 1
                }
                return 0
            })
        return () => categoryOptions
    },
}

const mutations: MutationTree<ResultsState> = {
    setFilters(state, payload: any) {
        state.filters = { ...state.filters, ...payload }
    },
    setResultsLoading(state, payload: boolean) {
        state.loading = payload
    },
    setResults(state, payload: IResult[]) {
        state.results = payload
    },
    setResultsByType(state, payload: IResultCollectionResponseLike) {
        switch (payload.resultType) {
            case ResultType.OEFENINGEN:
                payload.data.oefeningenResultaat.categorieen = payload.data.oefeningenResultaat.categorieen.sort((a, b) => a.naam.localeCompare(b.naam))
                payload.data.moeilijskteOefeningen = payload.data.moeilijskteOefeningen.sort((a, b) => a.toetsName.localeCompare(b.toetsName))
                state.resultsOefeningen = payload.data
                break
            case ResultType.TOETSEN:
                payload.data.categorieen = payload.data.categorieen.sort((a, b) => a.naam.localeCompare(b.naam))
                state.resultsToetsen = payload.data
                break
            case ResultType.VOLGTOETSEN:
                payload.data.categorieen = payload.data.categorieen.sort((a, b) => a.naam.localeCompare(b.naam))
                state.resultsVolgToetsen = payload.data
                break
            case ResultType.SOCIAAL_EMOTIONEEL:
                payload.data.categorieen = payload.data.categorieen.sort((a, b) => a.naam.localeCompare(b.naam))
                state.resultsLooqing.sociaalEmotioneel = payload.data
                break
            case ResultType.COMPETENTIES:
                payload.data.categorieen = payload.data.categorieen.sort((a, b) => a.naam.localeCompare(b.naam))
                state.resultsLooqing.competenties = payload.data
                break
            default:
                break
        }
    },
    setCategories(state, payload: ILabel[]) {
        state.categories = payload
    },
}

const actions: ActionTree<ResultsState, any> = {
    async getOefeningenResultsOverzicht({ commit, state }) {
        state.oefenenLoading = true

        try {
            const data = await oefeningenResultService.getOverzicht(state.filters.TimespanFilter)
            commit('setResultsByType', { resultType: ResultType.OEFENINGEN, data: data.data })
            return { resultType: ResultType.OEFENINGEN, data: data.data } as IOefeningenResultCollectionResponse
        } catch (error: any) {
            showVueNotifyError(ResultType.OEFENINGEN, error.message)
            throw error
        } finally {
            state.oefenenLoading = false
        }
    },
    async getVolgToetsenResultsOverzicht({ commit, state }) {
        state.volgToetsenLoading = true

        try {
            const data = await volgToetsResultService.getOverzicht(state.filters.VolgenTimespanFilter)
            commit('setResultsByType', { resultType: ResultType.VOLGTOETSEN, data: data.data })
            return { resultType: ResultType.VOLGTOETSEN, data: data.data } as IToetsenResultCollectionResponse
        } catch (error: any) {
            showVueNotifyError(ResultType.VOLGTOETSEN, error.message)
            throw error
        } finally {
            state.volgToetsenLoading = false
        }
    },
    async getMethodeToetsenResultsOverzicht({ commit, state }) {
        state.toetsenLoading = true

        try {
            const data = await methodeToetsResultService.getOverzicht(state.filters.ToetsTimespanFilter)
            commit('setResultsByType', { resultType: ResultType.TOETSEN, data: data.data })
            return { resultType: ResultType.TOETSEN, data: data.data } as IToetsenResultCollectionResponse
        } catch (error: any) {
            showVueNotifyError(ResultType.TOETSEN, error.message)
            throw error
        } finally {
            state.toetsenLoading = false
        }
    },
    async getLooqingSociaalEmotioneelResultsOverzicht({ commit, state }) {
        state.sociaalEmotioneelLoading = true

        try {
            const data = await looqinSociaalEmotioneelResultService.getOverzicht(state.filters.SociaalTimespanFilter)
            commit('setResultsByType', { resultType: ResultType.SOCIAAL_EMOTIONEEL, data: data.data })
            return { resultType: ResultType.SOCIAAL_EMOTIONEEL, data: data.data } as ILooqingSociaalEmotioneelResultCollectionResponse
        } catch (error: any) {
            showVueNotifyError(ResultType.SOCIAAL_EMOTIONEEL, error.message)
            throw error
        } finally {
            state.sociaalEmotioneelLoading = false
        }
    },
    async getLooqingCompetentiesResultsOverzicht({ commit, state }) {
        state.competentiesLoading = true

        try {
            const data = await looqinCompetentiesResultService.getOverzicht(state.filters.SociaalTimespanFilter)
            commit('setResultsByType', { resultType: ResultType.COMPETENTIES, data: data.data })
            return { resultType: ResultType.COMPETENTIES, data: data.data } as ILooqingCompetentiesResultCollectionResponse
        } catch (error: any) {
            showVueNotifyError(ResultType.COMPETENTIES, error.message)
            throw error
        } finally {
            state.competentiesLoading = false
        }
    },
    getAllResultsOverzicht({ dispatch }) {
        dispatch('getOefeningenResultsOverzicht')
        dispatch('getVolgToetsenResultsOverzicht')
        dispatch('getMethodeToetsenResultsOverzicht')
        dispatch('getLooqingSociaalEmotioneelResultsOverzicht')
        dispatch('getLooqingCompetentiesResultsOverzicht')
    },
}

export default {
    state: new ResultsState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}
