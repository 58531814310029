import Vue from 'vue'
import { ActionTree, GetterTree, MutationTree, createStore } from 'vuex'

import { OefenenPeriode } from '@/enums/Timespan'
import { Yeargroup } from '@/enums/Yeargroup'
import { ILesdag } from '@/interfaces/ILesdag'
import { ILesdagSpeciaal } from '@/interfaces/ILesdagSpeciaal'
import { INote, INoteWithLabel } from '@/interfaces/INote'
import { IOefeningenResultCollectionHome } from '@/interfaces/IResult'
import { yourDayService } from '@/services/LesdagenService'
import { notesService } from '@/services/noteService'
import { resultService } from '@/services/resultService'
import { notify } from '@kyvg/vue3-notification'

class HomeState {
    loading = false
    planningLoading = false
    resultsLoading = false
    notesLoading = false
    currentLesdag = {
        start: new Date(),
        end: new Date(),
    }
    lesdag: ILesdag[] = []
    lesdagSpeciaal: ILesdagSpeciaal[] = []
    notes: INoteWithLabel[] = []
    results: IOefeningenResultCollectionHome = {
        vakgebiedDetails: [],
        moeilijskteOefeningen: [],
    }
}

const getters: GetterTree<HomeState, any> = {
    homeNotesLoading(state) {
        return () => state.notesLoading
    },
    homeResultsLoading(state) {
        return () => state.resultsLoading
    },
    homePlanningLoading(state) {
        return () => state.planningLoading
    },
    lesdagHome(state) {
        return () => state.lesdag
    },
    lesdagSpeciaalHome(state) {
        return () => state.lesdagSpeciaal
    },
    notesHome(state) {
        return () =>
            state.notes.sort((a, b) => {
                if (a.datum && b.datum) {
                    if (new Date(a.datum).getTime() > new Date(b.datum).getTime()) {
                        return -1
                    }
                    if (new Date(a.datum).getTime() < new Date(b.datum).getTime()) {
                        return 1
                    }
                }
                return 0
            })
    },
    resultsHome(state) {
        return () => state.results
    },
}

const mutations: MutationTree<HomeState> = {
    setHomeLoading(state, payload) {
        state.loading = payload
    },
    setHomeNotesLoading(state, payload) {
        state.notesLoading = payload
    },
    setHomePlanningLoading(state, payload) {
        state.planningLoading = payload
    },
    setHomeResultsLoading(state, payload) {
        state.resultsLoading = payload
    },
    setLesdagTijdHome(state, payload: any) {
        state.currentLesdag = payload
    },
    setLesdagHome(state, payload) {
        state.lesdag = payload
    },
    setLesdagSpeciaalHome(state, payload) {
        state.lesdagSpeciaal = payload
    },
    setNotesHome(state, payload) {
        state.notes = payload
    },
    setResultsHome(state, payload) {
        state.results = payload
    },
    pinNoteHome(state, payload: INoteWithLabel) {
        const index = state.notes.findIndex((note) => note.id === payload.id)
        index > -1 ? (state.notes[index].vastgepind = payload.vastgepind) : null
    },
    archiveNoteHome(state, payload: INoteWithLabel) {
        const index = state.notes.findIndex((note) => note.id === payload.id)
        index > -1 ? (state.notes[index].gearchiveerd = payload.gearchiveerd) : null
    },
}

const actions: ActionTree<HomeState, any> = {
    getLesdagHome({ commit, rootState, state }) {
        return new Promise((resolve, reject) => {
            const date = new Date(state.currentLesdag.start)
            date.setHours(12)
            yourDayService
                .getLesdagen(rootState.groups.selectedGroup.id, date.toISOString())
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setLesdagHome', data.data as ILesdag[])
                        resolve(data.data as ILesdag[])
                    }
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    getLesdagSpeciaalHome({ commit, rootState, state }) {
        return new Promise((resolve, reject) => {
            const date = new Date(state.currentLesdag.start)
            date.setHours(12)
            yourDayService
                .getLesdagenSpeciaal(rootState.groups.selectedGroup.id, date.toISOString())
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setLesdagSpeciaalHome', data.data as ILesdagSpeciaal[])
                        resolve(data.data as ILesdagSpeciaal[])
                    }
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    getNotesHome({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            commit('setHomeNotesLoading', true)
            notesService
                .getNotesTwoWeeks(rootState.groups.selectedGroup.id)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                        commit('setHomeNotesLoading', false)
                    } else {
                        commit('setNotesHome', data.data as INote[])
                        commit('setNotes', data.data as INote[])
                        commit('setHomeNotesLoading', false)
                        resolve(data.data as INote[])
                    }
                })
                .catch((e: Error) => {
                    commit('setHomeNotesLoading', false)
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    getResultsHome({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            commit('setHomeResultsLoading', true)
            resultService
                .getResultsHome({
                    YeargroupFilter: Yeargroup.ALLE,
                    TimespanFilter: OefenenPeriode.PAST_TWO_WEEKS,
                })
                .then((data) => {
                    commit('setHomeResultsLoading', false)
                    commit('setResultsHome', data.data as IOefeningenResultCollectionHome[])
                    resolve(data.data)
                })
                .catch((e) => {
                    commit('setHomeResultsLoading', false)
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
}

export default {
    state: new HomeState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}
