<template>
    <div
        class="tippy-no-padding"
        v-if="shouldShowSupportButton"
    >
        <tippy
            id="support-button"
            placement="top"
            trigger="click"
            interactive
        >
            <div
                class="feedback-btn-wrapper"
                id="popover-target-1"
                tabindex="-1"
            >
                <div class="feedback-btn-icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-help-circle"
                    >
                        <circle
                            cx="12"
                            cy="12"
                            r="10"
                        ></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line
                            x1="12"
                            y1="17"
                            x2="12.01"
                            y2="17"
                        ></line>
                    </svg>
                </div>
            </div>
            <template #content>
                <div id="popover-menu">
                    <div class="support-wrapper">
                        <a
                            @click="goToFaq()"
                            class="support-button"
                        >
                            <div class="support-button__title">Veelgestelde vragen</div>
                            <div class="support-button__subtitle">Doorzoek de kennisbank, bekijk uitlegvideo's en antwoorden op veelgestelde vragen.</div>
                        </a>
                        <a
                            @click="goToHelpDesk()"
                            class="support-button"
                        >
                            <div class="support-button__title">Ik heb hulp nodig</div>
                            <div class="support-button__subtitle">Bekijk de contactgegevens om jouw vraag telefonisch of per mail te kunnen stellen.</div>
                        </a>
                        <a
                            @click="goToFeedbackPage()"
                            class="support-button"
                        >
                            <div class="support-button__title">Feedback</div>
                            <div class="support-button__subtitle">Geef hier aan ons door hoe we Momento nog verder voor je kunnen verbeteren.</div>
                        </a>
                        <a
                            @click="goToChangelogPage()"
                            class="support-button"
                        >
                            <div class="support-button__title">Wat is nieuw?</div>
                            <div class="support-button__subtitle">Bekijk welke functionaliteiten onlangs zijn toegevoegd aan Momento.</div>
                        </a>
                        <a
                            @click="goToTourPage()"
                            class="support-button"
                        >
                            <div class="support-button__title">Instructievideo's</div>
                            <div class="support-button__subtitle">Bekijk de Momento instructievideo's</div>
                        </a>
                    </div>
                </div>
            </template>
        </tippy>
    </div>
</template>

<script setup lang="ts">
import { useStore } from '@/composables/useStore'
import { computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const { userStore } = useStore()
const $route = useRoute()
const $router = useRouter()

onMounted(() => {
    if (!import.meta.env.PROD) return
    window.Appcues.on('flow_skipped', () => {
        $router.replace({ path: $route.path, query: { ...$route.query, appcue: undefined } })
    })
})

const goToFeedbackPage = () => {
    userStore.actions.goToFeedbackPortal()
}

const shouldShowSupportButton = computed(() => {
    return !$route.path.startsWith('/widget/moo')
})

const goToChangelogPage = () => {
    userStore.actions.goToFeedbackPortal('https://feedback.momento.nl/f/changelog/')
}

const goToTourPage = () => {
    $router.replace({ path: $route.path, query: { ...$route.query, appcue: 'be739fd3-0be5-410a-a1b6-7ea3b560b7ea' } })
}

const goToHelpDesk = () => {
    window.open('https://momento4079.zendesk.com/hc/nl/articles/7963881890461-Hoe-kom-ik-in-contact-met-Momento', '_blank')
}

const goToFaq = () => {
    window.open('https://momento4079.zendesk.com/hc/nl', '_blank')
}
</script>
