import helperConstants from '@/helpers/helperConstants'
import { IGroup, IYearGroupLabel } from '@/interfaces/IGroup'
import { IGroupSettings } from '@/interfaces/IGroupSettings'
import { IUser } from '@/interfaces/IUser'
import { groupService } from '@/services/groupService'
import { notify } from '@kyvg/vue3-notification'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

class GroupState {
    groups: IGroup[] = []
    candidates: IUser[] = []
    yeargroups: IYearGroupLabel[] = []
    selectedGroup: IGroup = {
        id: -1,
        naam: '',
        isSubGroep: false,
        hasBasisrooster: false,
    }
    groupSettings: IGroupSettings = {
        groupId: -1,
        lesdagStartTijd: helperConstants.LESDAGSTARTTIJD,
        lesdagEindTijd: helperConstants.LESDAGEINDTIJD,
    }
}

const getters: GetterTree<GroupState, any> = {
    groups(store) {
        return () => store.groups
    },
    candidates(store) {
        return () =>
            store.candidates
                .filter((candidate) => candidate.type === 1)
                .map((c: IUser) => {
                    return {
                        id: c.id,
                        jaargroep: c.jaargroep,
                        type: c.type,
                        email: c.email,
                        voornaam: c.voornaam,
                        achternaam: c.achternaam,
                        tussenvoegsel: c.tussenvoegsel,
                        naam: [c.voornaam, c.tussenvoegsel, c.achternaam].filter(Boolean).join(' '),
                        onboardingAfgerond: c.onboardingAfgerond,
                    }
                })
    },
    getYeargroups(store) {
        return () =>
            store.candidates
                .filter((candidate) => candidate.type === 1)
                .map((candidate) => candidate.jaargroep)
                .filter((value, index, self) => self.indexOf(value) === index)
    },
    getSelectedGroup(store) {
        return () => store.selectedGroup
    },
    yeargroupsInGroup(store) {
        return () =>
            store.yeargroups.sort((a, b) => {
                if (a.naam < b.naam) {
                    return -1
                }
                if (a.naam > b.naam) {
                    return 1
                }
                return 0
            })
    },
    getGroupSettings(store) {
        return () => store.groupSettings
    },
}

const mutations: MutationTree<GroupState> = {
    setGroups(state, payload: IGroup[]) {
        state.groups = payload
    },
    setCandidates(state, payload: IUser[]) {
        state.candidates = payload
    },
    setYeargroups(state, payload: any) {
        state.yeargroups = payload
    },
    setSelectedGroup(state, payload: IGroup) {
        state.selectedGroup = payload
    },
    setGroupSettings(state, payload: IGroupSettings) {
        state.groupSettings = payload
    },
}

const actions: ActionTree<GroupState, any> = {
    getGroups({ commit }) {
        return new Promise((resolve, reject) => {
            groupService
                .getGroups()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setGroups', data.data as IGroup[])
                        resolve(data.data as IGroup[])
                    }
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    getYeargroups({ commit, state }) {
        return new Promise((resolve, reject) => {
            groupService
                .getYeargroups(state.selectedGroup.id)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setYeargroups', data.data)
                        resolve(data.data)
                    }
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    getCandidates({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            const group = payload ? payload : state.selectedGroup.id
            groupService
                .getCandidatesByGroupId(group)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setCandidates', data.data as IUser[])
                        resolve(data.data as IUser[])
                    }
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    getGroupSettings({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            groupService
                .getGroupSettings(state.selectedGroup.id)
                .then((response) => {
                    if (response.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setGroupSettings', response.data as IGroupSettings)
                        resolve(response.data as IGroupSettings)
                    }
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    updateGroupSettings({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            groupService
                .updateGroupSettings(state.selectedGroup.id, { groepId: state.selectedGroup.id, ...payload })
                .then((response) => {
                    if (response.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setGroupSettings', response.data as IGroupSettings)
                        resolve(response.data as IGroupSettings)
                    }
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
}

export default {
    state: new GroupState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}
