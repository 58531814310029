import { UitgeverStatus } from './../../enums/Uitgeverstatus'
import { IUserLogin } from './../../interfaces/IUser'
import { IPublisher } from './../../interfaces/IPublisher'
import { ISupplier } from './../../interfaces/ISupplier'
import { ISchool } from './../../interfaces/ISchool'
import { settingsService } from './../../services/settingsService'
import { GetterTree, MutationTree, ActionTree, createStore } from 'vuex'
import Vue from 'vue'
import { onboardingService } from '@/services/onboardingService'
import { notify } from '@kyvg/vue3-notification'

class SettingsState {
    school: ISchool = {} as ISchool
    leveranciers = {
        availableLeveranciers: [] as ISupplier[],
        chosenLeverancier: {} as ISupplier,
    }
    uitgevers: IPublisher[] = []
    lastLogins: IUserLogin[] = []
    pollingData = false
    regios: any[] = []
    verwerkingsOvereenkomstStatus: number | string | null = null
    verwerkingsOvereenkomstOk = false
    showVerwerkersBtn = false
}

const getters: GetterTree<SettingsState, any> = {
    schoolInfo(store) {
        return () => store.school
    },
    leveranciers(store) {
        const leveranciers = store.leveranciers
        const sortedLeveranciers = leveranciers.availableLeveranciers.sort((a, b) => {
            const naamA = a.naam.toUpperCase()
            const naamB = b.naam.toUpperCase()
            if (naamA < naamB) {
                return -1
            }
            if (naamA > naamB) {
                return 1
            }
            return 0
        })
        return () => ({ ...store.leveranciers, availableLeveranciers: sortedLeveranciers })
    },
    uitgevers(store) {
        return () => store.uitgevers
    },
    lastLogins(store) {
        return () =>
            store.lastLogins
                .map((login: IUserLogin) => {
                    return {
                        ...login,
                        naam: [login.voornaam, login.tussenvoegsel, login.achternaam].filter(Boolean).join(' '),
                    }
                })
                .sort((a, b) => {
                    if (a.laatstIngelogdOp && b.laatstIngelogdOp) {
                        if (new Date(a.laatstIngelogdOp).getTime() > new Date(b.laatstIngelogdOp).getTime()) {
                            return -1
                        }
                        if (new Date(a.laatstIngelogdOp).getTime() < new Date(b.laatstIngelogdOp).getTime()) {
                            return 1
                        }
                    }
                    return 0
                })
    },
    pollingState(store) {
        return () => store.pollingData
    },
    schoolregios(store) {
        return () => store.regios
    },
    verwerkersovereenkomst(store) {
        return () => store.verwerkingsOvereenkomstStatus
    },
    verwerkersOvereenkomstOk(store) {
        return () => store.verwerkingsOvereenkomstOk
    },
    showVerwerkersBtn(store) {
        return () => store.showVerwerkersBtn
    },
}

const mutations: MutationTree<SettingsState> = {
    setSchool(state, payload) {
        state.school = payload
    },
    setLeveranciers(state, payload) {
        state.leveranciers = payload
    },
    setUitgevers(state, payload) {
        state.uitgevers = payload
    },
    updateLeverancier(state, payload) {
        state.leveranciers.chosenLeverancier = payload
    },
    setLastlogins(state, payload) {
        state.lastLogins = payload
    },
    setPollingState(state, payload) {
        state.pollingData = payload
    },
    setVerwerkersOvereenkomst(state, payload) {
        state.verwerkingsOvereenkomstStatus = payload
    },
    setVerwerkersOvereenkomstOk(state, payload) {
        state.verwerkingsOvereenkomstOk = payload
    },
    setSchoolRegios(state, payload) {
        state.regios = payload
    },
    setVerwerkersOvereenkomstBtn(state, payload) {
        state.showVerwerkersBtn = payload
    },
}

const actions: ActionTree<SettingsState, any> = {
    getSchool({ commit }) {
        return new Promise((resolve, reject) => {
            settingsService
                .getSchool()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setSchool', data.data)
                        resolve(data.data)
                    }
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er ging iets mis met het ophalen van de schoolinformatie, Error: ${e.message}`,
                    })
                })
        })
    },
    getLeveranciers({ commit }) {
        return new Promise((resolve, reject) => {
            settingsService
                .getLeveranciers()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setLeveranciers', data.data)
                        resolve(data.data)
                    }
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er ging iets mis met het ophalen van de leveranciers, Error: ${e.message}`,
                    })
                })
        })
    },
    getUitgevers({ commit }) {
        return new Promise((resolve, reject) => {
            settingsService
                .getUitgevers()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setUitgevers', data.data)
                        resolve(data.data)
                    }
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er ging iets mis met het ophalen van de uitgevers, Error: ${e.message}`,
                    })
                })
        })
    },
    getVerwerkersOvereenkomst({ commit }) {
        return new Promise((resolve, reject) => {
            settingsService
                .getVerwerkersOvereenkomst()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setVerwerkersOvereenkomst', data.data)
                        const isOk = data.data === 9
                        const showBtn = data.data === 0 || data.data === 99
                        commit('setVerwerkersOvereenkomstOk', isOk)
                        commit('setVerwerkersOvereenkomstBtn', showBtn)
                        resolve(data.data)
                    }
                })
                .catch((e: Error) => {
                    commit('setVerwerkersOvereenkomst', `Fout bij ophalen status. \n (Error: ${e.message})`)
                    commit('setVerwerkersOvereenkomstOk', false)
                    reject(e)
                })
        })
    },
    getSchoolRegios({ commit }) {
        return new Promise((resolve, reject) => {
            onboardingService
                .getRegios()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setSchoolRegios', data.data)
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    getLastLogins({ commit }) {
        return new Promise((resolve, reject) => {
            settingsService
                .getlastLogins()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setLastlogins', data.data)
                        resolve(data.data)
                    }
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er ging iets mis met het ophalen van de laatste logins, Error: ${e.message}`,
                    })
                })
        })
    },
    setLeverancier({ state }) {
        return new Promise((resolve, reject) => {
            settingsService
                .setLeverancier(state.leveranciers.chosenLeverancier.id)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                        notify({
                            group: 'requests',
                            title: 'Gelukt',
                            type: 'success',
                            text: `Leverancier gewijzigd!`,
                        })
                    }
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er ging iets mis met het updaten van de leverancier, Error: ${e.message}`,
                    })
                })
        })
    },
}

export default {
    state: new SettingsState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}
