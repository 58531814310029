import { LabelType } from '@/enums/LabelType'
import { ILabelKleurDto } from '@/interfaces/ILabelKleur'
import { groupService } from '@/services/groupService'
import { labelsService } from '@/services/labelsService'
import Vue from 'vue'
import { ActionTree, GetterTree, MutationTree, createStore } from 'vuex'
import { ILabel } from './../../interfaces/IPlanning'
import { notify } from '@kyvg/vue3-notification'

class LabelsState {
    vakLabels: ILabel[] = []
    notitieLabels: ILabel[] = []
    verdiepenLabels: ILabel[] = []
    alleJaargroepenLabels: ILabel[] = []
    jaargroepenLabels: ILabel[] = []
    standaardLabelKleuren: ILabel[] = []
    labelColorsLoading: boolean = false
}

const getters: GetterTree<LabelsState, any> = {
    vakLabels(state) {
        return () =>
            state.vakLabels.sort((a, b) => {
                if (a.naam < b.naam) {
                    return -1
                }
                if (a.naam > b.naam) {
                    return 1
                }
                return 0
            })
    },
    notitieLabels(state) {
        return () =>
            state.notitieLabels.sort((a, b) => {
                if (a.naam < b.naam) {
                    return -1
                }
                if (a.naam > b.naam) {
                    return 1
                }
                return 0
            })
    },
    verdiepenLabels(state) {
        return () =>
            state.verdiepenLabels.sort((a, b) => {
                if (a.naam < b.naam) {
                    return -1
                }
                if (a.naam > b.naam) {
                    return 1
                }
                return 0
            })
    },
    alleJaargroepenLabels(state) {
        return () =>
            state.alleJaargroepenLabels.sort((a, b) => {
                if (a.naam < b.naam) {
                    return -1
                }
                if (a.naam > b.naam) {
                    return 1
                }
                return 0
            })
    },
    jaargroepenLabels(state) {
        return () =>
            state.jaargroepenLabels.sort((a, b) => {
                if (a.naam < b.naam) {
                    return -1
                }
                if (a.naam > b.naam) {
                    return 1
                }
                return 0
            })
    },
    standaardLabelKleuren(state) {
        return () => state.standaardLabelKleuren
    },
    labelColorsLoading(state) {
        return () => state.labelColorsLoading
    },
}

const mutations: MutationTree<LabelsState> = {
    setVakLabels(state, payload: ILabel[]) {
        state.vakLabels = payload
    },
    setNotitieLabels(state, payload: ILabel[]) {
        state.notitieLabels = payload
    },
    setVerdiepenLabels(state, payload: ILabel[]) {
        state.verdiepenLabels = payload
    },
    setAlleJaargroepenLabels(state, payload: ILabel[]) {
        state.alleJaargroepenLabels = payload
    },
    setJaargroepenLabels(state, payload: ILabel[]) {
        state.jaargroepenLabels = payload
    },
    setStandaardLabelKleuren(state, payload: ILabel[]) {
        state.standaardLabelKleuren = payload
    },
    setLabelColorsLoading(state, payload: boolean) {
        state.labelColorsLoading = payload
    },
}

const actions: ActionTree<LabelsState, any> = {
    getVakLabels({ commit }) {
        return new Promise((resolve, reject) => {
            const vaklabels = labelsService.getLabelsByType(LabelType.Vakgebied)
            const activiteitlabels = labelsService.getLabelsByType(LabelType.Activiteiten)
            Promise.all([vaklabels, activiteitlabels])
                .then(([vakken, activiteiten]) => {
                    const vakData = vakken.data.map((vak: ILabel) => {
                        return {
                            ...vak,
                            labelType: LabelType.Vakgebied,
                        }
                    })

                    const activiteitData = activiteiten.data.map((vak: ILabel) => {
                        return {
                            ...vak,
                            labelType: LabelType.Activiteiten,
                        }
                    })

                    commit('setVakLabels', [...vakData, ...activiteitData])
                    commit('setCategories', vakData)
                    resolve([...vakData, ...activiteitData])
                })
                .catch((e) => reject(e))
        })
    },
    getNotitieLabels({ commit }) {
        return new Promise((resolve, reject) => {
            labelsService
                .getLabelsByType(LabelType.NotitieOnderwerp)
                .then((data) => {
                    commit('setNotitieLabels', data.data)
                    resolve(data.data)
                })
                .catch((e) => reject(e))
        })
    },
    getVerdiepenLabels({ commit }) {
        return new Promise((resolve, reject) => {
            labelsService
                .getLabelsByType(LabelType.VerdiepingsCategorie)
                .then((data) => {
                    commit('setVerdiepenLabels', data.data)
                    resolve(data.data)
                })
                .catch((e) => reject(e))
        })
    },
    getAlleJaargroepenLabels({ commit }) {
        //alle labels
        return new Promise((resolve, reject) => {
            labelsService
                .getLabelsByType(LabelType.Jaargroep)
                .then((data) => {
                    commit('setAlleJaargroepenLabels', data.data)
                    resolve(data.data)
                })
                .catch((e) => reject(e))
        })
    },
    getJaargroepenLabels({ commit, rootState }) {
        //jaargroeplabels die in de huidige groep zitten
        return new Promise((resolve, reject) => {
            groupService
                .getYeargroups(rootState.groups.selectedGroup.id)
                .then((data) => {
                    commit('setJaargroepenLabels', data.data)
                    commit('setYeargroups', data.data)
                    resolve(data.data)
                })
                .catch((e) => reject(e))
        })
    },
    setCustomLabelKleuren({ commit, dispatch }, labelKleuren: ILabelKleurDto[]) {
        return new Promise((resolve, reject) => {
            commit('setLabelColorsLoading', true)
            labelsService
                .uploadCustomColours(labelKleuren)
                .then((data) => {
                    dispatch('getVakLabels')
                        .then(() => {
                            notify({
                                group: 'requests',
                                title: 'Gelukt',
                                type: 'success',
                                text: `De wijzigingen zijn succesvol opgeslagen!`,
                            })
                            resolve(data)
                        })
                        .catch((e) => {
                            throw e
                        })
                        .finally(() => {
                            commit('setLabelColorsLoading', false)
                        })
                })
                .catch((e) => {
                    commit('setLabelColorsLoading', false)
                    notify({
                        group: 'requests',
                        title: 'Fout!',
                        type: 'error',
                        text: `Er ging iets mis met het opslaan van de wijzigingen!`,
                    })
                    reject(e)
                })
        })
    },
    getStandaardLabelKleuren({ commit }) {
        return new Promise((resolve, reject) => {
            labelsService
                .getVakLabelKleuren()
                .then((data) => {
                    commit('setStandaardLabelKleuren', data.data)
                    resolve(data.data)
                })
                .catch((e) => reject(e))
        })
    },
    resetAllLabelColors({ commit, dispatch }, creatorType: 1 | 2) {
        return new Promise((resolve, reject) => {
            commit('setLabelColorsLoading', true)
            labelsService
                .resetAllLabelColors(creatorType)
                .then((data) => {
                    dispatch('getVakLabels')
                        .then(() => {
                            notify({
                                group: 'requests',
                                title: 'Gelukt',
                                type: 'success',
                                text: `Alle kleuren zijn succesvol gereset!`,
                            })
                            resolve(data)
                        })
                        .catch((e) => {
                            throw e
                        })
                        .finally(() => {
                            commit('setLabelColorsLoading', false)
                        })
                })
                .catch((e) => {
                    notify({
                        group: 'requests',
                        title: 'Fout!',
                        type: 'error',
                        text: `Er ging iets mis met het resetten van de kleuren!`,
                    })
                    commit('setLabelColorsLoading', false)
                    reject(e)
                })
        })
    },
    resetLabelColor({ commit, dispatch }, payload: { labelId: number; creatorType: 1 | 2 }) {
        return new Promise((resolve, reject) => {
            commit('setLabelColorsLoading', true)
            labelsService
                .resetLabelColor(payload.labelId, payload.creatorType)
                .then((data) => {
                    dispatch('getVakLabels')
                        .then(() => {
                            resolve(data)
                        })
                        .catch((e) => {
                            throw e
                        })
                        .finally(() => {
                            commit('setLabelColorsLoading', false)
                        })
                })
                .catch((e) => {
                    commit('setLabelColorsLoading', false)
                    reject(e)
                })
        })
    },
}

export default {
    state: new LabelsState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}
