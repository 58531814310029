import { ToetsenPeriode } from '@/enums/Timespan'
import { IResultCollection, IResultUser } from '@/interfaces/IResult'
import axios from 'axios'

export default class MethodeToetsResultService {
    getOverzicht(period: ToetsenPeriode) {
        return axios.get<IResultCollection>(`api/Frontend/Resultaten/MethodeToets/Overzicht/periode/${period}`)
    }
    getVak(vakLabelId: number, period: ToetsenPeriode) {
        return axios.get<IResultUser[]>(`api/Frontend/Resultaten/MethodeToets/Vak/${vakLabelId}/periode/${period}`)
    }

    getLeerling(leerlingId: number, period: ToetsenPeriode) {
        return axios.get<IResultCollection>(`api/Frontend/Resultaten/MethodeToets/Leerling/${leerlingId}/periode/${period}`)
    }
}

export const methodeToetsResultService: MethodeToetsResultService = new MethodeToetsResultService()
