import { IUser } from '../interfaces/IUser'
import { Yeargroup } from './../enums/Yeargroup'
import { IUserSettings, IUserSettingsFilters } from '@/interfaces/IUserSettings'
import axios from 'axios'

export default class UserService {
    logout() {
        return axios.get(`auth/Logout`)
    }
    checkAuth() {
        return axios.get(`api/Frontend/UserInfo`)
    }
    getUser() {
        return axios.get(`api/Frontend/UserInfo`).then((data) => data.data as IUser)
    }
    getUserSettings() {
        return axios.get(`api/Frontend/UserInfo/settings`).then((data) => data.data as IUserSettings)
    }
    setUserSettings(settings: IUserSettings) {
        return axios.put(`api/Frontend/UserInfo/settings`, settings).then((data) => data.data as IUserSettings)
    }
    setOnboardingFinished() {
        return axios.get(`api/Frontend/UserInfo/SetOnboardingFinished`)
    }
    patchUserSettingsGroup(group: number | string) {
        return axios.patch(
            `api/Frontend/UserInfo/settings`,
            [
                {
                    op: 'replace',
                    path: '/groep',
                    value: group,
                },
            ],
            { headers: { 'Content-Type': 'application/json' } }
        )
    }
    patchUserSettingsYeargroup(yeargroup: Yeargroup) {
        return axios.patch(
            `api/Frontend/UserInfo/settings`,
            [
                {
                    op: 'replace',
                    path: '/jaargroep',
                    value: yeargroup,
                },
            ],
            { headers: { 'Content-Type': 'application/json' } }
        )
    }
    patchUserSettingsPeriod(filters: IUserSettingsFilters) {
        return axios.patch(
            `api/Frontend/UserInfo/settings`,
            [
                {
                    op: 'replace',
                    path: '/periodefilters/toetsPeriode',
                    value: filters.toetsPeriode,
                },
                {
                    op: 'replace',
                    path: '/periodefilters/oefeningPeriode',
                    value: filters.oefeningPeriode,
                },
                {
                    op: 'replace',
                    path: '/periodefilters/volgtoetsperiode',
                    value: filters.volgtoetsperiode,
                },
                {
                    op: 'replace',
                    path: '/periodefilters/sociaalPeriode',
                    value: filters.sociaalPeriode,
                },
                {
                    op: 'replace',
                    path: '/periodefilters/competentiePeriode',
                    value: filters.competentiePeriode,
                },
            ],
            { headers: { 'Content-Type': 'application/json' } }
        )
    }
    patchUserSettingsWidgets(settings: IUserSettings) {
        return axios.patch(
            `api/Frontend/UserInfo/settings`,
            [
                {
                    op: 'replace',
                    path: '/widgetrows/competentiesopened',
                    value: settings.widgetRows.competentiesOpened,
                },
                {
                    op: 'replace',
                    path: '/widgetrows/oefeningenverwerkenopened',
                    value: settings.widgetRows.oefeningEnVerwerkenOpened,
                },
                {
                    op: 'replace',
                    path: '/widgetrows/sociaalemtotionalopened',
                    value: settings.widgetRows.sociaalEmtotionalOpened,
                },
                {
                    op: 'replace',
                    path: '/widgetrows/toetsenopened',
                    value: settings.widgetRows.toetsenOpened,
                },
                {
                    op: 'replace',
                    path: '/widgetrows/volgToetsenopened',
                    value: settings.widgetRows.volgToetsenOpened,
                },
            ],
            { headers: { 'Content-Type': 'application/json' } }
        )
    }
}

export const userService = new UserService()
