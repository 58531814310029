<template>
    <footer
        :class="{ 'mt-14': !isErrorPage }"
        id="footer"
        v-if="showFooter"
    >
        <div class="container-xl h-100 p-5">
            <div class="row h-100">
                <div class="col-12 h-100 d-flex align-items-center justify-content-end">
                    <SupportButton />
                </div>
            </div>
        </div>
    </footer>
</template>
<script lang="ts" setup>
import { useStore } from '@/composables/useStore'
import { AppState } from '@/enums/AppState'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import SupportButton from './SupportButton.vue'

const { appStore, userStore } = useStore()
const $route = useRoute()

const shouldShowFeedback = () => {
    return appStore.getters.value.appState === AppState.LOGGEDIN && !$route.path.startsWith('/digibord') && !$route.path.startsWith('/onboarding')
}

const showFooter = computed(() => {
    return userStore.getters.value.getUser && userStore.getters.value.getUser.id && !$route.path.startsWith('/widget/moo')
})

const isErrorPage = computed(() => {
    return $route.name === 'Notfound'
})
</script>
