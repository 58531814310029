export type IAppcuesIdentifyFunction = (userId: string, params?: IAppcuesIdentifyParams) => void
export type IAppcuesTrackFunction = (eventName: string, params?: IAppcuesIdentifyParams) => void

export interface IAppcuesIdentifyParams {
    [prop: string]: string | number
}

export const appcuesPage = (): void => {
    window.Appcues.page()
}

export const appcuesIdentify: IAppcuesIdentifyFunction = (userId, params) => {
    window.Appcues.identify(userId, params)
}

export const appcuesTrack: IAppcuesTrackFunction = (eventName, params) => {
    window.Appcues.track(eventName, params)
}
