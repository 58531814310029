import { AxiosError } from 'axios'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

import { LesTemplateType } from '@/enums/LesTemplateType'
import { convertUTCtoLocal } from '@/helpers/convertTimezone'
import { uuidv4 } from '@/helpers/uuidv4'
import { IBasisroosterItem, IBasisroosterItemWithLesMomenten, IBasisroosterPost } from '@/interfaces/IBasisrooster'
import { IBasisroosterVersie } from '@/interfaces/IBasisroosterVersie'
import { IGroupsWithBasisrooster, IYearGroup } from '@/interfaces/IGroup'
import { ILesTemplateCollection } from '@/interfaces/ILesTemplate'

import { notify } from '@kyvg/vue3-notification'
import { LabelType } from './../../enums/LabelType'
import { onboardingPage } from './../../enums/OnboardingPage'
import { IDay, STAMGROEP_GROEPERING } from './../../interfaces/IDay'
import { IGroup } from './../../interfaces/IGroup'
import { ISelectedLesTemplate, ISelectedLesTemplatePutCopy } from './../../interfaces/ILesTemplate'
import { ILabel } from './../../interfaces/IPlanning'
import { onboardingService } from './../../services/onboardingService'

class OnboardingState {
    page: onboardingPage = onboardingPage.OTHER
    holidays: IDay[] = []
    studydays: IDay[] = []
    activities: IDay[] = []
    tests: IDay[] = []
    calendarAttributes: any[] = []
    regios: any[] = []
    lesTemplates: ILesTemplateCollection[] = []
    selectedLesTemplates: ISelectedLesTemplate[] = []
    basisrooster: IBasisroosterItem[] = []
    basisroosterBlocks: IBasisroosterItem[] = []
    availableYeargroups: IYearGroup[] = []
    selectedYeargroup: IYearGroup | null = null
    onboardingLoading = false
    vakActiviteitLabels: ILabel[] = []
    groupsWithRooster: IGroupsWithBasisrooster[] = []
    hasEditedBasisrooster = false
    basisroosterLoaded = false
    basisroosterVersies: IBasisroosterVersie[] = []
    versieTitle = ''
    useBasisroosterOnSave = false
    selectedBasisroosterVersieId: number | null = null
    hasRevertedBasisrooster = false
    basisroosterVersiesLoading = false
}

const getters: GetterTree<OnboardingState, any> = {
    page(store) {
        return () => store.page
    },
    basisroosterLoaded(store) {
        return () => store.basisroosterLoaded
    },
    hasEditedBasisrooster(store) {
        return () => store.hasEditedBasisrooster
    },
    holidays(store) {
        return () => store.holidays
    },
    studydays(store) {
        return () => store.studydays
    },
    activities(store) {
        return () => store.activities
    },
    tests(store) {
        return () => store.tests
    },
    calendarAttributes(store) {
        const getDifferenceInDays = (date1: Date, date2: Date) => {
            const diffInMs = Math.abs((date2 as any) - (date1 as any))
            return diffInMs / (1000 * 60 * 60 * 24)
        }

        const holidays = store.holidays.map((day) => {
            const diff = getDifferenceInDays(day.datumStart, day.datumEind)
            if (diff < 1) {
                return {
                    key: day.titel,
                    highlight: {
                        fillMode: 'light',
                        color: 'teal',
                    },
                    popover: {
                        label: day.titel ? day.titel : 'Geen omschrijving toegevoegd',
                        visibility: 'hover',
                    },
                    customData: {
                        id: day.id ? day.id : -1,
                        type: 'holiday',
                        naam: day.titel ? day.titel : 'Geen naam toegevoegd',
                        heleDag: day.heleDag,
                        confirmed: day.confirmed,
                        omschrijving: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        datumStart: day.datumStart ? day.datumStart : 'Geen startdatum toegevoegd',
                        datumEind: day.datumEind ? day.datumEind : 'Geen einddatum toegevoegd',
                    },
                    dates: day.datumStart,
                    order: store.page === onboardingPage.HOLIDAYS ? 100 : 0,
                }
            } else {
                return {
                    key: day.titel,
                    highlight: {
                        start: { fillMode: 'light', color: 'teal' },
                        base: { fillMode: 'light', color: 'teal' },
                        end: { fillMode: 'light', color: 'teal' },
                    },
                    popover: {
                        label: day.titel ? day.titel : 'Geen omschrijving toegevoegd',
                        visibility: 'hover',
                    },
                    customData: {
                        id: day.id ? day.id : -1,
                        type: 'holiday',
                        naam: day.titel ? day.titel : 'Geen naam toegevoegd',
                        heleDag: day.heleDag,
                        confirmed: day.confirmed,
                        omschrijving: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        datumStart: day.datumStart ? day.datumStart : 'Geen startdatum toegevoegd',
                        datumEind: day.datumEind ? day.datumEind : 'Geen einddatum toegevoegd',
                    },
                    dates: { start: day.datumStart, end: day.datumEind },
                    order: store.page === onboardingPage.HOLIDAYS ? 100 : 0,
                }
            }
        })

        const studyDays = store.studydays.map((day) => {
            const diff = getDifferenceInDays(day.datumStart, day.datumEind)
            if (!day.confirmed) return
            if (diff < 1) {
                return {
                    key: day.titel,
                    highlight: {
                        fillMode: 'light',
                        color: 'pink',
                    },
                    popover: {
                        label: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        visibility: 'hover',
                    },
                    customData: {
                        id: day.id ? day.id : -1,
                        type: 'studyday',
                        naam: day.titel ? day.titel : 'Geen naam toegevoegd',
                        heleDag: day.heleDag,
                        lesVrij: day.lesVrij,
                        confirmed: day.confirmed,
                        omschrijving: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        datumStart: day.datumStart ? day.datumStart : 'Geen startdatum toegevoegd',
                        datumEind: day.datumEind ? day.datumEind : 'Geen einddatum toegevoegd',
                        groepering: day.groepering,
                        aantalGekoppeldeGroepen: day.aantalGekoppeldeGroepen,
                        aangemaaktDoorId: day.aangemaaktDoorId,
                        aanmaakType: day.aanmaakType,
                    },
                    dates: day.datumStart,
                    order: store.page === onboardingPage.STUDYDAYS ? 100 : 0,
                }
            } else {
                return {
                    key: day.titel,
                    highlight: {
                        start: { fillMode: 'light', color: 'pink' },
                        base: { fillMode: 'light', color: 'pink' },
                        end: { fillMode: 'light', color: 'pink' },
                    },
                    popover: {
                        label: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        visibility: 'hover',
                    },
                    customData: {
                        id: day.id ? day.id : -1,
                        type: 'studyday',
                        naam: day.titel ? day.titel : 'Geen naam toegevoegd',
                        heleDag: day.heleDag,
                        lesVrij: day.lesVrij,
                        confirmed: day.confirmed,
                        omschrijving: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        datumStart: day.datumStart ? day.datumStart : 'Geen startdatum toegevoegd',
                        datumEind: day.datumEind ? day.datumEind : 'Geen einddatum toegevoegd',
                        groepering: day.groepering,
                        aantalGekoppeldeGroepen: day.aantalGekoppeldeGroepen,
                        aangemaaktDoorId: day.aangemaaktDoorId,
                        aanmaakType: day.aanmaakType,
                    },
                    dates: { start: day.datumStart, end: day.datumEind },
                    order: store.page === onboardingPage.STUDYDAYS ? 100 : 0,
                }
            }
        })

        const tests = store.tests.map((day) => {
            const diff = getDifferenceInDays(day.datumStart, day.datumEind)
            if (!day.confirmed) return
            if (diff < 1) {
                return {
                    key: day.titel,
                    highlight: {
                        fillMode: 'light',
                        color: 'blue',
                    },
                    popover: {
                        label: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        visibility: 'hover',
                    },
                    customData: {
                        id: day.id ? day.id : -1,
                        type: 'test',
                        naam: day.titel ? day.titel : 'Geen naam toegevoegd',
                        heleDag: day.heleDag,
                        lesVrij: day.lesVrij,
                        confirmed: day.confirmed,
                        omschrijving: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        datumStart: day.datumStart ? day.datumStart : 'Geen startdatum toegevoegd',
                        datumEind: day.datumEind ? day.datumEind : 'Geen einddatum toegevoegd',
                        groepering: day.groepering,
                        aantalGekoppeldeGroepen: day.aantalGekoppeldeGroepen,
                        aangemaaktDoorId: day.aangemaaktDoorId,
                        aanmaakType: day.aanmaakType,
                    },
                    dates: day.datumStart,
                    order: store.page === onboardingPage.TESTS ? 100 : 0,
                }
            } else {
                return {
                    key: day.titel,
                    highlight: {
                        start: { fillMode: 'light', color: 'blue' },
                        base: { fillMode: 'light', color: 'blue' },
                        end: { fillMode: 'light', color: 'blue' },
                    },
                    popover: {
                        label: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        visibility: 'hover',
                    },
                    customData: {
                        id: day.id ? day.id : -1,
                        type: 'test',
                        naam: day.titel ? day.titel : 'Geen naam toegevoegd',
                        heleDag: day.heleDag,
                        lesVrij: day.lesVrij,
                        confirmed: day.confirmed,
                        omschrijving: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        datumStart: day.datumStart ? day.datumStart : 'Geen startdatum toegevoegd',
                        datumEind: day.datumEind ? day.datumEind : 'Geen einddatum toegevoegd',
                        groepering: day.groepering,
                        aantalGekoppeldeGroepen: day.aantalGekoppeldeGroepen,
                        aangemaaktDoorId: day.aangemaaktDoorId,
                        aanmaakType: day.aanmaakType,
                    },
                    dates: { start: day.datumStart, end: day.datumEind },
                    order: store.page === onboardingPage.TESTS ? 100 : 0,
                }
            }
        })

        const activities = store.activities.map((day) => {
            const diff = getDifferenceInDays(day.datumStart, day.datumEind)
            if (!day.confirmed) return
            if (diff < 1) {
                return {
                    key: day.titel,
                    highlight: {
                        fillMode: 'light',
                        color: 'purple',
                    },
                    popover: {
                        label: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        visibility: 'hover',
                    },
                    customData: {
                        id: day.id ? day.id : -1,
                        type: 'activity',
                        naam: day.titel ? day.titel : 'Geen naam toegevoegd',
                        heleDag: day.heleDag,
                        lesVrij: day.lesVrij,
                        confirmed: day.confirmed,
                        omschrijving: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        datumStart: day.datumStart ? day.datumStart : 'Geen startdatum toegevoegd',
                        datumEind: day.datumEind ? day.datumEind : 'Geen einddatum toegevoegd',
                        groepering: day.groepering,
                        aantalGekoppeldeGroepen: day.aantalGekoppeldeGroepen,
                        aangemaaktDoorId: day.aangemaaktDoorId,
                        aanmaakType: day.aanmaakType,
                    },
                    dates: day.datumStart,
                    order: store.page === onboardingPage.ACTIVITIES ? 100 : 0,
                }
            } else {
                return {
                    key: day.titel,
                    highlight: {
                        start: { fillMode: 'light', color: 'purple' },
                        base: { fillMode: 'light', color: 'purple' },
                        end: { fillMode: 'light', color: 'purple' },
                    },
                    popover: {
                        label: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        visibility: 'hover',
                    },
                    customData: {
                        id: day.id ? day.id : -1,
                        type: 'activity',
                        naam: day.titel ? day.titel : 'Geen naam toegevoegd',
                        heleDag: day.heleDag,
                        lesVrij: day.lesVrij,
                        confirmed: day.confirmed,
                        omschrijving: day.omschrijving ? day.omschrijving : 'Geen omschrijving toegevoegd',
                        datumStart: day.datumStart ? day.datumStart : 'Geen startdatum toegevoegd',
                        datumEind: day.datumEind ? day.datumEind : 'Geen einddatum toegevoegd',
                        groepering: day.groepering,
                        aantalGekoppeldeGroepen: day.aantalGekoppeldeGroepen,
                        aangemaaktDoorId: day.aangemaaktDoorId,
                        aanmaakType: day.aanmaakType,
                    },
                    dates: { start: day.datumStart, end: day.datumEind },
                    order: store.page === onboardingPage.ACTIVITIES ? 100 : 0,
                }
            }
        })

        return () => [...holidays, ...studyDays, ...activities, ...tests]
    },
    regios(store) {
        return () => store.regios
    },
    lesTemplates(store) {
        const templates = store.lesTemplates
            .map((template) => {
                return {
                    ...template,
                    vakken: template.vakken
                        .map((vak) => {
                            return {
                                ...vak,
                                selected: store.selectedLesTemplates.find((selectedtemplate) => vak.id === selectedtemplate.vakgebiedId && template.jaargroepLabelId === selectedtemplate.jaargroepLabelId) ? true : false,
                                templates: vak.templates.sort((a, b) => {
                                    if (a.naam < b.naam) {
                                        return -1
                                    }
                                    if (a.naam > b.naam) {
                                        return 1
                                    }
                                    return 0
                                }),
                            }
                        })
                        .sort((a, b) => {
                            if (a.naam < b.naam) {
                                return -1
                            }
                            if (a.naam > b.naam) {
                                return 1
                            }
                            return 0
                        }),
                }
            })
            .sort((a, b) => {
                if (a.jaargroep < b.jaargroep) {
                    return -1
                }
                if (a.jaargroep > b.jaargroep) {
                    return 1
                }
                return 0
            })
        return () => templates
    },
    selectedLesTemplates(store) {
        return () =>
            store.selectedLesTemplates.sort((a, b) => {
                if (a.vakgebiedNaam < b.vakgebiedNaam) {
                    return -1
                }
                if (a.vakgebiedNaam > b.vakgebiedNaam) {
                    return 1
                }
                return 0
            })
    },
    basisrooster(store) {
        return () => store.basisrooster
    },
    basisroosterVisible(store) {
        return () => store.basisrooster.filter((item) => item.jaargroepId === store.selectedYeargroup?.jaargroepId || item.heleStamgroep)
    },
    basisroosterBlocks(store) {
        const blocks = store.basisroosterBlocks
            .map((block) => {
                let inTePlannenLessen = 0
                const lessenInRooster: IBasisroosterItem[] = []
                store.basisrooster.forEach((roosteritem) => {
                    const dontCountItem = lessenInRooster.some((item) => item.dag === roosteritem.dag && roosteritem.isGekoppeld)
                    if (roosteritem.blockId === block.blockId && !dontCountItem) {
                        lessenInRooster.push(roosteritem)
                    }
                })

                if (block.standaardAantalLesMomenten && block.standaardAantalLesMomenten <= lessenInRooster.length) {
                    inTePlannenLessen = 0
                } else if (block.standaardAantalLesMomenten && block.standaardAantalLesMomenten > lessenInRooster.length) {
                    inTePlannenLessen = block.standaardAantalLesMomenten - lessenInRooster.length
                } else {
                    inTePlannenLessen = 0
                }

                return {
                    ...block,
                    inTePlannenLessen: inTePlannenLessen,
                    isIngepland: lessenInRooster.length > 0,
                }
            })
            .filter((block) => block.jaargroepId === store.selectedYeargroup?.jaargroepId || block.heleStamgroep)
            .sort((a, b) => {
                if (a.naam < b.naam) {
                    return -1
                }
                if (a.naam > b.naam) {
                    return 1
                }
                return 0
            })
        return () => blocks
    },
    availableYeargroups(store) {
        return () =>
            store.availableYeargroups.sort((a, b) => {
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            })
    },
    selectedYeargroup(store) {
        return () => store.selectedYeargroup
    },
    onboardingLoading(store) {
        return () => store.onboardingLoading
    },
    vakActiviteitLabels(store) {
        return () =>
            store.vakActiviteitLabels.sort((a, b) => {
                if (a.naam < b.naam) {
                    return -1
                }
                if (a.naam > b.naam) {
                    return 1
                }
                return 0
            })
    },
    flattenedTemplates(store) {
        return () => store.lesTemplates.flatMap((collection) => collection.vakken).flatMap((vakken) => vakken.templates)
    },
    schooljarenWithRooster(store) {
        return () => store.groupsWithRooster
    },
    basisroosterVersies(store) {
        return () => store.basisroosterVersies
    },
    basisroosterVersieTitle(store) {
        return () => store.versieTitle
    },
    useBasisroosterOnSave(store) {
        return () => store.useBasisroosterOnSave
    },
    basisroosterVersieId(store) {
        return () => store.selectedBasisroosterVersieId
    },
    hasRevertedBasisrooster(store) {
        return () => store.hasRevertedBasisrooster
    },
    basisroosterVersiesLoading(store) {
        return () => store.basisroosterVersiesLoading
    },
}

const mutations: MutationTree<OnboardingState> = {
    setHasEditedBasisrooster(state, payload: boolean) {
        state.hasEditedBasisrooster = payload
    },
    setPage(state, payload: onboardingPage) {
        state.page = payload
    },
    setOnboardingLoading(state, payload: boolean) {
        state.onboardingLoading = payload
    },
    changeHoliday(state, payload: IDay) {
        payload.confirmed = true
        state.holidays = state.holidays.map((holiday) => (holiday.id === payload.id ? payload : holiday))
    },
    addHoliday(state, payload: IDay) {
        state.holidays.push(payload)
    },
    deleteHoliday(state, id: string) {
        state.holidays.splice(
            state.holidays.findIndex((day) => day.id === id),
            1
        )
    },
    addStudyday(state, payload: IDay) {
        state.studydays.push(payload)
    },
    changeStudyday(state, payload: IDay) {
        state.studydays = state.studydays.map((studyDay) => (studyDay.id === payload.id ? payload : studyDay))
    },
    deleteStudyday(state, id: string) {
        state.studydays.splice(
            state.studydays.findIndex((day) => day.id === id),
            1
        )
    },
    addTest(state, payload: IDay) {
        state.tests.push(payload)
    },
    changeTest(state, payload: IDay) {
        state.tests = state.tests.map((test) => (test.id === payload.id ? payload : test))
    },
    deleteTest(state, id: string) {
        state.tests.splice(
            state.tests.findIndex((day) => day.id === id),
            1
        )
    },
    addActivity(state, payload: IDay) {
        state.activities.push(payload)
    },
    changeActivity(state, payload: IDay) {
        state.activities = state.activities.map((activity) => (activity.id === payload.id ? payload : activity))
    },
    deleteActivity(state, id: string) {
        state.activities.splice(
            state.activities.findIndex((day) => day.id === id),
            1
        )
    },
    setRegios(state, payload: any[]) {
        state.regios = payload
    },
    setHolidays(state, payload: IDay[]) {
        state.holidays = payload
    },
    setStudydays(state, payload: IDay[]) {
        state.studydays = payload
    },
    setTests(state, payload: IDay[]) {
        state.tests = payload
    },
    setActivities(state, payload: IDay[]) {
        state.activities = payload
    },
    setTemplates(state, payload: ILesTemplateCollection[]) {
        state.lesTemplates = payload
    },
    setSelectedTemplates(state, payload: ISelectedLesTemplate[]) {
        state.selectedLesTemplates = payload
    },
    updateSelectedTemplates(state, payload: ISelectedLesTemplate) {
        if (state.selectedLesTemplates.findIndex((template) => template.vakgebiedId === payload.vakgebiedId && template.jaargroepLabelId === payload.jaargroepLabelId) > -1) {
            state.selectedLesTemplates = state.selectedLesTemplates.map((template) => {
                if (template.vakgebiedId === payload.vakgebiedId && template.jaargroepLabelId === payload.jaargroepLabelId) {
                    return payload
                } else {
                    return template
                }
            })
            state.basisrooster = state.basisrooster.filter((item) => item.vakgebiedId !== payload.vakgebiedId || (item.vakgebiedId === payload.vakgebiedId && item.jaargroepId !== payload.jaargroepLabelId) || item.zelfAangemaakt)
        } else {
            state.selectedLesTemplates = [...state.selectedLesTemplates, payload]
        }
    },
    removeSelectedTemplate(state, payload: ISelectedLesTemplate) {
        const index = state.selectedLesTemplates.findIndex((template) => template.vakgebiedId === payload.vakgebiedId && template.jaargroepLabelId === payload.jaargroepLabelId)
        index >= 0 ? state.selectedLesTemplates.splice(index, 1) : null
    },
    addBasisroosterItem(state, payload: IBasisroosterItem) {
        state.basisrooster = [...state.basisrooster, payload]
    },
    updateBasisroosterItem(state, payload: IBasisroosterItem) {
        if (state.basisrooster.findIndex((roosteritem) => (roosteritem.blockId === payload.blockId && roosteritem.defId === payload.defId) || (roosteritem.blockId === payload.blockId && !roosteritem.defId)) > -1) {
            state.basisrooster = state.basisrooster.map((roosteritem) => (roosteritem.blockId === payload.blockId && roosteritem.defId === payload.defId ? payload : roosteritem))
        }
    },
    updateBasisroosterItemCalendar(state, payload) {
        if (payload.changeAll && state.basisrooster.findIndex((roosteritem) => roosteritem.blockId === payload.roosterItem.blockId) > -1) {
            state.basisrooster = state.basisrooster.map((roosteritem) => (roosteritem.blockId === payload.roosterItem.blockId ? { ...roosteritem, ...payload.roosterItem, defId: roosteritem.defId, dag: roosteritem.dag, uur: roosteritem.uur, minuut: roosteritem.minuut } : roosteritem))
        } else if (!payload.changeAll && state.basisrooster.findIndex((roosteritem) => roosteritem.blockId === payload.roosterItem.blockId && roosteritem.defId === payload.roosterItem.defId) > -1) {
            state.basisrooster = state.basisrooster.map((roosteritem) => (roosteritem.blockId === payload.roosterItem.blockId && roosteritem.defId === payload.roosterItem.defId ? { ...roosteritem, ...payload.roosterItem } : roosteritem))
        }
    },
    removeBasisroosterItem(state, payload: any) {
        if (payload.changeAll) {
            state.basisrooster = state.basisrooster.filter((item: IBasisroosterItem) => item.blockId !== payload.id)
        } else {
            const index = state.basisrooster.findIndex((item: IBasisroosterItem) => item.blockId === payload.id && item.defId === payload.defId)
            index >= 0 ? state.basisrooster.splice(index, 1) : null
        }
    },
    updateBasisroosterItemTemplateChange(state, payload: ISelectedLesTemplate) {
        if (state.basisrooster.findIndex((roosteritem) => roosteritem.vakgebiedId === payload.vakgebiedId && roosteritem.jaargroepId === payload.jaargroepId) > -1) {
            state.basisrooster = state.basisrooster.map((roosteritem) => (roosteritem.vakgebiedId === payload.vakgebiedId && roosteritem.jaargroepId === payload.jaargroepId ? { ...roosteritem, schoolLesTemplateId: payload.id } : roosteritem))
        }
    },
    updateBasisroosterItemDefId(state, ids: any) {
        const index = state.basisrooster.findIndex((roosteritem) => roosteritem.defId === ids.oldId)
        if (index > -1) {
            state.basisrooster[index].defId = ids.newId
        }
    },
    removeBasisroosterItemTemplateChange(state, payload: IBasisroosterItem) {
        state.basisrooster = state.basisrooster.filter((item: IBasisroosterItem) => item.blockId !== payload.blockId)
    },
    setBasisrooster(state, payload: IBasisroosterItem[]) {
        state.basisrooster = payload
    },
    setBasisroosterLoaded(state, payload: boolean) {
        state.basisroosterLoaded = payload
    },
    setRoosterItems(state, payload: IBasisroosterItem[]) {
        //remember created blocks of selected group that havent been used in basisrooster yet
        const unusedCustomblocks = state.basisroosterBlocks.filter((block) => !payload.find((b) => b.blockId === block.blockId) && block.zelfAangemaakt)
        state.basisroosterBlocks = [...payload, ...unusedCustomblocks]
    },
    cleanCustomBlocks(state) {
        state.basisroosterBlocks = []
        state.basisrooster = []
    },
    addRoosterItem(state, payload: IBasisroosterItem) {
        state.basisroosterBlocks = [...state.basisroosterBlocks, payload]
    },
    updateRoosterItem(state, payload: IBasisroosterItem) {
        state.basisroosterBlocks = state.basisroosterBlocks.map((roosteritem) => (roosteritem.blockId === payload.blockId ? { ...roosteritem, ...payload, standaardAantalLesMomenten: roosteritem.standaardAantalLesMomenten } : roosteritem))
        state.basisrooster = state.basisrooster.map((item) => (item.blockId === payload.blockId ? { ...item, omschrijving: !item.omschrijving || item.omschrijving === payload.oldOmschrijving ? payload.omschrijving : item.omschrijving } : item))
    },
    removeRoosterItem(state, payload: IBasisroosterItem) {
        state.basisroosterBlocks = state.basisroosterBlocks.filter((block) => block.blockId !== payload.blockId)
    },
    addBasisroosterBlocks(state, payload: IBasisroosterItem[]) {
        state.basisroosterBlocks = [...state.basisroosterBlocks, ...payload]
    },
    setAvailableYeargroups(state, payload: IYearGroup[]) {
        state.availableYeargroups = payload
        state.selectedYeargroup = payload[0]
    },
    setSelectedYeargroup(state, payload: IYearGroup) {
        state.selectedYeargroup = payload
    },
    setVakActiviteitLabels(state, payload: ILabel[]) {
        state.vakActiviteitLabels = payload
    },
    setSchooljarenWithRoosters(state, payload: IGroupsWithBasisrooster[]) {
        state.groupsWithRooster = payload
    },
    setBasisroosterVersies(state, payload: IBasisroosterVersie[]) {
        state.basisroosterVersies = payload
    },
    setBasisroosterVersiesLoading(state, payload: boolean) {
        state.basisroosterVersiesLoading = payload
    },
    setBasisroosterVersieNaam(state, payload: string) {
        state.versieTitle = payload
    },
    setUseBasisroosterOnSave(state, payload: boolean) {
        state.useBasisroosterOnSave = payload
    },
    setBasisroosterVersieId(state, payload: number) {
        state.selectedBasisroosterVersieId = payload
    },
    setHasRevertedBasisrooster(state, payload: boolean) {
        state.hasRevertedBasisrooster = payload
    },
}

const actions: ActionTree<OnboardingState, any> = {
    getRegios({ commit }) {
        return new Promise((resolve, reject) => {
            onboardingService
                .getRegios()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setRegios', data.data)
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    getVoorwaarden({ commit }, params) {
        return new Promise((resolve, reject) => {
            onboardingService
                .getVoorwaarden(params)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    basispoortToestemming({ commit }) {
        return new Promise((resolve, reject) => {
            onboardingService
                .getBasispoortToestemming()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    getHolidays({ commit }) {
        return new Promise((resolve, reject) => {
            onboardingService
                .getVakanties()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        const days: IDay[] = data.data.map((day: IDay) => {
                            return {
                                ...day,
                                confirmed: true,
                            }
                        })

                        const vakantieTemplateDagen: string[] = ['Zomervakantie', 'Kerstvakantie', 'Meivakantie', 'Herfstvakantie', 'Voorjaarsvakantie']
                        const unusedTemplateDays = vakantieTemplateDagen.filter((day) => !days.find((d) => d.titel && d.titel.toLowerCase().includes(day.toLowerCase())))

                        for (let index = 0; index < unusedTemplateDays.length; index++) {
                            const day: IDay = {
                                id: uuidv4(),
                                titel: unusedTemplateDays[index] || `Vakantie ${index + 1}`,
                                confirmed: false,
                                lesVrij: false,
                                datumStart: new Date(),
                                datumEind: new Date(),
                                tijdStart: new Date().toLocaleTimeString('nl-NL', { hour12: false, hour: 'numeric', minute: 'numeric' }),
                                tijdEind: new Date().toLocaleTimeString('nl-NL', { hour12: false, hour: 'numeric', minute: 'numeric' }),
                                heleDag: true,
                                groepering: STAMGROEP_GROEPERING.STAMGROEP,
                                aantalGekoppeldeGroepen: -1,
                                aangemaaktDoor: undefined,
                                aanmaakType: STAMGROEP_GROEPERING.STAMGROEP,
                            }
                            days.push(day)
                        }
                        commit('setHolidays', days)
                        resolve(days)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    updateHoliday({ commit }, day: IDay) {
        return new Promise((resolve, reject) => {
            onboardingService
                .updateVakantie(day)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('changeHoliday', day)
                        resolve(data.data)
                    }
                })
                .catch((e: AxiosError<any>) => {
                    rejectWithCustomMessage(e, reject)
                })
        })
    },
    createHoliday({ commit }, day: IDay) {
        delete day.id
        return new Promise((resolve, reject) => {
            onboardingService
                .createVakantie(day)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('addHoliday', day)
                        resolve(data.data)
                    }
                })
                .catch((e: AxiosError<any>) => {
                    rejectWithCustomMessage(e, reject)
                })
        })
    },
    deleteHoliday({ commit }, dayid) {
        return new Promise((resolve, reject) => {
            onboardingService
                .deleteVakantie(dayid)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('deleteHoliday', dayid)
                        resolve(data.data)
                    }
                })
                .catch((e: AxiosError<any>) => {
                    rejectWithCustomMessage(e, reject)
                })
        })
    },
    getStudydays({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            onboardingService
                .getStudiedagen()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        const days: IDay[] = (data.data as IDay[]).map((day) => {
                            return {
                                ...day,
                                confirmed: true,
                                datumStart: convertUTCtoLocal(new Date(day.datumStart)),
                                datumEind: convertUTCtoLocal(new Date(day.datumEind)),
                                tijdStart: convertUTCtoLocal(new Date(day.datumStart)).toLocaleTimeString('nl-NL', { hour12: false, hour: 'numeric', minute: 'numeric' }),
                                tijdEind: convertUTCtoLocal(new Date(day.datumEind)).toLocaleTimeString('nl-NL', { hour12: false, hour: 'numeric', minute: 'numeric' }),
                            }
                        })
                        if (days.length < 7) {
                            const daysToAdd = 7 - days.length
                            const amountOfExistingDays = days.length
                            for (let index = 0; index < daysToAdd; index++) {
                                const day: IDay = {
                                    id: uuidv4(),
                                    titel: `studiedag ${amountOfExistingDays + index + 1}`,
                                    confirmed: false,
                                    lesVrij: false,
                                    datumStart: new Date(),
                                    datumEind: new Date(),
                                    tijdStart: new Date().toLocaleTimeString('nl-NL', { hour12: false, hour: 'numeric', minute: 'numeric' }),
                                    tijdEind: new Date().toLocaleTimeString('nl-NL', { hour12: false, hour: 'numeric', minute: 'numeric' }),
                                    heleDag: true,
                                    groepering: STAMGROEP_GROEPERING.STAMGROEP,
                                    aantalGekoppeldeGroepen: -1,
                                    aangemaaktDoor: undefined,
                                    aanmaakType: STAMGROEP_GROEPERING.STAMGROEP,
                                }
                                days.push(day)
                            }
                        }
                        commit('setStudydays', days)
                        resolve(days)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    createStudyday({ commit }, day: IDay) {
        return new Promise((resolve, reject) => {
            delete day.id
            onboardingService
                .createStudiedag(day)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('addStudyday', day)
                        resolve(data.data)
                    }
                })
                .catch((e: AxiosError<any>) => {
                    rejectWithCustomMessage(e, reject)
                })
        })
    },
    updateStudyday({ commit }, day: IDay) {
        return new Promise((resolve, reject) => {
            onboardingService
                .updateStudiedag(day)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('changeStudyday', day)
                        resolve(data.data)
                    }
                })
                .catch((e: AxiosError<any>) => {
                    rejectWithCustomMessage(e, reject)
                })
        })
    },
    deleteStudyday({ commit }, dayid) {
        return new Promise((resolve, reject) => {
            onboardingService
                .deleteStudiedag(dayid)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('deleteStudyday', dayid)
                        resolve(data.data)
                    }
                })
                .catch((e: AxiosError<any>) => {
                    rejectWithCustomMessage(e, reject)
                })
        })
    },
    getActivities({ commit }) {
        return new Promise((resolve, reject) => {
            const activiteiten = onboardingService.getActiviteiten()
            const standaardActiviteiten = onboardingService.getActiviteitenStandaard()

            Promise.all([activiteiten, standaardActiviteiten])
                .then(([activiteiten, standaardActiviteiten]) => {
                    if (activiteiten.status == 401) {
                        reject('unauthorized')
                    } else {
                        const days: IDay[] = (activiteiten.data as IDay[]).map((day) => {
                            return {
                                ...day,
                                confirmed: true,
                                datumStart: convertUTCtoLocal(new Date(day.datumStart)),
                                datumEind: convertUTCtoLocal(new Date(day.datumEind)),
                                tijdStart: convertUTCtoLocal(new Date(day.datumStart)).toLocaleTimeString('nl-NL', { hour12: false, hour: 'numeric', minute: 'numeric' }),
                                tijdEind: convertUTCtoLocal(new Date(day.datumEind)).toLocaleTimeString('nl-NL', { hour12: false, hour: 'numeric', minute: 'numeric' }),
                            }
                        })

                        const unusedTemplateDays = (standaardActiviteiten.data as string[]).filter((day) => !days.find((d) => d.titel.toLowerCase() === day.toLowerCase()))

                        for (let index = 0; index < unusedTemplateDays.length; index++) {
                            const day: IDay = {
                                id: uuidv4(),
                                titel: unusedTemplateDays[index] || `Themadag ${index + 1}`,
                                confirmed: false,
                                lesVrij: false,
                                datumStart: new Date(),
                                datumEind: new Date(),
                                tijdStart: new Date().toLocaleTimeString('nl-NL', { hour12: false, hour: 'numeric', minute: 'numeric' }),
                                tijdEind: new Date().toLocaleTimeString('nl-NL', { hour12: false, hour: 'numeric', minute: 'numeric' }),
                                heleDag: true,
                                groepering: STAMGROEP_GROEPERING.STAMGROEP,
                                aantalGekoppeldeGroepen: -1,
                                aangemaaktDoor: undefined,
                                aanmaakType: STAMGROEP_GROEPERING.STAMGROEP,
                            }
                            days.push(day)
                        }
                        commit('setActivities', days)
                        resolve(days)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    createActivity({ commit }, day: IDay) {
        return new Promise((resolve, reject) => {
            delete day.id
            onboardingService
                .createActiviteit(day)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('addActivity', day)
                        resolve(data.data)
                    }
                })
                .catch((e: AxiosError<any>) => {
                    rejectWithCustomMessage(e, reject)
                })
        })
    },
    updateActivity({ commit }, day: IDay) {
        return new Promise((resolve, reject) => {
            onboardingService
                .updateActiviteit(day)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('changeActivity', day)
                        resolve(data.data)
                    }
                })
                .catch((e: AxiosError<any>) => {
                    rejectWithCustomMessage(e, reject)
                })
        })
    },
    deleteActivity({ commit }, dayid) {
        return new Promise((resolve, reject) => {
            onboardingService
                .deleteActiviteit(dayid)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('deleteActivity', dayid)
                        resolve(data.data)
                    }
                })
                .catch((e: AxiosError<any>) => {
                    rejectWithCustomMessage(e, reject)
                })
        })
    },
    getTests({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            onboardingService
                .getToetsen()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        const days = (data.data as IDay[]).map((day) => {
                            return {
                                ...day,
                                confirmed: true,
                                datumStart: convertUTCtoLocal(new Date(day.datumStart)),
                                datumEind: convertUTCtoLocal(new Date(day.datumEind)),
                                tijdStart: convertUTCtoLocal(new Date(day.datumStart)).toLocaleTimeString('nl-NL', { hour12: false, hour: 'numeric', minute: 'numeric' }),
                                tijdEind: convertUTCtoLocal(new Date(day.datumEind)).toLocaleTimeString('nl-NL', { hour12: false, hour: 'numeric', minute: 'numeric' }),
                            }
                        })
                        commit('setTests', days)
                        resolve(days)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    createTest({ commit }, day: IDay) {
        return new Promise((resolve, reject) => {
            onboardingService
                .createToets(day)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('addTest', day)
                        resolve(data.data)
                    }
                })
                .catch((e: AxiosError<any>) => {
                    rejectWithCustomMessage(e, reject)
                })
        })
    },
    updateTest({ commit }, day: IDay) {
        return new Promise((resolve, reject) => {
            onboardingService
                .updateToets(day)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('changeTest', day)
                        resolve(data.data)
                    }
                })
                .catch((e: AxiosError<any>) => {
                    rejectWithCustomMessage(e, reject)
                })
        })
    },
    deleteTest({ commit }, dayid) {
        return new Promise((resolve, reject) => {
            onboardingService
                .deleteToets(dayid)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('deleteTest', dayid)
                        resolve(data.data)
                    }
                })
                .catch((e: AxiosError<any>) => {
                    rejectWithCustomMessage(e, reject)
                })
        })
    },
    getTemplates({ commit, rootState, state }, force = false) {
        return new Promise((resolve, reject) => {
            if (!force && state.lesTemplates.some((t) => state.availableYeargroups.some((a) => a.jaargroepId === t.jaargroepLabelId))) {
                resolve(state.lesTemplates)
            } else {
                onboardingService
                    .getTemplates(rootState.groups.selectedGroup.id)
                    .then((data) => {
                        if (data.status == 401) {
                            reject('unauthorized')
                        } else {
                            const templatecollections: ILesTemplateCollection[] = data.data

                            templatecollections.forEach((templatecollection: ILesTemplateCollection) => {
                                templatecollection.vakken.forEach((vak) => {
                                    vak.templates = vak.templates.map((temp) => {
                                        return {
                                            ...temp,
                                            isFixedPlaceholder: temp.blokkenLineair === LesTemplateType.Fixed,
                                            _id: temp.id,
                                            id: uuidv4(),
                                        }
                                    })
                                    vak.templates.push({
                                        id: uuidv4(),
                                        _id: null,
                                        naam: 'Eigen invulling',
                                        omschrijving: 'Eigen invulling',
                                        blokkenLineair: LesTemplateType.Lineair,
                                        standaardAantalLesMomenten: 0,
                                        methodeId: null,
                                        isFixedPlaceholder: false,
                                    })
                                })
                            })

                            commit('setTemplates', templatecollections)

                            const yeargroups: IYearGroup[] = (data.data as ILesTemplateCollection[])
                                .map((item): IYearGroup => {
                                    return {
                                        label: item.jaargroep,
                                        jaargroepId: item.jaargroepLabelId,
                                    }
                                })
                                .sort((a, b) => {
                                    if (a.label < b.label) {
                                        return -1
                                    }
                                    if (a.label > b.label) {
                                        return 1
                                    }
                                    return 0
                                })

                            commit('setAvailableYeargroups', yeargroups)
                            resolve(data.data)
                        }
                    })
                    .catch((e) => {
                        reject(e)
                    })
            }
        })
    },
    getSelectedTemplates({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            onboardingService
                .getSelectedTemplates(rootState.groups.selectedGroup.id)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        const templates: ISelectedLesTemplate[] = data.data.map((template: ISelectedLesTemplate) => {
                            return {
                                ...template,
                                jaargroepLabelId: template.jaargroepId,
                                standaardAantalLesMomenten: !template.standaardAantalLesMomenten ? 0 : template.standaardAantalLesMomenten,
                                standaardLesDuur: !template.standaardLesDuur ? 60 : template.standaardLesDuur,
                            }
                        })

                        commit('setSelectedTemplates', templates)
                        resolve(templates)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    putSelectedTemplates({ state, rootState }) {
        return new Promise((resolve, reject) => {
            onboardingService
                .putTemplates(rootState.groups.selectedGroup.id, state.selectedLesTemplates)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    putCopySelectedTemplates({ state, rootState, commit }, data) {
        return new Promise((resolve, reject) => {
            const templates = state.selectedLesTemplates
            const payload: ISelectedLesTemplatePutCopy = {
                ...data,
                stamgroepId: rootState.groups.selectedGroup.id,
                methodsToLink: templates,
            }
            onboardingService
                .copyBasisrooster(payload)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    getBasisrooster({ rootState }) {
        return new Promise((resolve, reject) => {
            onboardingService
                .getBasisrooster(rootState.groups.selectedGroup.id)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e: AxiosError) => {
                    reject(e)
                })
        })
    },
    postBasisroosterStamgroep({ state, rootState, commit }, { versieTitel = `rooster-${new Date().toLocaleDateString('nl-NL', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })}`, useBasisroosterOnSave = false, versionId }) {
        return new Promise((resolve, reject) => {
            const basisrooster = state.basisrooster.filter((rooster) => rooster.heleStamgroep)
            let allRecords: IBasisroosterItem[] = basisrooster

            const createActiviteiten = (items: IBasisroosterItem[]): IBasisroosterItemWithLesMomenten[] => {
                const activiteiten: IBasisroosterItemWithLesMomenten[] = []
                items.forEach((item) => {
                    const activiteitExists = activiteiten.find((activiteit) => activiteit.blockId === item.blockId && activiteit.dag === item.dag && item.isGekoppeld === true && activiteit.isGekoppeld === item.isGekoppeld)
                    if (!activiteitExists) {
                        const activiteit: IBasisroosterItemWithLesMomenten = {
                            blockId: item.blockId,
                            activiteitType: item.activiteitType,
                            dag: item.dag,
                            naam: item.naam,
                            omschrijving: item.omschrijving,
                            vakgebiedId: item.vakgebiedId,
                            activiteitLabelId: item.activiteitLabelId,
                            schoolLesTemplateId: item.schoolLesTemplateId,
                            lesplanningTemplateId: item.lesplanningTemplateId,
                            isGekoppeld: item.isGekoppeld,
                            lesmomenten: [
                                {
                                    uur: item.uur,
                                    minuut: item.minuut,
                                    lesDuur: item.lesDuur,
                                },
                            ],
                            eigenInvullingTekst: item.eigenInvullingTekst,
                            methodeLesId: item.methodeLesId,
                            isFixedPlaceholder: item.isFixedPlaceholder,
                        }
                        activiteiten.push(activiteit)
                    } else {
                        const lesmoment = {
                            uur: item.uur,
                            minuut: item.minuut,
                            lesDuur: item.lesDuur,
                        }
                        activiteitExists.lesmomenten.push(lesmoment)
                    }
                })
                return activiteiten
            }

            const stamgroepActiviteiten: IBasisroosterItemWithLesMomenten[] = createActiviteiten(basisrooster)

            const rooster: IBasisroosterPost = {
                titel: versieTitel,
                gebruikBasisrooster: useBasisroosterOnSave,
                basisroosterDto: [],
                activiteitenStamgroepBreedDto: stamgroepActiviteiten,
                versieId: versionId,
            }

            state.availableYeargroups.forEach((group) => {
                const records = state.basisrooster.filter((rooster) => rooster.jaargroepId === group.jaargroepId && !rooster.heleStamgroep)
                allRecords = [...allRecords, ...records]

                const activiteiten: IBasisroosterItemWithLesMomenten[] = createActiviteiten(records)

                rooster.basisroosterDto.push({
                    jaargroepLabelId: group.jaargroepId,
                    stamGroepId: rootState.groups.selectedGroup.id,
                    basisroosterRecords: activiteiten,
                })
            })

            const groups = rootState.groups.groups as IGroup[]
            const group = groups.find((group) => group.id === rootState.groups.selectedGroup.id)
            if (group) {
                group.hasBasisrooster = allRecords.length > 0
            }
            commit('setGroups', [])
            commit('setGroups', groups as IGroup[])

            onboardingService
                .postBasisroosterStamgroepNew(rootState.groups.selectedGroup.id, rooster)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data)
                    }
                })
                .catch((e) => {
                    reject(e)
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan. Error: ${e.message}`,
                    })
                })
        })
    },
    getVakActiviteitLabels({ commit }) {
        return new Promise((resolve, reject) => {
            const vaklabels = onboardingService.getVakgebiedLabels()
            const activiteitlabels = onboardingService.getActiviteitLabels()
            Promise.all([vaklabels, activiteitlabels])
                .then(([v, a]) => {
                    const vakken = (v.data as ILabel[]).map((item) => {
                        return {
                            ...item,
                            labelType: LabelType.Vakgebied,
                        }
                    })

                    const activiteiten = (a.data as ILabel[]).map((item) => {
                        return {
                            ...item,
                            labelType: LabelType.Activiteiten,
                        }
                    })

                    commit('setVakActiviteitLabels', [...vakken, ...activiteiten])
                    resolve([...vakken, ...activiteiten])
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    getBasisroosterVersies({ commit }) {
        commit('setBasisroosterVersiesLoading', true)
        return new Promise((resolve, reject) => {
            onboardingService
                .getBasisroosterVersies()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setBasisroosterVersies', data.data)
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    commit('setBasisroosterVersiesLoading', false)
                })
        })
    },
    revertBasisroosterVersie({ commit }, versieId: number) {
        return new Promise((resolve, reject) => {
            onboardingService
                .revertBasisroosterVersie(versieId)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    undoRevertBasisroosterVersie({ commit }) {
        return new Promise((resolve, reject) => {
            onboardingService
                .undoRevertBasisroosterVersie()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
    resetBasisroosterVersie({ commit }) {
        return new Promise((resolve, reject) => {
            onboardingService
                .resetBasisroosterVersie()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    },
}

export enum OnboardingFrontendExceptions {
    CompletelyInThePast = 1,
    PartiallyInThePast = 2,
    OriginalCompletelyInThePast = 3,
    OriginalPartiallyInThePast = 4,
}

const getOnboardingFrontendExceptionMessage = (frontendExceptionId?: number) => {
    switch (frontendExceptionId) {
        case OnboardingFrontendExceptions.CompletelyInThePast:
            return 'Aanpassingen in het verleden zijn niet mogelijk. Alleen wijzigingen vanaf vandaag worden doorgevoerd.'
        case OnboardingFrontendExceptions.PartiallyInThePast:
            return 'Aanpassingen in het verleden zijn niet mogelijk. Alleen wijzigingen vanaf vandaag worden doorgevoerd.'
        case OnboardingFrontendExceptions.OriginalCompletelyInThePast:
            return 'Aanpassingen in het verleden zijn niet mogelijk. Alleen wijzigingen vanaf vandaag worden doorgevoerd.'
        case OnboardingFrontendExceptions.OriginalPartiallyInThePast:
            return 'Aanpassingen in het verleden zijn niet mogelijk. Alleen wijzigingen vanaf vandaag worden doorgevoerd.'
        default:
            return 'Er ging iets mis'
    }
}

const rejectWithCustomMessage = (e: AxiosError<any>, reject: (reason?: any) => void): void => {
    if (e.response && e.response.data) {
        if (e.response.data.frontendExceptions) {
            reject(getOnboardingFrontendExceptionMessage(e.response.data.frontendExceptions.id))
        } else if (e.response.data.detail) {
            reject(e.response.data.detail)
        } else if (e.response.data.errors) {
            reject('Er ging iets mis')
        } else {
            reject(e.response.data)
        }
    } else if (e.message) {
        reject(e.message)
    } else {
        reject('Er is iets misgegaan')
    }
}

export default {
    state: new OnboardingState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}
