import { IResultFiltersHome } from './../interfaces/IFilters'
import axios from 'axios'

export default class ResultService {
    getResultsHome(params: IResultFiltersHome) {
        return axios.get(`/api/Frontend/Resultaten/Oefening/Homepage/Periode/${params.TimespanFilter}`)
    }
    getResultsGroep() {
        return axios.get('/api/Frontend/Resultaten/Algemeen/groep')
    }
    getResultsLeerling(leerlingId: number) {
        return axios.get(`/api/Frontend/Resultaten/Algemeen/leerling/${leerlingId}`)
    }
    getResultaatVakken() {
        return axios.get('/api/Frontend/Resultaten/Algemeen/Vakken')
    }
    getResultaatSoorten() {
        return axios.get('/api/Frontend/Resultaten/Algemeen/ResultaatSoorten')
    }
}

export const resultService = new ResultService()
