import { addMilliseconds, subMilliseconds } from 'date-fns'
import { getTimezoneOffset } from 'date-fns-tz'

export const convertLocalToUTC = (date: Date): Date => {
    const d = new Date(date)
    //when posting a date to backend this will convert the time from local to UTC
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const offset = getTimezoneOffset(tz, d)
    return addMilliseconds(d, offset)
}

export const convertUTCtoLocal = (date: Date): Date => {
    const d = new Date(date)
    //when retrieving a date from backend this will convert the time from UTC to local for display reasons
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const offset = getTimezoneOffset(tz, d)
    return subMilliseconds(d, offset)
}
