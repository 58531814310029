<template>
    <div
        backdrop
        id="sidebar-backdrop"
        class="sidebar"
        :class="{ 'sidebar-open': modelValue }"
    >
        <aside
            class="sidebar-container"
            :style="{ maxWidth: containerStyle?.maxWidth, borderRadius: containerStyle?.radius }"
        >
            <header class="sidebar-header">
                <slot name="sidebar-header">
                    <button
                        v-if="!options?.hideClose"
                        type="button"
                        aria-label="Close"
                        class="close text-dark"
                        @click="modelValue = false"
                    >
                        <svg
                            viewBox="0 0 16 16"
                            width="1em"
                            height="1em"
                            focusable="false"
                            role="img"
                            aria-label="x"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            class="bi-x b-icon bi"
                        >
                            <g><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></g>
                        </svg></button
                    ><span></span>
                </slot>
            </header>
            <section class="sidebar-body">
                <slot name="sidebar-body"></slot>
            </section>
            <footer class="sidebar-footer">
                <slot name="sidebar-footer"></slot>
            </footer>
        </aside>

        <div
            class="sidebar-backdrop"
            @click="modelValue = false"
        ></div>
    </div>
</template>
<script setup lang="ts">
interface Props {
    options?: {
        hideClose?: boolean
    }
    containerStyle?: {
        maxWidth?: string
        radius?: string
    }
}
const props = defineProps<Props>()

const modelValue = defineModel({
    default: false,
})
</script>
<style lang="scss" scoped>
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    overflow: hidden;
    pointer-events: none;

    &.sidebar-open {
        pointer-events: all;

        .sidebar-container {
            transform: translateX(0);
        }

        .sidebar-backdrop {
            opacity: 1;
        }
    }

    .sidebar-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: 640px;
        height: 100vh;
        z-index: 1000;
        overflow-y: auto;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        background: #f8f9fa;
        border-radius: 1rem 0 0 1rem;
    }

    .sidebar-backdrop {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }
}
</style>
