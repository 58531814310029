import { OefenenPeriode } from '@/enums/Timespan'
import { IOefeningenResultCollection, IResultUser } from '@/interfaces/IResult'
import axios, { AxiosResponse } from 'axios'

export default class MoelijksteOefeningenResultService {
    getVak(vakLabelId: number, period: OefenenPeriode) {
        return axios.get<IResultUser[]>(`/api/Frontend/Resultaten/MoeilijksteOefening/Vak/${vakLabelId}/periode/${period}`)
    }

    getLeerling(leerlingId: number, period: OefenenPeriode): Promise<AxiosResponse<IOefeningenResultCollection>> {
        return axios.get<IOefeningenResultCollection>(`/api/Frontend/Resultaten/MoeilijksteOefening/Leerling/${leerlingId}/periode/${period}`)
    }
}

export const moelijksteOefeningenResultService: MoelijksteOefeningenResultService = new MoelijksteOefeningenResultService()
