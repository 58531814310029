import { ILabel } from './../../interfaces/IPlanning'
import { GetterTree, MutationTree, ActionTree, createStore } from 'vuex'
import { appcuesIdentify, appcuesPage, appcuesTrack } from '@/helpers/appcues'
import { Usertype } from '@/enums/UserType'
import { notify } from '@kyvg/vue3-notification'
import Vue from 'vue'

class AppcuesState {}

const getters: GetterTree<AppcuesState, any> = {}

const mutations: MutationTree<AppcuesState> = {}

const actions: ActionTree<AppcuesState, any> = {
    appcuesPage() {
        return new Promise((resolve) => {
            try {
                appcuesPage()
            } catch (error) {
                notify({
                    group: 'requests',
                    title: 'Foutmelding',
                    type: 'error',
                    text: `Er is iets misgegaan met het versturen van data naar appcues.`,
                })
                resolve(null)
            }
            resolve(null)
        })
    },

    appcuesIdentify({ rootState }, customprops) {
        return new Promise((resolve, reject) => {
            const userId = rootState.user.currentUser.id
            const naam = rootState.user.currentUser.voornaam
            const rol = Usertype.get(rootState.user.currentUser.type) || 'onbekend'
            const groepen = (rootState.groups.yeargroups as ILabel[])?.map((group) => group.externId).join(', ') || ''
            const verwerkersOvereenkomstOk = rootState.settings.verwerkingsOvereenkomstOk ? 'ja' : 'nee'

            if (customprops) {
                for (const [key, value] of Object.entries(customprops)) {
                    if (Array.isArray(value)) {
                        customprops[key] = value.join(',')
                    }
                }
            }
            try {
                appcuesIdentify(userId, {
                    voornaam: naam,
                    rol: rol,
                    jaargroepen: groepen,
                    verwerkersOvereenkomstGeregeld: verwerkersOvereenkomstOk,
                    ...customprops,
                })
            } catch (error) {
                notify({
                    group: 'requests',
                    title: 'Foutmelding',
                    type: 'error',
                    text: `Er is iets misgegaan met het versturen van data naar appcues.`,
                })
                resolve(null)
            }
            resolve(null)
        })
    },

    appcuesTrack({ rootState }, customprops) {
        return new Promise((resolve, reject) => {
            if (customprops) {
                for (const [key, value] of Object.entries(customprops)) {
                    if (Array.isArray(value)) {
                        customprops[key] = value.join(',')
                    }
                }
            }
            try {
                appcuesTrack(customprops.evtName, customprops)
            } catch (error) {
                notify({
                    group: 'requests',
                    title: 'Foutmelding',
                    type: 'error',
                    text: `Er is iets misgegaan met het versturen van data naar appcues.`,
                })
                resolve(null)
            }

            resolve(null)
        })
    },
}

export default {
    state: new AppcuesState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}
