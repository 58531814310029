export enum ResultType {
    OEFENINGEN = 'Oefeningen',
    MOEILIJKSTE_OEFENINGEN = 'MoeilijksteOefeningen',
    TOETSEN = 'Toetsen',
    VOLGTOETSEN = 'VolgToetsen',
    SOCIAAL_EMOTIONEEL = 'SociaalEmotioneel',
    COMPETENTIES = 'Competenties',
}

export enum CandidateResultType {
    OEFENINGEN = 'Oefeningen',
    TOETSEN = 'Toetsen',
    MOEILIJKSTE_OEFENINGEN = 'MoeilijksteOefeningen',
    SOCIAAL_EMOTIONEEL = 'SociaalEmotioneel',
    COMPETENTIES = 'Competenties',
    VOLGTOETSEN = 'VolgToetsen',
}
