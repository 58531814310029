import { JaarPeriode, OefenenPeriode, ToetsenPeriode } from '@/enums/Timespan'
import { ICandidateResultVM } from '@/interfaces/ICandidateResult'
import { looqinCompetentiesResultService } from '@/services/resultaten/looqinCompetentiesResultService'
import { looqinSociaalEmotioneelResultService } from '@/services/resultaten/looqinSociaalEmotioneelResultService'
import { methodeToetsResultService } from '@/services/resultaten/methodeToetsResultService'
import { moelijksteOefeningenResultService } from '@/services/resultaten/moelijksteOefeningenResultService'
import { oefeningenResultService } from '@/services/resultaten/oefeningenResultService'
import { volgToetsResultService } from '@/services/resultaten/volgToetsResultService'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { ICandidateResultFilters } from '../../interfaces/IFilters'
import { CandidateResultType } from './../../enums/ResultType'

class ResultsDetailsState {
    filters: ICandidateResultFilters = {
        CandidateFilter: -1,
        TimespanFilter: OefenenPeriode.THIS_WEEK,
        MoeilijksteOefeningenTimespanFilter: OefenenPeriode.THIS_WEEK,
        ToetsTimespanFilter: ToetsenPeriode.THIS_WEEK,
        VolgenTimespanFilter: JaarPeriode.THIS_SCHOOLYEAR,
        SociaalTimespanFilter: JaarPeriode.THIS_SCHOOLYEAR,
        CompetentiesTimespanFilter: JaarPeriode.THIS_SCHOOLYEAR,
        CategoryFilter: 1,
        VolgCategoryFilter: 1,
    }
    results: ICandidateResultVM = {
        oefeningen: [],
        toetsen: [],
        moeilijksteOefeningen: [],
        volgToetsen: [],
        sociaalEmotioneel: [],
        competenties: [],
    }
    toetsenLoading = false
    volgtoetsenLoading = false
}

const getters: GetterTree<ResultsDetailsState, any> = {
    candidateDetailFilters(store) {
        return () => store.filters
    },
    candidateFilter(store) {
        return () => store.filters.CandidateFilter
    },
    candidateResults(store) {
        return () => store.results
    },
    candidateResultsLoading(store) {
        return () => store.toetsenLoading || store.volgtoetsenLoading
    },
}

const mutations: MutationTree<ResultsDetailsState> = {
    setFiltersCandidateDetail(state, payload: ICandidateResultFilters) {
        state.filters = state.filters = { ...state.filters, ...payload }
    },

    setCandidateResultsByType(state, payload) {
        switch (payload.type) {
            case CandidateResultType.OEFENINGEN:
                state.results.oefeningen = payload.data
                break
            case CandidateResultType.TOETSEN:
                state.results.toetsen = payload.data
                break
            case CandidateResultType.MOEILIJKSTE_OEFENINGEN:
                state.results.moeilijksteOefeningen = payload.data
                break
            case CandidateResultType.VOLGTOETSEN:
                state.results.volgToetsen = payload.data
                break
            case CandidateResultType.SOCIAAL_EMOTIONEEL:
                state.results.sociaalEmotioneel = payload.data
                break
            case CandidateResultType.COMPETENTIES:
                state.results.competenties = payload.data
                break
            default:
                break
        }
    },
}

const actions: ActionTree<ResultsDetailsState, any> = {
    async getCandidateResults({ commit, state }) {
        state.toetsenLoading = true
        const oefeningen = new Promise((resolve, reject) => {
            oefeningenResultService
                .getLeerling(state.filters.CandidateFilter, state.filters.TimespanFilter)
                .then((data) => {
                    resolve({ type: CandidateResultType.OEFENINGEN, data: data.data })
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er ging iets mis met het ophalen van de oefeningresultaten voor de leerling, Error: ${e.message}`,
                    })
                    reject(e)
                })
        })

        const toetsen = new Promise((resolve, reject) => {
            methodeToetsResultService
                .getLeerling(state.filters.CandidateFilter, state.filters.ToetsTimespanFilter)
                .then((data) => {
                    resolve({ type: CandidateResultType.TOETSEN, data: data.data })
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er ging iets mis met het ophalen van de toetsresultaten voor de leerling, Error: ${e.message}`,
                    })
                    reject(e)
                })
        })

        const moeilijksteOefeningen = new Promise((resolve, reject) => {
            moelijksteOefeningenResultService
                .getLeerling(state.filters.CandidateFilter, state.filters.MoeilijksteOefeningenTimespanFilter)
                .then((data) => {
                    resolve({ type: CandidateResultType.MOEILIJKSTE_OEFENINGEN, data: data.data })
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er ging iets mis met het ophalen van de moeilijkste oefeningen voor de leerling, Error: ${e.message}`,
                    })
                    reject(e)
                })
        })

        const results = await Promise.allSettled([oefeningen, toetsen, moeilijksteOefeningen])

        results.forEach((result) => {
            if (result.status === 'fulfilled') {
                commit('setCandidateResultsByType', result.value)
            }
        })

        state.toetsenLoading = false
    },

    async getCandidateVolgResults({ commit, state }) {
        state.volgtoetsenLoading = true
        const volgtoetsen = new Promise((resolve, reject) => {
            volgToetsResultService
                .getLeerling(state.filters.CandidateFilter, state.filters.VolgenTimespanFilter)
                .then((data) => {
                    resolve({ type: CandidateResultType.VOLGTOETSEN, data: data.data })
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er ging iets mis met het ophalen van de oefeningresultaten voor de leerling, Error: ${e.message}`,
                    })
                    reject(e)
                })
        })

        const sociaalEmotioneel = new Promise((resolve, reject) => {
            looqinSociaalEmotioneelResultService
                .getLeerling(state.filters.CandidateFilter, state.filters.SociaalTimespanFilter)
                .then((data) => {
                    resolve({ type: CandidateResultType.SOCIAAL_EMOTIONEEL, data: data.data })
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er ging iets mis met het ophalen van de toetsresultaten voor de leerling, Error: ${e.message}`,
                    })
                    reject(e)
                })
        })

        const competenties = new Promise((resolve, reject) => {
            looqinCompetentiesResultService
                .getLeerling(state.filters.CandidateFilter, state.filters.CompetentiesTimespanFilter)
                .then((data) => {
                    resolve({ type: CandidateResultType.COMPETENTIES, data: data.data })
                })
                .catch((e: Error) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er ging iets mis met het ophalen van de moeilijkste oefeningen voor de leerling, Error: ${e.message}`,
                    })
                    reject(e)
                })
        })

        const results = await Promise.allSettled([volgtoetsen, sociaalEmotioneel, competenties])

        results.forEach((result) => {
            if (result.status === 'fulfilled') {
                commit('setCandidateResultsByType', result.value)
            }
        })

        state.volgtoetsenLoading = false
    },
}

export default {
    state: new ResultsDetailsState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}
