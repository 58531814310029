import { JaarPeriode } from '@/enums/Timespan'
import { IResultCollection, IResultUser } from '@/interfaces/IResult'
import axios from 'axios'

export default class VolgToetsResultService {
    getOverzicht(period: JaarPeriode) {
        return axios.get<IResultCollection>(`api/Frontend/Resultaten/Volgtoets/Overzicht/periode/${period}`)
    }
    getVak(vakLabelId: number, period: JaarPeriode) {
        return axios.get<IResultUser[]>(`api/Frontend/Resultaten/Volgtoets/Vak/${vakLabelId}/periode/${period}`)
    }

    getLeerling(leerlingId: number, period: JaarPeriode) {
        return axios.get<IResultCollection>(`api/Frontend/Resultaten/Volgtoets/Leerling/${leerlingId}/periode/${period}`)
    }
}

export const volgToetsResultService: VolgToetsResultService = new VolgToetsResultService()
