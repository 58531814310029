<template>
    <VueFinalModal
        class="d-flex align-items-center justify-content-center"
        :content-class="'planning-modal-container'"
        v-model="showValue"
    >
        <div class="planning-modal-scroll-container">
            <div
                class="planning-modal-content p-4"
                :class="contentClassNames"
            >
                <div class="modal-header">
                    <div class="header-wrapper d-flex justify-content-end w-100">
                        <Close
                            v-if="!hideClose"
                            @click="handleClose"
                        />
                    </div>
                </div>
                <div class="body-wrapper p-0 border-0">
                    <div class="body-header py-4">
                        <slot name="title"> </slot>
                    </div>
                    <div class="body-content">
                        <slot name="content"> </slot>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="footer-wrapper border-0">
                        <div class="button-wrapper">
                            <slot name="button">
                                <button
                                    class="btn btn-primary btn-sm ml-auto"
                                    @click="handleClose"
                                >
                                    Sluiten
                                </button>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </VueFinalModal>
</template>
<script lang="ts" setup>
import Close from '@/components-svg/Close.vue'
import { computed } from 'vue'
import { VueFinalModal } from 'vue-final-modal'

interface Props {
    modalId: string
    size?: 'sm' | 'md' | 'lg' | 'xl'
    hideClose?: boolean
    show?: boolean
    dialog?: boolean
    noCloseOnBackdrop?: boolean
}

interface Emit {
    (e: 'update:show', val: boolean): void
    (e: 'close-modal'): void
}

const props = withDefaults(defineProps<Props>(), {
    modalId: 'info-modal',
    size: 'md',
    hideClose: false,
    show: false,
    noCloseOnBackdrop: false,
})

const emit = defineEmits<Emit>()

const showValue = computed({
    get() {
        return props.show
    },
    set(value) {
        emit('update:show', value)
    },
})

const contentClassNames = computed(() => {
    return `${props.dialog ? 'modal-dialog' : ''} ${size.value}`
})

const size = computed(() => {
    switch (props.size) {
        case 'sm':
            return 'modal-sm'
        case 'md':
            return 'modal-md'
        case 'lg':
            return 'modal-lg'
        case 'xl':
            return 'modal-xl'
    }
})

function handleClose() {
    showValue.value = false
    emit('close-modal')
}
</script>
