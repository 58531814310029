import { LabelType } from '@/enums/LabelType'
import { LesTemplateType } from '@/enums/LesTemplateType'
import { PlanItemType } from '@/enums/PlanItemType'
import { ILesActiviteit } from '@/interfaces/ILesActiviteit'
import { IEventAfspraakDetails } from '@/interfaces/ILesdagSpeciaal'

import { INoteIngeplandPlanning } from './INote'

export class Afspraak implements IAfspraakPostModel {
    heleDag = false
    titel = ''
    omschrijving = ''
    datum = new Date()
    tijdStart = '09:00'
    tijdEind = '17:00'
    AfspraakAfbeelding = ''
    jaargroepenString: number[] = []
    vakgebiedLabelId: number | null = null
    activiteitLabelId: number | null = null
    methodeLesId: number | null = null
    methodeBlokId: number | null = null
    templateId: number | null = null
    subActiviteit: ILesActiviteit[] | null = null
    eigenInvullingTekst: string = ''
}

export interface IPlanItem {
    activiteitLabelId: number | null
    id: number
    blockId: string
    activiteitType: number
    datumTijd: Date
    lesduur: number
    vervallen: boolean
    naamVak: string
    naamLes: string
    naamMethode: string
    naamTemplate: string
    naamBasisActiviteit: string
    naamActiviteitLabel: string
    omschrijvingLes: string
    omschrijvingActiviteit: string
    omschrijvingMethodegroep: string
    omschrijvingMethode: string
    omschrijvingMethodeBlok: string
    eigenTitel: string
    eigenOmschrijving: string
    lesId: number
    lesplanningTemplateId: number | null
    methodeId: number
    methodeBlok: number | null
    jaargroepLabelId: number
    stamgroepId: number
    vakgebiedId: number
    kleurcodeBorder?: string
    isLes?: boolean
    nieuweDatum?: string
    nieuweEindDatum?: string
    naamMethodeBlok: string
    isStamgroepBreed: boolean
    isGekoppeld: boolean
    subActiviteit: {
        id: number
        inhoud: string
        htmlInhoud: string
    }[]
    eigenInvullingTekst: string
    isBasedOnFixedTemplatePlaceholder: boolean
}

export interface IPlanItemIngepland {
    id: number
    titel: string
    omschrijving: string
    heleDag: boolean
    datumStart: Date
    datumEind: Date
    afbeeldingUrl: string
    schoolId: number
    groepId: number
    afspraakDetails: IEventAfspraakDetails
    planItemType?: PlanItemType
    textColor?: string
    borderColor?: string
    backgroundColor: string
    subActiviteit?: ILesActiviteit[] | null
    eigenInvullingTekst: string
}

export interface IIngeplandeItems {
    afspraken: IPlanItemIngepland[]
    studiedagen: IPlanItemIngepland[]
    themadagen: IPlanItemIngepland[]
    toetsperiodes: IPlanItemIngepland[]
    vakanties: IPlanItemIngepland[]
    notities: INoteIngeplandPlanning[]
}

export interface ISchoolweken {
    eersteSchooldag: Date
    laatsteSchooldag: Date
    weeknummerInformatie: ISchoolweekInfo[]
}

export interface ISchoolweekInfo {
    datum: Date
    schoolWeeknummer: number
    iso8601Weeknumber: number
}

export interface IPlanItemPost {
    titel: string
    omschrijving: string
    eigenInvullingTekst: string
}

export interface ILesMovePost {
    lesId: number
    stamgroepId: number
    nieuweDatum: string
    nieuweEindDatum: string
}

export interface ILesReeksMovePost {
    lesId: number
    stamgroepId: number
    aantalWeken: number
}

export interface IAfspraak {
    id: number
    titel: string
    omschrijving: string
    heleDag: boolean
    datumStart: Date
    datumEind: Date
    afbeeldingUrl: string
    schoolId: number
    groepId: number
}

export interface IAfspraakPostModel {
    id?: number
    titel: string
    omschrijving: string
    heleDag: boolean
    datum: Date
    tijdStart: string
    tijdEind: string
    AfspraakAfbeelding: string
    jaargroepenString: number[] // i know its weird, formdata makes this a string
    vakgebiedLabelId: number | null
    activiteitLabelId: number | null
    templateId: number | null
    methodeBlokId: number | null
    methodeLesId: number | null
    eigenInvullingTekst: string
}

export interface IAfspraakPost {
    titel: string
    omschrijving: string
    heleDag: boolean
    datumStart: string
    datumEind: string
    afspraakAfbeelding: File | null
    jaargroepenString: number[]
    vakgebiedLabelId: number | null
    activiteitLabelId: number | null
    templateId: number | null
    methodeBlokId: number | null
    methodeLesId: number | null
    eigenInvullingTekst: string
}

export interface IAfspraakPut {
    id?: number
    titel: string
    omschrijving: string
    heleDag: boolean
    datumStart: string
    datumEind: string
    afspraakAfbeelding: File | null
    jaargroepenString: number[]
    vakgebiedLabelId: number | null
    activiteitLabelId: number | null
    templateId: number | null
    methodeBlokId: number | null
    methodeLesId: number | null
    eigenInvullingTekst: string
}

export interface ILabelKleur {
    labelId: number
    naam: string
    kleurHexCode: string
}

export interface IMethodeBlokCollectionPlanning {
    lesTemplateId: number
    blokken: IMethodeBlokPlanning[]
}

export interface IMethodeBlokPlanning {
    methodeBlokId: number
    methodeBlokNaam: string
    lessen: IMethodeLesPlanning[]
}

export const LES_PLANNING_STATUS = {
    NONE: 0,
    GEGEVEN: 1,
    GEPLANNED: 2,
    VERVALLEN: 3,
} as const

export type LesPlanningStatus = (typeof LES_PLANNING_STATUS)[keyof typeof LES_PLANNING_STATUS]

export interface IMethodeLesPlanning {
    methodeLesId: number
    methodeLesNaam: string
    status: LesPlanningStatus
}

export interface IActiviteitenCollectionPlanning {
    jaargroepLabelId: number
    jaargroep: string
    vakken: IActiviteitenCollectionPlanningVak[]
}

export interface IActiviteitenCollectionPlanningVak {
    id: number
    naam: string
    templates: IActiviteitenCollectionPlanningTemplate[]
}

export interface IActiviteitenCollectionPlanningTemplate {
    id: number | null
    naam: string
    omschrijving: string
    blokkenLineair: LesTemplateType
    standaardAantalLesMomenten: number
    standaardLesduur: number
    methodeBlokken: IActiviteitenCollectionPlanningBlok[]
}

export interface IActiviteitenCollectionPlanningBlok {
    id: number
    naam: string
    omschrijving: string
    methodeLessen: IActiviteitenCollectionPlanningLes[]
}

export interface IActiviteitenCollectionPlanningLes {
    id: number
    naam: string
    omschrijving: string
}

export interface ILabel {
    labelId: number
    naam: string
    externId: string
    kleurHexCode: string
    labelType?: LabelType
    labelKleurCreatorType?: 0 | 1 | 2 // 0 = None, 1 = Group, 2 = User
}

export enum SearchAction {
    Equals = 0,
    Contains = 1,
}

export interface IIngeplandeItemsSearchRequest {
    searchString?: string
    searchAction: SearchAction
    skip: number
    take: number
    vakgebiedId: number
    stamgroepId: number
}

export interface IIngeplandeItemsSearchResponse {
    total: number
    ingeplandeItems: IPlanItem[]
}
