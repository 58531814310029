import { IYearGroupLabel } from '@/interfaces/IGroup'
import { ILabelVerdiepen, IVerdiepenItem, IVerdiepenQueryParams } from '@/interfaces/IVerdiepenItem'
import { verdiepenService } from '@/services/verdiepenService'
import { notify } from '@kyvg/vue3-notification'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { IMethodeVerdiepen } from './../../interfaces/ILesTemplate'
import { ILabel } from './../../interfaces/IPlanning'
import { IVerdiepenFilters } from './../../interfaces/IVerdiepenItem'

class VerdiepenState {
    loading = false
    searchText = ''
    favouriteOnly = false
    showAll = true
    visibleItems = 2
    verdiepenItems: IVerdiepenItem[] = []
    verdiepenCategorieen: ILabelVerdiepen[] = []
    verdiepenMethodes: IMethodeVerdiepen[] = []
    verdiepenVakken: ILabel[] = []
    verdiepenJaargroepen: ILabel[] = []
    filters: IVerdiepenFilters = {
        jaargroepLabel: null,
        vakLabel: { labelId: -1, naam: 'Alle vakken', kleurHexCode: '', externId: '' },
        methodeLabel: { id: -1, naam: 'Alle methodes' },
    }
}

const getters: GetterTree<VerdiepenState, any> = {
    verdiepenLoading(state) {
        return () => state.loading
    },
    getSearchTextVerdiepen(state) {
        return () => state.searchText
    },
    verdiepenItems(state) {
        return () => state.verdiepenItems
    },
    verdiepenItemsFiltered(state) {
        return () =>
            state.verdiepenItems
                .filter((item) => state.verdiepenCategorieen.find((label) => label.active && label.labelId === item.categorieLabelId) || state.showAll)
                .filter((item) => {
                    if (state.favouriteOnly) {
                        return item.isFavoriet
                    }
                    return true
                })
    },
    verdiepenCategorieen(state) {
        return () => state.verdiepenCategorieen
    },
    verdiepenMethodes(state) {
        return () => [{ id: -1, naam: 'Alle methodes' }, ...state.verdiepenMethodes]
    },
    verdiepenVakken(state) {
        return () => [{ labelId: -1, naam: 'Alle vakken', kleurHexCode: '' }, ...state.verdiepenVakken]
    },
    verdiepenJaargroepen(state) {
        return () => state.verdiepenJaargroepen
    },
    verdiepenFilters(state) {
        return () => state.filters
    },
    favouriteOnly(state) {
        return () => state.favouriteOnly
    },
    showAll(state) {
        return () => state.showAll
    },
    visibleItems(state) {
        return () => state.visibleItems
    },
}

const mutations: MutationTree<VerdiepenState> = {
    setVerdiepenLoading(state, payload: boolean) {
        state.loading = payload
    },
    setVerdiepenItems(state, payload: IVerdiepenItem[]) {
        state.verdiepenItems = payload
    },
    appendVerdiepenItems(state, payload: IVerdiepenItem[]) {
        state.verdiepenItems = [...state.verdiepenItems, ...payload]
    },
    setVerdiepenSearch(state, payload: string) {
        state.searchText = payload
    },
    setVerdiepenFiltersFromParams(state, payload: IVerdiepenQueryParams) {
        const methodelabel = state.verdiepenMethodes.find((methode) => methode.id === payload.methodeId)
        const vaklabel = state.verdiepenVakken.find((vak) => vak.labelId === payload.vaklabelId)
        const jaargroeplabel = state.verdiepenJaargroepen.find((jaargroep) => jaargroep.labelId === payload.jaargroepLabelId)

        if (methodelabel) {
            state.filters.methodeLabel = methodelabel
        }

        if (vaklabel) {
            state.filters.vakLabel = vaklabel
        }

        if (jaargroeplabel) {
            state.filters.jaargroepLabel = jaargroeplabel
        }
    },
    setVerdiepenCategorieen(state, payload: ILabelVerdiepen[]) {
        state.verdiepenCategorieen = payload
    },
    setVerdiepenMethodes(state, payload: IMethodeVerdiepen[]) {
        state.verdiepenMethodes = payload
    },
    setVerdiepenVakken(state, payload: ILabel[]) {
        state.verdiepenVakken = payload
    },
    setVerdiepenJaargroepen(state, payload: ILabel[]) {
        state.verdiepenJaargroepen = payload
    },
    setFavouriteVerdiepenItem(state, payload: IVerdiepenItem) {
        const index = state.verdiepenItems.findIndex((item) => item.id === payload.id)
        if (index > -1) {
            state.verdiepenItems[index] = payload
        }
    },
    setVerdiepenFilter(state, payload: ILabelVerdiepen) {
        const index = state.verdiepenCategorieen.findIndex((item) => item.labelId === payload.labelId)
        if (index > -1) {
            state.verdiepenCategorieen[index] = payload
        }

        const activeFilter = state.verdiepenCategorieen.find((item) => item.active)

        if (activeFilter) {
            state.showAll = false
        } else {
            state.showAll = true
        }
    },
    setFavouriteOnly(state, payload: boolean) {
        state.favouriteOnly = payload
    },
    setShowAll(state, payload: boolean) {
        state.showAll = payload
        if (payload) {
            state.verdiepenCategorieen = state.verdiepenCategorieen.map((cat) => {
                return {
                    ...cat,
                    active: false,
                }
            })
        }
    },
    setVerdiepenFilters(state, payload: ILabel | IMethodeVerdiepen) {
        state.filters = { ...state.filters, ...payload }
    },
    setAllVerdiepenFilters(state, payload: IVerdiepenFilters) {
        state.filters = payload
    },
    resetMethodeFilter(state) {
        state.filters.methodeLabel = { id: -1, naam: 'Alle methodes' }
    },
    increaseVisibleItems(state) {
        state.visibleItems += 12
    },
    resetVisibleItems(state) {
        state.visibleItems = 12
    },
}

const actions: ActionTree<VerdiepenState, any> = {
    handleVerdiepenSearch({ commit, state }) {
        return new Promise((resolve, reject) => {
            state.loading = true
            commit('resetVisibleItems')

            const params: IVerdiepenQueryParams = {
                query: state.searchText,
                vaklabelId: state.filters.vakLabel && state.filters.vakLabel.labelId != -1 ? state.filters.vakLabel.labelId : null,
                jaargroepLabelId: state.filters.jaargroepLabel ? state.filters.jaargroepLabel.labelId : null,
                methodeId: state.filters.methodeLabel && state.filters.methodeLabel.id != -1 ? state.filters.methodeLabel.id : null,
                take: state.visibleItems,
                skip: 0,
            }

            verdiepenService
                .getItems(params)
                .then((data) => {
                    commit('setVerdiepenItems', data.data)
                    state.loading = false
                    resolve(params)
                })
                .catch((e) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan met het ophalen van de verdiepenitems. Error: ${e.message}`,
                    })
                    state.loading = false
                    reject(e)
                })
        })
    },

    setVerdiepenFiltersFromParams({ commit, state, dispatch }, payload: IVerdiepenQueryParams) {
        return new Promise((resolve, reject) => {
            const vaklabel = state.verdiepenVakken.find((vak) => vak.labelId === payload.vaklabelId) || { labelId: -1, naam: 'Alle vakken', kleurHexCode: '' }
            const jaargroeplabel = state.verdiepenJaargroepen.find((jaargroep) => jaargroep.labelId === payload.jaargroepLabelId) || state.verdiepenJaargroepen[0]

            commit('setVerdiepenFilters', { jaargroepLabel: jaargroeplabel })
            commit('setVerdiepenFilters', { vakLabel: vaklabel })

            dispatch('getVerdiepenMethodes')
                .then((methodes: IMethodeVerdiepen[]) => {
                    if (methodes) {
                        const methodelabel = methodes.find((methode) => methode.id === payload.methodeId) || { labelId: -1, naam: 'Alle methodes', kleurHexCode: '' }

                        if (methodelabel) {
                            commit('setVerdiepenFilters', { methodeLabel: methodelabel })
                        }
                    }

                    resolve(null)
                })
                .catch(() => reject(null))
        })
    },

    takeMoreVerdiepenItems({ commit, state }) {
        return new Promise((resolve, reject) => {
            state.loading = true
            commit('increaseVisibleItems')

            const params: IVerdiepenQueryParams = {
                query: state.searchText,
                vaklabelId: state.filters.vakLabel && state.filters.vakLabel.labelId != -1 ? state.filters.vakLabel.labelId : null,
                jaargroepLabelId: state.filters.jaargroepLabel ? state.filters.jaargroepLabel.labelId : null,
                methodeId: state.filters.methodeLabel && state.filters.methodeLabel.id != -1 ? state.filters.methodeLabel.id : null,
                take: state.visibleItems,
                skip: state.verdiepenItems.length,
            }

            verdiepenService
                .getItems(params)
                .then((data) => {
                    commit('appendVerdiepenItems', data.data)
                    state.loading = false
                    resolve(data.data)
                })
                .catch((e) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan met het ophalen van de verdiepenitems. Error: ${e.message}`,
                    })
                    state.loading = false
                    reject(e)
                })
        })
    },
    getVerdiepenCategorieen({ commit }) {
        return new Promise((resolve, reject) => {
            verdiepenService
                .getVerdiepenCategorieen()
                .then((data) => {
                    const categories: ILabelVerdiepen[] = data.data.map((cat: ILabel) => {
                        return {
                            ...cat,
                            active: false,
                        }
                    })
                    commit('setVerdiepenCategorieen', categories)
                    resolve(data.data)
                })
                .catch((e) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan met het ophalen van de categorieen. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    getVerdiepenMethodes({ commit, state }) {
        return new Promise((resolve, reject) => {
            if (state.filters.vakLabel?.labelId && state.filters.vakLabel?.labelId > -1) {
                verdiepenService
                    .getVerdiepenMethodes(state.filters.vakLabel.labelId)
                    .then((data) => {
                        const methodes: IMethodeVerdiepen[] = data.data
                        commit('setVerdiepenMethodes', methodes)
                        resolve(methodes)
                    })
                    .catch((e) => {
                        notify({
                            group: 'requests',
                            title: 'Foutmelding',
                            type: 'error',
                            text: `Er is iets misgegaan met het ophalen van de methodes. Error: ${e.message}`,
                        })
                        reject(e)
                    })
            } else {
                reject()
            }
        })
    },
    getVerdiepenVakken({ commit }) {
        return new Promise((resolve, reject) => {
            verdiepenService
                .getVerdiepenVakLabels()
                .then((data) => {
                    const vakken: ILabel[] = (data.data as ILabel[]).sort((a, b) => {
                        if (a.naam < b.naam) {
                            return -1
                        }
                        if (a.naam > b.naam) {
                            return 1
                        }
                        return 0
                    })
                    commit('setVerdiepenVakken', vakken)
                    resolve(vakken)
                })
                .catch((e) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan met het ophalen van de vakken. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    getVerdiepenJaargroepen({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            verdiepenService
                .getVerdiepenJaargroepen(rootState.groups.selectedGroup.id)
                .then((data) => {
                    const jaargroepen = (data.data as IYearGroupLabel[]).sort((a, b) => {
                        if (a.naam < b.naam) {
                            return -1
                        }
                        if (a.naam > b.naam) {
                            return 1
                        }
                        return 0
                    })
                    commit('setVerdiepenJaargroepen', jaargroepen)
                    if (jaargroepen && jaargroepen.length > 0) {
                        commit('setVerdiepenFilters', { jaargroepLabel: jaargroepen[0] })
                    }

                    resolve(data.data)
                })
                .catch((e) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er is iets misgegaan met het ophalen van de vakken. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
    favouriteVerdiepenItem({ commit }, item: IVerdiepenItem) {
        return new Promise((resolve, reject) => {
            const newItem = item
            newItem.isFavoriet = !item.isFavoriet

            verdiepenService
                .putFavoriteVerdiepenItem(newItem.id, newItem.isFavoriet)
                .then((data) => {
                    commit('setFavouriteVerdiepenItem', newItem)
                    resolve(data.data)
                })
                .catch((e) => {
                    notify({
                        group: 'requests',
                        title: 'Foutmelding',
                        type: 'error',
                        text: `Er ging iets mis met het liken van dit item. Error: ${e.message}`,
                    })
                    reject(e)
                })
        })
    },
}

export default {
    state: new VerdiepenState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}
