import { ILabelKleurDto } from '@/interfaces/ILabelKleur';
import axios, { AxiosResponse } from 'axios';
import { LabelType } from './../enums/LabelType';
export default class LabelsService {
    getLabelsByType(labeltype: LabelType) {
        return axios.get(`api/Frontend/Labels/${labeltype}`);
    }
	uploadCustomColours(labelKleuren: ILabelKleurDto[]): Promise<AxiosResponse<void>> {
		return axios.post('api/Frontend/Labels/', labelKleuren);
	}
	getVakLabelKleuren() {
		return axios.get('api/Frontend/Labels/Vakgebieden/standaardkleuren');
	}
	resetAllLabelColors(creatorType: 1 | 2) {
		return axios.delete(`api/Frontend/Labels/${creatorType}`);
	}
	resetLabelColor(labelId: number, creatorType: 1 | 2) {
		return axios.delete(`api/Frontend/Labels/${creatorType}/${labelId}`);
	}
}

export const labelsService = new LabelsService();