import axios from 'axios'

import { convertLocalToUTC } from '@/helpers/convertTimezone'
import { ISelectedLesTemplatePutCopy } from '@/interfaces/ILesTemplate'

import { LabelType } from './../enums/LabelType'
import { IBasisroosterDto, IBasisroosterPost } from './../interfaces/IBasisrooster'
import { IDay } from './../interfaces/IDay'

export default class OnboardingService {
    //voorwaarden
    getVoorwaarden(payload: any) {
        return axios.post(`api/Frontend/onboarding/Gebruikersvoorwaarden`, payload)
    }
    getBasispoortToestemming() {
        return axios.get(`api/Frontend/onboarding/Gebruikersvoorwaarden/BasispoortToestemming`)
    }
    getRegios() {
        return axios.get(`api/Frontend/onboarding/Gebruikersvoorwaarden/Regios`)
    }
    getSelectedRegio() {
        return axios.get(`api/Frontend/onboarding/Gebruikersvoorwaarden/GetSelectedRegio`)
    }
    //end voorwaarden

    //vakanties
    getVakanties() {
        return axios.get(`api/Frontend/onboarding/Schoolvakantie`)
    }
    updateVakantie(day: IDay) {
        const dayToSend = {
            id: day.id,
            titel: day.titel,
            datumStart: convertLocalToUTC(day.datumStart).toISOString() as Date | string,
            datumEind: convertLocalToUTC(day.datumEind).toISOString() as Date | string,
            zelfToegevoegd: day.zelfToegevoegd,
        } as Pick<IDay, 'id' | 'titel' | 'datumStart' | 'datumEind' | 'zelfToegevoegd'>

        return axios.post(`api/Frontend/onboarding/Schoolvakantie/update`, dayToSend)
    }
    createVakantie(day: IDay) {
        const dayToSend = {
            id: day.id,
            titel: day.titel,
            datumStart: convertLocalToUTC(day.datumStart).toISOString() as Date | string,
            datumEind: convertLocalToUTC(day.datumEind).toISOString() as Date | string,
            zelfToegevoegd: day.zelfToegevoegd,
        } as Pick<IDay, 'id' | 'titel' | 'datumStart' | 'datumEind' | 'zelfToegevoegd'>

        return axios.put(`api/Frontend/onboarding/Schoolvakantie`, dayToSend)
    }
    deleteVakantie(dagId: string) {
        return axios.delete(`api/Frontend/onboarding/Schoolvakantie/${dagId}`)
    }
    //end vakanties

    //studiedagen
    getStudiedagen() {
        return axios.get(`api/Frontend/onboarding/Studiedag/`)
    }
    createStudiedag(studiedag: IDay) {
        const studiedagToSend = {
            id: studiedag.id,
            titel: studiedag.titel,
            omschrijving: studiedag.omschrijving,
            datumStart: convertLocalToUTC(studiedag.datumStart).toISOString() as Date | string,
            datumEind: convertLocalToUTC(studiedag.datumEind).toISOString() as Date | string,
            heleDag: studiedag.heleDag,
            lesVrij: studiedag.lesVrij,
        } as Pick<IDay, 'id' | 'titel' | 'omschrijving' | 'datumStart' | 'datumEind' | 'heleDag' | 'lesVrij'>

        return axios.post(`api/Frontend/onboarding/Studiedag/create/${studiedag.groepering}`, studiedagToSend)
    }
    updateStudiedag(studiedag: IDay) {
        const studiedagToSend = {
            id: studiedag.id,
            titel: studiedag.titel,
            omschrijving: studiedag.omschrijving,
            datumStart: convertLocalToUTC(studiedag.datumStart).toISOString() as Date | string,
            datumEind: convertLocalToUTC(studiedag.datumEind).toISOString() as Date | string,
            heleDag: studiedag.heleDag,
            lesVrij: studiedag.lesVrij,
        } as Pick<IDay, 'id' | 'titel' | 'omschrijving' | 'datumStart' | 'datumEind' | 'heleDag' | 'lesVrij'>
        return axios.post(`api/Frontend/onboarding/Studiedag/update`, studiedagToSend)
    }
    deleteStudiedag(studiedagId: string) {
        return axios.delete(`api/Frontend/onboarding/Studiedag/${studiedagId}`)
    }
    //end studiedagen

    //activiteiten
    getActiviteiten() {
        return axios.get(`api/Frontend/onboarding/Themadag/`)
    }
    getActiviteitenStandaard() {
        return axios.get(`api/Frontend/onboarding/Themadag/Standaard`)
    }
    createActiviteit(day: IDay) {
        const dayToSend = {
            id: day.id,
            titel: day.titel,
            omschrijving: day.omschrijving,
            datumStart: convertLocalToUTC(day.datumStart).toISOString() as Date | string,
            datumEind: convertLocalToUTC(day.datumEind).toISOString() as Date | string,
            heleDag: day.heleDag,
            lesVrij: day.lesVrij,
        } as Pick<IDay, 'id' | 'titel' | 'omschrijving' | 'datumStart' | 'datumEind' | 'heleDag' | 'lesVrij'>

        return axios.post(`api/Frontend/onboarding/Themadag/create/${day.groepering}`, dayToSend)
    }
    updateActiviteit(day: IDay) {
        const dayToSend = {
            id: day.id,
            titel: day.titel,
            omschrijving: day.omschrijving,
            datumStart: convertLocalToUTC(day.datumStart).toISOString() as Date | string,
            datumEind: convertLocalToUTC(day.datumEind).toISOString() as Date | string,
            heleDag: day.heleDag,
            lesVrij: day.lesVrij,
        } as Pick<IDay, 'id' | 'titel' | 'omschrijving' | 'datumStart' | 'datumEind' | 'heleDag' | 'lesVrij'>

        return axios.post(`api/Frontend/onboarding/Themadag/update`, dayToSend)
    }
    deleteActiviteit(activiteitId: string) {
        return axios.delete(`api/Frontend/onboarding/Themadag/${activiteitId}`)
    }
    //end activiteiten

    //toetsen
    getToetsen() {
        return axios.get(`api/Frontend/onboarding/Toetsperiode/`)
    }
    createToets(day: IDay) {
        const dayToSend = {
            id: day.id,
            titel: day.titel,
            omschrijving: day.omschrijving,
            datumStart: convertLocalToUTC(day.datumStart).toISOString() as Date | string,
            datumEind: convertLocalToUTC(day.datumEind).toISOString() as Date | string,
            heleDag: day.heleDag,
            lesVrij: day.lesVrij,
        } as Pick<IDay, 'id' | 'titel' | 'omschrijving' | 'datumStart' | 'datumEind' | 'heleDag' | 'lesVrij'>

        return axios.post(`api/Frontend/onboarding/Toetsperiode/create/${day.groepering}`, dayToSend)
    }
    updateToets(day: IDay) {
        const dayToSend = {
            id: day.id,
            titel: day.titel,
            omschrijving: day.omschrijving,
            datumStart: convertLocalToUTC(day.datumStart).toISOString() as Date | string,
            datumEind: convertLocalToUTC(day.datumEind).toISOString() as Date | string,
            heleDag: day.heleDag,
            lesVrij: day.lesVrij,
        } as Pick<IDay, 'id' | 'titel' | 'omschrijving' | 'datumStart' | 'datumEind' | 'heleDag' | 'lesVrij'>

        return axios.post(`api/Frontend/onboarding/Toetsperiode/update`, dayToSend)
    }
    deleteToets(groepId: string) {
        return axios.delete(`api/Frontend/onboarding/Toetsperiode/${groepId}`)
    }
    //end toetsen

    //lestemplates
    getTemplates(groepId: string) {
        return axios.get(`api/Frontend/onboarding/LesTemplates/${groepId}`)
    }
    getSelectedTemplates(groepId: number, schooljaarId?: number) {
        return axios.get(`api/Frontend/onboarding/LesTemplates/selected/${groepId}`, {
            params: {
                schooljaarId: schooljaarId,
            },
        })
    }
    putTemplates(stamgroepId: number, payload: any) {
        return axios.put(`api/Frontend/onboarding/LesTemplates/${stamgroepId}`, payload)
    }
    copyBasisrooster(payload: ISelectedLesTemplatePutCopy) {
        return axios.put(`api/Frontend/onboarding/LesTemplates/copyBasisrooster`, payload)
    }
    getMethodeBlokken(templateId: string | number) {
        return axios.get(`api/Frontend/onboarding/MethodeBlok/${templateId}`)
    }
    getMethodes(jaarGroep: number) {
        return axios.get(`api/Frontend/onboarding/Basisrooster/Methodes/${jaarGroep}`)
    }
    //end lestemplates

    //basisrooster
    getBasisrooster(groepId: number) {
        return axios.get(`/api/Frontend/onboarding/Basisrooster/${groepId}`)
    }

    postBasisrooster(payload: IBasisroosterDto) {
        return axios.post(`/api/Frontend/onboarding/Basisrooster`, payload)
    }
    postBasisroosterStamgroep(groepId: string, payload: IBasisroosterPost) {
        return axios.post(`/api/Frontend/onboarding/Basisrooster/${groepId}`, payload)
    }
    postBasisroosterStamgroepNew(groepId: string, payload: IBasisroosterPost) {
        return axios.post(`/api/Frontend/onboarding/Basisrooster/${groepId}`, payload)
    }
    //end basisrooster

    getVakgebiedLabels() {
        return axios.get(`/api/Frontend/Labels/${LabelType.Vakgebied}`)
    }
    getActiviteitLabels() {
        return axios.get(`/api/Frontend/Labels/${LabelType.Activiteiten}`)
    }
    getBasisroosterVersies() {
        return axios.get(`/api/Frontend/Planning/Basisrooster/Versie`)
    }
    revertBasisroosterVersie(versieId: number) {
        return axios.get(`/api/Frontend/Planning/Basisrooster/Versie/Terugzetten`, { params: { versieId: versieId } })
    }
    undoRevertBasisroosterVersie() {
        return axios.get(`/api/Frontend/Planning/Basisrooster/Versie/Terugzetten/LaatsteJaarplanning`)
    }
    resetBasisroosterVersie() {
        return axios.get(`/api/Frontend/Planning/Basisrooster/Reset`)
    }
}

export const onboardingService = new OnboardingService()
