<template>
    <div
        id="note-notification"
        :class="{ active: notesStore.getters.value.getNotificationState }"
    >
        <template v-if="notesStore.getters.value.getNotificationHtml">
            <div
                class="note-text"
                v-html="notesStore.getters.value.getNotificationHtml"
            ></div>
        </template>
        <template v-else>
            <div class="note-text"><b>Je notitie is opgeslagen!</b> <span>Je kunt hem terugvinden op de Notities pagina.</span></div>
        </template>

        <div @click="setNotificationState()">
            <Close class="icon-close" />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { onMounted, watch } from 'vue'

import Close from '@/components-svg/Close.vue'
import { useStore } from '@/composables/useStore'

const { notesStore } = useStore()

const setNotificationState = () => {
    notesStore.commits.setNotificationState(false)
}

onMounted(() => {
    watch(
        () => notesStore.getters.value.getNotificationState,
        (newVal) => {
            if (newVal) {
                setTimeout(() => {
                    notesStore.commits.setNotificationState(false)
                }, 5000)
            }
        }
    )
})
</script>
