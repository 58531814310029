import { LabelType } from '@/enums/LabelType';
import { IVerdiepenQueryParams } from '@/interfaces/IVerdiepenItem';
import axios from 'axios';

export default class VerdiepenService {
    getItems(params: IVerdiepenQueryParams) {
        return axios.get(`api/Frontend/Verdiepen`, { params: params });
    }

    getVerdiepenCategorieen(){
        return axios.get(`api/Frontend/Verdiepen/Categorieen`);
    }

    getVerdiepenMethodes(vaklabelId: number){
        return axios.get(`api/Frontend/Verdiepen/Methodes/${vaklabelId}`);
    }

    getVerdiepenOmschrijving(id: number){
        return axios.get(`api/Frontend/Verdiepen/${id}/Omschrijving`);
    }

    getVerdiepenLabels(){
        return axios.get(`api/Frontend/Labels/${LabelType.VerdiepingsCategorie}`);
    }

    getVerdiepenVakLabels(){
        return axios.get(`api/Frontend/Labels/${LabelType.Vakgebied}`);
    }

    getVerdiepenJaargroepen(groupId: number) {
        return axios.get(`api/Frontend/Groep/Jaargroepen/${groupId}`);
    }

    putFavoriteVerdiepenItem(id: number, isFavorite: boolean){
        return axios.put(`api/Frontend/Verdiepen/${id}/Like/${isFavorite}`);
    }
}

export const verdiepenService = new VerdiepenService();