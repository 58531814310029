import { IGroupSettings } from '@/interfaces/IGroupSettings'
import axios from 'axios'
export default class GroupService {
    getGroups() {
        return axios.get(`api/Frontend/Groep`)
    }
    getYeargroups(groupId: number) {
        return axios.get(`api/Frontend/Groep/Jaargroepen/${groupId}`)
    }
    getCandidatesByGroupId(groupId: number) {
        return axios.get(`api/Frontend/Groep/${groupId}`)
    }
    getGroupSettings(groupId: number) {
        return axios.get(`api/Frontend/Groep/${groupId}/settings`)
    }
    updateGroupSettings(groupId: number, groupSettings: IGroupSettings) {
        return axios.put(`api/Frontend/Groep/${groupId}/settings`, groupSettings)
    }
}

export const groupService = new GroupService()
