export enum UserType {
    Geen = 0, //Waarschijnlijk schoolverlater
    Leerling = 1,
    Leerkracht = 2,
    IctCoordinator = 3,
    Stagiair = 4,
    IbrTer = 5,
    GlobaleAdministrator = 6, //helpdeskmedewerker
    SchoolValidator = 7, //Voor als je School aanmeld voor Momento (Ict coordinatoren, en nog een paar) Die kunnen aan het begin akkoord gaan
    Uitgever = 8,
    Invalleerkracht
}

// these are the descriptions for the enum. you can use this with Usertype.get(key)
export const Usertype = new Map<number, string>([
    [UserType.Geen, 'Onbekend'],
    [UserType.Leerling, 'Leerling'],
    [UserType.Leerkracht, 'Leerkracht'],
    [UserType.IctCoordinator, 'ICT Coördinator'],
    [UserType.Stagiair, 'Stagiair'],
    [UserType.IbrTer, 'Intern begeleider'],
    [UserType.GlobaleAdministrator, 'Administrator'],
    [UserType.SchoolValidator, 'Validator'],
    [UserType.Uitgever, 'Uitgever'],
    [UserType.Invalleerkracht, 'Invalleerkracht'],
  ]);