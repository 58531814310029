import { IArchiveFileSchoolYearWrapper, IArchiveRequest, IDocument, IDownloadArchiveFileRequest, IExportRequest, ISchoolGroupWrapper } from '@/interfaces/IDocument'
import { IKlassenMapExportResponse } from '@/interfaces/IExport'
import axios, { AxiosResponse } from 'axios'

export default class ExportService {
    downloadNotities(groupId: number, document: IDocument) {
        return axios.get(`/api/Frontend/Groep/file/`, {
            responseType: 'blob',
            params: {
                groupId: groupId,
                blobId: document.blobId,
            },
        })
    }
    downloadResultaten(groupId: number, document: IDocument) {
        return axios.get(`/api/Frontend/Groep/file/`, {
            responseType: 'blob',
            params: {
                groupId: groupId,
                blobId: document.blobId,
            },
        })
    }
    downloadPlanning(groupId: number, document: IDocument) {
        return axios.get(`/api/Frontend/Groep/file/`, {
            responseType: 'blob',
            params: {
                groupId: groupId,
                blobId: document.blobId,
            },
        })
    }
    getArchiveFileList(groupIds: IArchiveRequest): Promise<AxiosResponse<IArchiveFileSchoolYearWrapper[]>> {
        return axios.post(`/api/Frontend/Export/archiveFileList`, groupIds)
    }
    generateArchiveFileList(request: IArchiveRequest): Promise<AxiosResponse<IArchiveFileSchoolYearWrapper>> {
        return axios.post(`/api/Frontend/Export/generateArchiveFileList`, request)
    }
    downloadArchiveFiles(request: IDownloadArchiveFileRequest[]): Promise<AxiosResponse<Blob>> {
        return axios.post(`/api/Frontend/Export/archiveFiles`, request, {
            responseType: 'blob',
        })
    }
    getExportOverview(): Promise<AxiosResponse<ISchoolGroupWrapper[]>> {
        return axios.get(`/api/Frontend/Export/exportOverview`)
    }
    getKlassenmapExport(request: IExportRequest): Promise<AxiosResponse<IKlassenMapExportResponse>> {
        return axios.post(`/api/Frontend/Export/klassenmap`, request)
    }
}

export const exportService = new ExportService()
