import { DOCUMENT_TYPES, IArchiveDocument, IArchiveDocumentTable, IDocument, IDocumentConfig, IUploadDocument, IUploadDocumentForm } from '@/interfaces/IDocument'
import { documentsService } from '@/services/documentsService'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

import { notify } from '@kyvg/vue3-notification'

const showVueNotifyError = (message: string) => {
    notify({
        group: 'requests',
        title: 'Foutmelding',
        type: 'error',
        text: `Er ging iets mis met het ophalen van de documenten, Error: ${message}`,
    })
}

const showFileSizeError = () => {
    notify({
        group: 'requests',
        title: 'Foutmelding',
        type: 'error',
        text: `Het bestand is niet geüpload, je overschrijdt hiermee de limiet van de beschikbare opslagruimte. Maak ruimte vrij door bestanden te verwijderen.`,
    })
}

const showFileTooLargeError = () => {
    notify({
        group: 'requests',
        title: 'Foutmelding',
        type: 'error',
        text: `Het bestand is niet geüpload, het bestand is te groot. Maak het bestand kleiner en probeer het opnieuw.`,
    })
}

class DocumentsState {
    documentsLoading = false
    documents: IDocument[] = []
    archiveDocuments: IArchiveDocument[] = []
    documentConfig = {
        maxStorageSize: 0,
        currentStorageSize: 0,
    } as IDocumentConfig
    documentConfigLoading = true
}

const getters: GetterTree<DocumentsState, any> = {
    getDocuments(state) {
        return () => state.documents
    },
    getDocumentsLoading(state) {
        return () => state.documentsLoading
    },
    getDocumentConfig(state) {
        return () => state.documentConfig
    },
    getDocumentsConfigLoading(state) {
        return () => state.documentConfigLoading
    },
    getArchiveDocuments(state) {
        return () => state.archiveDocuments
    },
}

const mutations: MutationTree<DocumentsState> = {
    setDocuments(state, payload: IDocument[]) {
        state.documents = payload
    },
    addDocument(state, payload: IDocument) {
        state.documents.push(payload)
    },
    setDocumentsLoading(state, payload: boolean) {
        state.documentsLoading = payload
    },
    setDocumentConfig(state, payload: IDocumentConfig) {
        state.documentConfig = payload
    },
    setDocumentsConfigLoading(state, payload: boolean) {
        state.documentConfigLoading = payload
    },
    setArchivedDocuments(state, payload: IArchiveDocument[]) {
        state.archiveDocuments = payload
    },
}

const actions: ActionTree<DocumentsState, any> = {
    async getDocumenten({ commit, state }, payload: number) {
        try {
            const { data } = await documentsService.getDocuments(payload)
            const documents = data
            commit('setDocuments', documents)
            return documents
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        }
    },
    async downloadDocument({ commit, state }, payload: { groupId: number; document: IDocument }) {
        try {
            if (payload.document.type === DOCUMENT_TYPES.LINK || !payload.document.fileName) throw new Error('Cannot download link')

            const { data } = await documentsService.downloadDocument(payload.groupId, payload.document)

            const url = window.URL.createObjectURL(data)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = payload.document.fileName
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)

            return data
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        }
    },
    async downloadDocumentArchive({ commit, state }, payload: { groupId: number; document: IArchiveDocumentTable }) {
        try {
            const { data } = await documentsService.downloadDocumentArchive(payload.groupId, payload.document)

            const url = window.URL.createObjectURL(data)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = payload.document.fileName
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)

            return data
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        }
    },
    async uploadDocument({ commit, state, dispatch, getters, rootGetters }, payload: { documentForm: IUploadDocumentForm; groupId: number }) {
        let arrayBuffer: null | ArrayBuffer = null
        if (payload.documentForm.file) {
            arrayBuffer = await payload.documentForm.file.arrayBuffer()
        }

        return new Promise((resolve, reject) => {
            if (payload.documentForm.type === null) reject('No file type selected')
            if (!payload.documentForm.file && payload.documentForm.type === DOCUMENT_TYPES.FILE) reject('No file selected')

            if (payload.documentForm.type && payload.documentForm.type === DOCUMENT_TYPES.FILE && payload.documentForm.file && arrayBuffer) {
                if (payload.documentForm.file.size > state.documentConfig.maxStorageSize) {
                    showFileTooLargeError()
                    reject('File too large')
                    return
                } else if (payload.documentForm.file.size + state.documentConfig.currentStorageSize > state.documentConfig.maxStorageSize) {
                    showFileSizeError()
                    reject('File too large')
                    return
                }

                const documentToUpload: IUploadDocument = {
                    groupId: payload.groupId,
                    friendlyName: payload.documentForm.titel,
                    fileContent: Array.from(new Uint8Array(arrayBuffer)),
                    fileName: payload.documentForm.file?.name,
                    subject: payload.documentForm.omschrijving,
                    type: payload.documentForm.type,
                    creationDate: new Date(),
                }

                documentsService
                    .uploadDocument(documentToUpload)
                    .then((data) => {
                        if (data.status === 401) reject('unauthorized')

                        commit('addDocument', data.data)
                        dispatch('getConfig', payload.groupId)
                        resolve(data.data)
                    })
                    .catch((e: Error) => {
                        showVueNotifyError(e.message)
                        reject(e)
                    })
            } else if (payload.documentForm.type && payload.documentForm.type === DOCUMENT_TYPES.LINK) {
                const utf8Encode = new TextEncoder()

                const documentToUpload: IUploadDocument = {
                    groupId: payload.groupId,
                    friendlyName: payload.documentForm.titel,
                    fileContent: Array.from(utf8Encode.encode(payload.documentForm.link)),
                    fileName: payload.documentForm.file?.name,
                    subject: payload.documentForm.omschrijving,
                    type: payload.documentForm.type,
                    creationDate: new Date(),
                }

                documentsService.uploadDocument(documentToUpload).then((data) => {
                    if (data.status === 401) reject('unauthorized')
                    commit('addDocument', data.data)

                    dispatch('getConfig', payload.groupId)
                    resolve(data.data)
                })
            } else {
                showVueNotifyError('No type selected')
                reject('No type selected')
            }
        })
    },
    async deleteDocument({ commit, state, dispatch }, payload: { groupId: number; document: IDocument }) {
        try {
            commit('setDocumentsLoading', true)
            const { data } = await documentsService.deleteDocument(payload.groupId, payload.document)
            dispatch('getDocumenten', payload.groupId)
            dispatch('getConfig', payload.groupId)
            return data
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        }
    },
    async getConfig({ commit, state }, payload: number) {
        commit('setDocumentsConfigLoading', true)
        try {
            const { data } = await documentsService.getConfig(payload)
            commit('setDocumentConfig', data)
            return data
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        } finally {
            commit('setDocumentsConfigLoading', false)
        }
    },
    async getArchivedDocuments({ commit, state }, groupId: number) {
        try {
            const { data } = await documentsService.getArchivedDocuments(groupId)
            commit('setArchivedDocuments', data)
            return data
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        }
    },
}

export default {
    state: new DocumentsState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}
