import { DOCUMENT_TYPES, IArchiveRequest, IDocument, IDownloadArchiveFileRequest, IExportRequest } from '@/interfaces/IDocument'
import { documentsService } from '@/services/documentsService'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

import { exportService } from '@/services/exportService'
import { notify } from '@kyvg/vue3-notification'

const showVueNotifyError = (message: string) => {
    notify({
        group: 'requests',
        title: 'Foutmelding',
        type: 'error',
        text: `Er ging iets mis met het ophalen van de documenten, Error: ${message}`,
    })
}

const showExportNoData = () => {
    notify({
        group: 'requests',
        title: 'Foutmelding',
        type: 'error',
        text: `Er is voor de selectie geen data beschikbaar.`,
    })
}

class ExportState {
    exportLoading = false
}

const getters: GetterTree<ExportState, any> = {
    getExportLoading(state) {
        return () => state.exportLoading
    },
}

const mutations: MutationTree<ExportState> = {
    setExportLoading(state, payload: boolean) {
        state.exportLoading = payload
    },
}

const actions: ActionTree<ExportState, any> = {
    async downloadDocument({ commit, state }, payload: { groupId: number; document: IDocument }) {
        try {
            if (payload.document.type === DOCUMENT_TYPES.LINK || !payload.document.fileName) throw new Error('Cannot download link')

            const { data } = await documentsService.downloadDocument(payload.groupId, payload.document)

            downloadFile(data, payload.document.fileName)

            return data
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        }
    },
    async exportOverview({ commit, state }) {
        try {
            const { data } = await exportService.getExportOverview()

            return data
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        }
    },
    async exportKlassenMap({ commit, state }, payload: { request: IExportRequest; schoolJaarLabel: string }) {
        try {
            const { data } = await exportService.getKlassenmapExport(payload.request)

            if (!data.zipContent) {
                showExportNoData()
                return
            }

            downloadBase64string(data.zipContent, `klassenmap_export_${payload.schoolJaarLabel.replaceAll(' ', '')}.zip`)

            return data
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        }
    },
    async generateArchiveFileList({ commit, state }, payload: IArchiveRequest) {
        try {
            const { data } = await exportService.generateArchiveFileList(payload)

            return data
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        }
    },
    async getArchiveFileList({ commit, state }, payload: IArchiveRequest) {
        try {
            const { data } = await exportService.getArchiveFileList(payload)

            return data
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        }
    },
    async downloadArchiveFiles({ commit, state }, payload: { request: IDownloadArchiveFileRequest[]; schoolJaarLabel: string }) {
        try {
            const { data } = await exportService.downloadArchiveFiles(payload.request)

            downloadFile(data, `archief_export_${payload.schoolJaarLabel.replaceAll(' ', '')}.zip`)

            return data
        } catch (error: any) {
            showVueNotifyError(error.message)
            throw error
        }
    },
}

const downloadBase64string = (base64String: string, fileName: string) => {
    const byteCharacters = atob(base64String)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: 'application/octet-stream' })
    downloadFile(blob, fileName)
}

const downloadFile = (blob: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
}

export default {
    state: new ExportState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}
