export enum LabelType {
    Undefined = 0,
    Vakgebied,
    Jaargroep,
    SLOKerndoel,
    SociaalEmotioneel,
    Competenties,
    Activiteiten,
    Kleuteractiviteiten,
    NotitieOnderwerp,
    VerdiepingsCategorie
}