import { IUser } from './IUser'

export const STAMGROEP_GROEPERING = {
    STAMGROEP: 0,
    VERBONDEN_GROEPEN: 1,
    VERBONDEN_SCHOOL: 2,
} as const

export type ObjectValues<T> = T[keyof T]

export type StamgroepGroepering = ObjectValues<typeof STAMGROEP_GROEPERING>

export interface IDay {
    titel: string
    id: any
    datumStart: Date
    datumEind: Date
    tijdStart: string
    tijdEind: string
    lesVrij: boolean
    groepId?: number
    omschrijving?: string
    heleDag?: boolean
    confirmed?: boolean
    zelfToegevoegd?: boolean
    groepering?: StamgroepGroepering
    aantalGekoppeldeGroepen: number
    aangemaaktDoorId?: number
    aangemaaktDoor: IUser | null
    aanmaakType: StamgroepGroepering
}

export interface IDayPlanning {
    titel: string
    id: number
    datumStart: Date
    datumEind: Date
    tijdStart?: string
    tijdEind?: string
    omschrijving?: string
    heleDag?: boolean
    afbeeldingUrl: string
    planItemType: number
    kleurcodeBorder: string
    schoolId: number
    groepId: number
}
