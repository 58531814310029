import axios from 'axios'

export default class SettingsService {
    getlastLogins() {
        return axios.get(`api/Frontend/Instellingen/LaatstIngelogdeGebruikers`)
    }
    getSchool() {
        return axios.get(`api/Frontend/School`)
    }
    getLeveranciers() {
        return axios.get(`api/Frontend/School/Leveranciers`)
    }
    getLeerkrachten() {
        return axios.get(`api/Frontend/School/Leerkrachten`)
    }
    setLeverancier(leverancierId: number) {
        return axios.post(`api/Frontend/Instellingen/UpdateLeverancier/${leverancierId}`)
    }
    getUitgevers() {
        return axios.get(`api/Frontend/Instellingen/Uitgevers`)
    }
    getVerwerkersOvereenkomst() {
        return axios.get(`api/Frontend/Instellingen/VerwerkersOvereenkomst`)
    }
    koppelUitgever(uitgeverId: number) {
        return axios.post(`api/Frontend/Instellingen/KoppelUitgever/${uitgeverId}`)
    }
    ontkoppelUitgever(uitgeverId: number) {
        return axios.post(`api/Frontend/Instellingen/OntkoppelUitgever/${uitgeverId}`)
    }
}

export const settingsService = new SettingsService()
