import axios from 'axios';

export default class LesdagenService {
    getLesdagen(stamgroepId: number, datum: string): Promise<any> {
        return axios.get(`/api/Frontend/Planning/JouwLesdag/${stamgroepId}/${datum}`);
    }

    getAfspraakDetails(stamgroepId: number, afspraakId: number): Promise<any> {
        return axios.get(`/api/Frontend/Planning/JouwLesdag/afspraakDetails/${stamgroepId}/${afspraakId}`);
    }

    getLesdagenSpeciaal(stamgroepId: number, datum: string): Promise<any> {
        return axios.get(`/api/Frontend/Planning/JouwLesdag/speciaal/${stamgroepId}/${datum}`);
    }
}

export const yourDayService: LesdagenService = new LesdagenService();
